import { string, boolean } from 'yup';

import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from './constants';

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const testEmail = (isOptional: boolean) =>
  string().test({
    name: 'test-email',
    test(value, ctx) {
      const valueTrim = value?.trim();
      let errMsg = null;

      if (!isOptional && !valueTrim) errMsg = 'Email address is required';
      else if (valueTrim && !emailRegex.test(valueTrim))
        errMsg = 'Invalid email address';

      if (errMsg) return ctx.createError({ message: errMsg });
      return true;
    },
  });

export const testConfirmEmail = () =>
  string().test({
    name: 'test-confirm-mail',
    test(value, ctx) {
      const valueTrim = value?.trim();
      let errMsg = null;

      if (!valueTrim) errMsg = 'Confirm email address is required';
      else if (ctx.parent.email !== valueTrim) errMsg = 'Emails must match';

      if (errMsg) return ctx.createError({ message: errMsg });
      return true;
    },
  });

export const testName = (label: string) =>
  string().test({
    name: 'test-name',
    test(value, ctx) {
      const valueTrim = value?.trim();
      let errMsg = null;

      if (!valueTrim) errMsg = `${label} is required`;
      else if (!/^[a-zA-Z\s]+$/.test(valueTrim))
        errMsg = `${label} must contain only letters and spaces`;

      if (errMsg) return ctx.createError({ message: errMsg });
      return true;
    },
  });

export const testPassword = () =>
  string().test({
    name: 'test-password',
    test(value, ctx) {
      let errMsg = null;

      if (!value) errMsg = 'Password is required';
      else if (
        value.length < PASSWORD_MIN_LENGTH ||
        value.length > PASSWORD_MAX_LENGTH
      )
        errMsg = `Password must be ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters long`;
      else if (!/[a-z]/.test(value))
        errMsg = 'Password must contain at least one lowercase character';
      else if (!/[A-Z]/.test(value))
        errMsg = 'Password must contain at least one uppercase character';
      else if (!/[0-9]/.test(value))
        errMsg = 'Password must contain at least one number';
      else if (!/[`~!@#$%^&*()_+\-={}|[\]\\;':",./<>?]/.test(value))
        errMsg = 'Password must contain at least one special character';

      if (errMsg) return ctx.createError({ message: errMsg });
      return true;
    },
  });

export const testConfirmPassword = () =>
  string().test({
    name: 'test-confirm-password',
    test(value, ctx) {
      let errMsg = null;

      if (!value) errMsg = 'Confirm password is required';
      else if (ctx.parent.password !== value) errMsg = 'Passwords must match';

      if (errMsg) return ctx.createError({ message: errMsg });
      return true;
    },
  });

export const testTsAndCs = () =>
  boolean().oneOf([true], 'You must agree to the Terms and Conditions');
