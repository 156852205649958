import React from 'react';
import { List } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';

import { trackCreateProject } from '../../../analytics';

import { URL } from '../../../constants';

import { useUserStore } from '../../../stores/user';

import { Button, Logo } from '../../general';
import { ProfileDropdown } from './';

const Header = (props) => {
  const { hasProjSideNav, toggleSideNav } = props;

  const user = useUserStore((state) => state.user);
  const accessToken = useUserStore((state) => state.accessToken);

  const pathname = window.location.pathname;

  // on event check-in page – header only has logo
  const showLogoOnly = React.useMemo(() => {
    return [URL.REVIEW_VIDEO].reduce(
      (acc, url) => acc || pathname.includes(url),
      false,
    );
  }, [pathname]);

  const navigate = useNavigate();

  const onLoginClick = () => {
    if (pathname.includes(URL.MANAGED_SERVICES)) {
      // store the current page URL in local storage (to redirect back after login)
      const { pathname, search } = window.location;
      localStorage.setItem('previousPage', pathname + search);
    }

    navigate(URL.LOGIN);
  };

  const handleCreateEvent = () => {
    navigate(URL.PROJECT_SETTINGS);
    trackCreateProject('Header');
  };

  const headerClass =
    'fixed top-0 left-0 w-screen h-[var(--header-height)] flex items-center justify-between px-[var(--page-spacing)] py-1 bg-gradient-primary-right text-white z-[100]';

  if (showLogoOnly)
    return (
      <div className={headerClass}>
        <Logo isWhite noLink />
      </div>
    );

  if (accessToken && user)
    return (
      <header className={headerClass}>
        <div className="flex items-center gap-4">
          {hasProjSideNav && (
            <Button variant="transparent-white" isThin onClick={toggleSideNav}>
              <List size="2rem" />
            </Button>
          )}

          <Logo isWhite />
        </div>

        <div className="flex items-center gap-8">
          {/* not shown on small screens */}
          <Button
            variant="white-primary"
            className="hidden sm:flex font-medium"
            isThin
            onClick={handleCreateEvent}
          >
            Create Project
          </Button>

          <ProfileDropdown />
        </div>
      </header>
    );

  return (
    <div className={headerClass}>
      <Logo isWhite />

      <Button
        variant="white-primary"
        className="w-24"
        isThin
        onClick={onLoginClick}
      >
        Log In
      </Button>
    </div>
  );
};

export default Header;
