import React, { useState, ChangeEvent } from 'react';
import { toast } from 'react-toastify';

import {
  isEmpty,
  isPlural,
  getPluralPhrase,
  validateEmail,
  updateEmailText,
} from '../../../../Utils';

import {
  trackEmailHubSendTestEmailSuccess,
  trackEmailHubSendTestEmailError,
} from '../../../../analytics';

import { Form, FloatingLabel, FormText } from '../../../../components/form';
import { Button, Modal } from '../../../../components/general';

import { sendEmail } from '../../../../services/api/emails';

const SendTestEmailModal = (props: any) => {
  const { show, onHide, template, event, user, customText } = props;

  const [emails, setEmails] = useState<string>('');
  const [emailsErr, setEmailsErr] = useState<string | null>(null);

  const [isSending, setIsSending] = useState<boolean>(false);

  if (isEmpty(event) || isEmpty(template)) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const emailsArr: string[] = emails.split(',');
    let emailErrTemp: string | null = null;

    emailsArr.forEach((email) => {
      if (!emailErrTemp) {
        const { emailErr } = validateEmail(email.trim());

        if (emailErr)
          emailErrTemp = 'Some of the addresses appear to be invalid';
      }
    });

    if (emailErrTemp) setEmailsErr(emailErrTemp);
    else {
      setIsSending(true);

      let successCount: number = 0;
      let errorList: string[] = [];

      for (const email of emailsArr) {
        const emailData = {
          recipientEmail: email.trim(),
          templateId: template.template_id,
          dynamicTemplateData: {
            event_name: event.name,
            name: user.firstName,
            share_url: 'https://www.crowdclip.com/',
            text: customText,
          },
        };

        const isSuccess: any = await sendEmail(emailData);

        if (isSuccess) successCount++;
        else errorList.push(email.trim());
      }

      if (successCount > 0) {
        toast.success(
          <div>
            Sent email to <b>{getPluralPhrase(successCount, 'address')}</b>.
          </div>,
        );
        trackEmailHubSendTestEmailSuccess(successCount);
      }

      if (!isEmpty(errorList)) {
        toast.error(
          <div>
            Email{isPlural(errorList)} couldn't be sent to{' '}
            <b>{errorList.join(', ')}</b>, please try again later.
          </div>,
        );
        trackEmailHubSendTestEmailError(errorList.length);
      }

      setIsSending(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Send a test email</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <b>Subject</b>: {updateEmailText(template.subject, event, user)}
        </div>

        <Form onSubmit={onSubmit}>
          <div>
            <FloatingLabel
              label="Email addresses"
              name="emails"
              value={emails ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmails(e.target.value);
                setEmailsErr(null);
              }}
              isInvalid={!!emailsErr}
              errMsg={emailsErr ?? ''}
              required
            />

            <FormText text="Enter 1 or more email addresses, separated by commas" />
          </div>

          <Button
            disabled={isEmpty(emails) || !isEmpty(emailsErr)}
            isSubmitBtn
            isProcessing={isSending}
          >
            Send
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer className="text-sm italic">
        Please note that all links (if existing) in the test email are
        substituted with www.crowdclip.com. The emails your attendees receive
        will include all relevant links.
      </Modal.Footer>
    </Modal>
  );
};

export default SendTestEmailModal;
