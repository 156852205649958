import React from 'react';

import { goTo } from '../../../Utils';

import { unsetUserID, trackLogOut } from '../../../analytics';

import { Dropdown } from '../../../components/general';

import { URL } from '../../../constants';

import { restartSessionLR } from '../../../services/logrocketService';
import { useUserStore } from '../../../stores/user';

import { ProfileIcon } from './';

const ProfileDropdown = () => {
  const user = useUserStore((state) => state.user);

  const onClickLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();

    unsetUserID();
    trackLogOut();

    restartSessionLR();

    goTo(URL.LOGIN);
  };

  return (
    <div className="flex gap-2">
      <Dropdown
        btnData={{ icon: <ProfileIcon />, variant: 'custom' }}
        menuItems={[
          {
            type: 'custom',
            customContent: (
              <div className="w-80 flex items-center px-4 py-1 gap-4">
                <div>
                  {(user.firstName || user.lastName) && (
                    <div className="font-bold break-all">
                      {user.firstName} {user.lastName}
                    </div>
                  )}
                  {user.email && (
                    <div className="text-xs break-all opacity-50">
                      {user.email}
                    </div>
                  )}
                </div>
              </div>
            ),
          },
          { type: 'divider' },
          { label: 'Log Out', onClick: onClickLogOut, closeOnClick: true },
        ]}
      />
    </div>
  );
};

export default ProfileDropdown;
