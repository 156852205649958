import React from 'react';
import classNames from 'classnames';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Popover } from 'flowbite-react';

import { Placement, Trigger } from '../../types';

type Props = {
  children: React.ReactNode;
  header: string;
  body: string;
  placement?: Placement;
  trigger?: Trigger;
  variant?: string;
  isWide?: boolean;
  className?: string;
  hide?: boolean;
};

const CustomPopover = (props: Props) => {
  const {
    children,
    header,
    body,
    trigger = 'hover',
    placement = 'bottom',
    variant = 'primary',
    isWide = false,
    className = '',
    hide = false,
  } = props;

  const borderColorClass = classNames({
    'border-grey-200': variant === 'grey',
    'border-primary-900': variant === 'primary',
  });

  const customTheme: CustomFlowbiteTheme['popover'] = {
    base: classNames(
      'absolute z-20 inline-block bg-white outline-none border border-solid rounded-md shadow-sm',
      borderColorClass,
      className,
      { 'w-max min-w-60 max-w-80': !isWide, 'w-[400px] max-w-[90vw]': isWide },
    ),
    content: 'z-10 overflow-hidden rounded-[5px]',
    arrow: {
      base: classNames(
        'absolute h-2 w-2 z-0 rotate-45 mix-blend-lighten bg-white border border-solid',
        borderColorClass,
      ),
    },
  };

  if (hide) return <>{children}</>;

  return (
    <Popover
      theme={customTheme}
      placement={placement}
      trigger={trigger}
      content={
        <React.Fragment>
          <div
            className={classNames('px-4 py-3 text-md font-medium', {
              'bg-grey-100': variant === 'grey',
              'bg-gradient-primary-right text-white': variant === 'primary',
            })}
          >
            {header}
          </div>

          <div className="px-4 py-3 text-sm font-normal leading-normal">
            {body}
          </div>
        </React.Fragment>
      }
    >
      {children}
    </Popover>
  );
};

export default CustomPopover;
