import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { CaretDown, Check } from '@phosphor-icons/react';
import classNames from 'classnames';

import { Button, Spinner } from './';

type Item = {
  type?: string;
  label?: string;
  icon?: ReactNode;
  customContent?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  isProcessing?: boolean;
  isCurrent?: boolean;
  show?: boolean;
  closeOnClick?: boolean;
  className?: string;
  isSmall?: boolean;
};

type Props = {
  btnData: {
    label?: string;
    icon?: React.ReactNode;
    variant?: string;
    form?: string;
    withCaret?: boolean;
    className?: string;
  };
  menuHeading?: string;
  menuItems: Item[];
  position?: 'right' | 'left';
  onOpen?: () => void;
  onClose?: () => void;
};

const Dropdown = (props: Props) => {
  const { btnData, menuHeading, menuItems, position, onOpen, onClose } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const isOpenDropdown = Boolean(anchorEl);

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const handleToggleDropdown = (event: React.MouseEvent<HTMLElement>) => {
    if (!isOpenDropdown) {
      setAnchorEl(event.currentTarget);
      onOpen && onOpen();
    } else handleCloseDropdown();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      !anchorEl?.contains(event.target as Node) &&
      !menuRef.current?.contains(event.target as Node)
    )
      handleCloseDropdown();
  };

  useEffect(() => {
    if (anchorEl) document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [anchorEl]);

  const renderItemContent = (item: Item) => {
    if (item.type === 'divider')
      return <hr className="my-2 border-grey-200 opacity-100" />;
    else if (item.type === 'custom') return item.customContent;
    else
      return (
        <MenuItem
          onClick={() => {
            item.onClick && item.onClick();
            if (item.closeOnClick) handleCloseDropdown();
          }}
          disabled={item.disabled}
          className={classNames(item.className, 'transition-all', {
            'px-3': item.isSmall,
          })}
          selected={item.isCurrent}
          sx={{ '.MuiListItemIcon-root': { minWidth: 0 } }}
        >
          {item.customContent ?? (
            <>
              {item.icon && (
                <ListItemIcon
                  className={classNames({
                    'mr-4 text-[1.25rem]': !item.isSmall,
                    'mr-2 text-[1.1rem]': item.isSmall,
                  })}
                >
                  {item.isProcessing ? (
                    <Spinner size="sm" hoverColor="white" />
                  ) : (
                    item.icon
                  )}
                </ListItemIcon>
              )}

              <ListItemText>
                <div
                  className={classNames({
                    'text-sm': !item.isSmall,
                    'text-xs': item.isSmall,
                  })}
                >
                  {item.label}
                </div>
              </ListItemText>

              {item.isCurrent && (
                <Check weight="bold" className="ml-2 float-end" />
              )}
            </>
          )}
        </MenuItem>
      );
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleToggleDropdown}
        variant={btnData.variant ?? 'primary'}
        form={btnData.form}
        isThin
        withIcon={btnData.withCaret}
        className={btnData.className}
      >
        {btnData.icon}
        {btnData.label && <div>{btnData.label}</div>}
        {btnData.withCaret && <CaretDown weight="bold" />}
      </Button>

      <Menu
        ref={menuRef}
        anchorEl={anchorEl}
        open={isOpenDropdown}
        onClose={handleCloseDropdown}
        disableScrollLock
        transformOrigin={{ horizontal: position ?? 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: position ?? 'right', vertical: 'bottom' }}
        className="mt-2 pointer-events-none"
        sx={{
          '.MuiMenu-paper': { pointerEvents: 'auto' },
          '.MuiMenuItem-root': { padding: '0.5rem 1rem' },
          '.MuiList-root:has(.MuiMenuItem-root:hover) .MuiMenuItem-root:not(:hover)':
            { backgroundColor: 'transparent' },
          '.MuiMenuItem-root.Mui-selected': {
            backgroundColor: 'var(--light-grey-2)',
          },
          '.MuiMenuItem-root:hover': {
            backgroundColor: 'var(--light-grey-1) !important',
          },
          '.MuiListItemIcon-root': { color: 'black' },
        }}
      >
        {menuHeading && (
          <React.Fragment>
            <div className="px-4 text-sm font-bold">{menuHeading}</div>

            <hr className="my-2" />
          </React.Fragment>
        )}

        {menuItems.map((item: Item, index: number) =>
          item && item.show !== false ? (
            <React.Fragment key={index}>
              {renderItemContent(item)}
            </React.Fragment>
          ) : null,
        )}
      </Menu>
    </React.Fragment>
  );
};

export default Dropdown;
