import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { CaretDown } from '@phosphor-icons/react';
import parse from 'html-react-parser';
import { PiCaretUp, PiCaretDown } from 'react-icons/pi';
import { toast } from 'react-toastify';

import { Form, FloatingLabel } from '../../components/form';
import { Button } from '../../components/general';
import { PageContainer, ContentHeader } from '../../components/layout/content';
import { ConfirmModal } from '../../components/modals';

import { sendMsNotification } from '../../services/api/emails';

import withRouter from '../../withRouter';

import { getHelpContent } from './helpContent';

const Help = (props) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [requestText, setRequestText] = useState('');
  const [infoOpen, setInfoOpen] = useState(true);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contentBlocks = getHelpContent();

  const [openedFAQs, setOpenedFAQs] = useState(
    contentBlocks.map((_, index) => index),
  );

  const onFaqClick = (index) => {
    // By default, all FAQs are opened
    setOpenedFAQs((prevOpenedFAQs) => {
      if (prevOpenedFAQs.includes(index)) {
        // Remove index if it's already in the array
        return prevOpenedFAQs.filter((i) => i !== index);
      } else {
        // Add index to the array
        return [...prevOpenedFAQs, index];
      }
    });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    const text =
      'You have a new <b>Support Request</b> from the following user:<br />' +
      `<b>User</b>: ${email}<br />` +
      `<b>Name</b>: ${name}<br /><br />` +
      requestText;

    const isSuccess = await sendMsNotification(
      'Support Request',
      text.replace(/\n/g, '<br />'),
      ['naomi@crowdclip.com'],
    );

    if (isSuccess) setShowConfirmModal(true);
    else toast.error('Something went wrong. Try again later');

    setIsLoading(false);
  };

  const renderAnswerContent = (block) => {
    const content = block.content;

    switch (block.type) {
      case 'text':
        return <div>{parse(content)}</div>;

      case 'note':
        return <div className="text-sm italic">{parse(content)}</div>;

      case 'few-blocks':
        return (
          <div className="flex flex-col sm:flex-row gap-8">
            {content.map((option) => (
              <div className="w-full sm:w-1/2 flex flex-col gap-2">
                {option.text && (
                  <div className="text-center">{parse(option.text)}</div>
                )}

                {option.video && (
                  <video
                    className="w-[60%] sm:w-[45%] mx-auto"
                    src={option.video}
                    controls
                  />
                )}

                {option.items && (
                  <ol className="w-full flex flex-col self-center m-0 pl-8 gap-2 sm:gap-4 list-decimal">
                    {option.items.map((item) => (
                      <li>{parse(item)}</li>
                    ))}
                  </ol>
                )}
              </div>
            ))}
          </div>
        );

      case 'element':
        return content;

      default:
        return null;
    }
  };

  return (
    <PageContainer title="Help" addClass="gap-6">
      <ContentHeader title="Help Page" />

      <Accordion
        expanded={infoOpen}
        onChange={() => setInfoOpen(!infoOpen)}
        className="shadow-none"
        disableGutters
        sx={{ ':before': { display: 'none' } }}
      >
        <AccordionSummary
          className="p-0"
          sx={{
            '.MuiAccordionSummary-content': {
              flexDirection: 'column',
              margin: 0,
              gap: '0.75rem',
            },
          }}
        >
          <div>
            <h3 className="inline-block">Sharing Generated Videos</h3>

            <div className="inline-block ml-2">
              <div className="w-6 h-6 flex items-center justify-center bg-true-black text-white rounded-full cursor-pointer hover:bg-primary-900">
                {infoOpen ? <PiCaretUp /> : <PiCaretDown />}
              </div>
            </div>
          </div>

          <div>
            You may encounter known issues when attempting to share your newly
            generated videos using a mobile device. As a workaround, you may
            download and share manually.
          </div>
        </AccordionSummary>

        <AccordionDetails className="mt-3 p-4 rounded-md border border-grey-900 border-solid">
          {contentBlocks.map((faq, index) => {
            const isOpened = openedFAQs.includes(index);

            return (
              <React.Fragment>
                <Accordion
                  expanded={isOpened}
                  onChange={() => onFaqClick(index)}
                  className="shadow-none"
                  disableGutters
                  sx={{
                    '.MuiAccordionSummary-root': { minHeight: 0 },
                    ':before': { display: 'none' },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <CaretDown
                        weight="bold"
                        size="1.1rem"
                        className="leading-none group-hover:text-primary-900"
                      />
                    }
                    className="group p-0 font-bold hover:text-primary-900"
                    sx={{ '.MuiAccordionSummary-content': { margin: 0 } }}
                  >
                    {parse(faq.question)}
                  </AccordionSummary>

                  <AccordionDetails className="p-0 pt-4 flex flex-col gap-4">
                    {faq.answer.map((block) => renderAnswerContent(block))}
                  </AccordionDetails>
                </Accordion>

                {index !== contentBlocks.length - 1 && <hr className="my-4" />}
              </React.Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>

      <div className="flex flex-col p-4 gap-4 bg-grey-100 rounded-md border border-grey-900 border-solid">
        <h2 className="text-center">Support Request Form</h2>

        <div>
          We're here to help whenever you encounter an issue or have questions.
          Complete the Support Request Form to contact the CrowdClip&reg;
          support team and we will endeavour to assist you within 48 hours.
        </div>

        <Form onSubmit={onSubmit} disabled={isLoading}>
          <div className="flex flex-col gap-2">
            <h5>Name</h5>

            <FloatingLabel
              label="Full name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col gap-2">
            <h5>Email</h5>

            <FloatingLabel
              label="Email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="flex flex-col gap-2">
            <h5>How can we help?</h5>

            <FloatingLabel
              label="Tell us more"
              as="textarea"
              name="requestText"
              value={requestText}
              onChange={(e) => setRequestText(e.target.value)}
              required
            />
          </div>

          <Button
            className="w-full sm:w-1/2 self-center"
            disabled={name === '' || email === '' || requestText === ''}
            isSubmitBtn
            isProcessing={isLoading}
          >
            Submit
          </Button>
        </Form>
      </div>

      <ConfirmModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        heading="Thank you, our team will contact you shortly!"
      />
    </PageContainer>
  );
};

export default withRouter(Help);
