import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { Link } from 'react-router-dom';

import {
  Form,
  FloatingLabel,
  FormText,
  FormError,
  PasswordField,
} from '../../../components/form';
import { Button, Checkbox } from '../../../components/general';

import {
  URL,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
} from '../../../constants';

import { CreateAccFormData } from '../components/types';

type Props = {
  formData: CreateAccFormData;
  errors?: { [key: string]: string };
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isCreating?: boolean;
};

const CreateAccountForm = (props: Props) => {
  const { formData, errors, onChange, onSubmit, isCreating } = props;

  return (
    <Form onSubmit={onSubmit} disabled={isCreating}>
      <FloatingLabel
        label="Email"
        name="email"
        value={formData.email}
        required
        readOnly
        disabled
      />

      <div className="flex flex-col sm:flex-row gap-4">
        <FloatingLabel
          label="First Name"
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={onChange}
          isInvalid={!!errors?.firstName}
          errMsg={errors?.firstName}
          required
          className="w-full sm:w-1/2"
        />

        <FloatingLabel
          label="Last Name"
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={onChange}
          isInvalid={!!errors?.lastName}
          errMsg={errors?.lastName}
          required
          className="w-full sm:w-1/2"
        />
      </div>

      <div>
        <div className="flex flex-col sm:flex-row gap-4">
          <PasswordField
            className="w-full sm:w-1/2"
            label="Password"
            name="password"
            value={formData.password}
            onChange={onChange}
            isInvalid={!!errors?.password}
            errMsg={errors?.password}
          />

          <PasswordField
            className="w-full sm:w-1/2"
            label="Confirm password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={onChange}
            isInvalid={!!errors?.confirmPassword}
            errMsg={errors?.confirmPassword}
          />
        </div>

        <FormText
          text={`Password must be ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters long, contain at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character.`}
          color="white"
        />
      </div>

      <div>
        <Checkbox
          id="create-acc-t&c-checkbox"
          label={
            <>
              Accept{' '}
              <a
                href="https://my.crowdclip.com/terms"
                target="_blank"
                rel="noreferrer"
              >
                Terms & Conditions
              </a>
            </>
          }
          name="isTsAndCs"
          checked={formData.isTsAndCs}
          onChange={onChange}
          isInvalid={!!errors?.isTsAndCs}
          className="text-white"
        />

        <FormError errMsg={errors?.isTsAndCs} />
      </div>

      <Button className="w-full" isSubmitBtn isProcessing={isCreating}>
        Create Account
      </Button>

      <div className="text-white">
        Already have an account? <Link to={URL.LOGIN}>Log in</Link>
      </div>
    </Form>
  );
};

export default CreateAccountForm;
