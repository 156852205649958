import React, { useState, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, isProduction } from '../../../Utils';
import { sendVerificationEmail } from '../../../UtilsEmail';

import {
  trackAccountCreateSuccess,
  trackAccountCreateError,
  trackLogInSuccess,
  trackLogInError,
} from '../../../analytics';

import { Button } from '../../../components/general';
import { ErrorModal } from '../../../components/modals';

import config from '../../../config';
import { URL } from '../../../constants';

import {
  getUserData,
  updateUserAccount,
  verifyEmail,
} from '../../../services/api/auth';
import { signIn } from '../../../services/api/sessions';
import { identifyUserLR } from '../../../services/logrocketService';
import { useUserStore } from '../../../stores/user';

import OnboardingPage from '../components/onboardingPage';
import { validateCreateAccountForm } from '../components/validations';
import CreateAccountForm from './createAccountForm';

const CreateAccount = () => {
  const setIsLoggingIn = useUserStore((state) => state.setIsLoggingIn);

  const [userId, setUserId] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    isTsAndCs: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorModal, setShowErrorModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const verificationIdOnUrl = queryParameters.get('id');
    const userIdOnUrl = queryParameters.get('userId');

    const verifyAndProceed = async () => {
      setIsLoading(true);

      try {
        const userData = await getUserData(userIdOnUrl);

        sessionStorage.setItem('email', userData?.email);

        if (userData.status === 'active') navigate(URL.LOGIN);

        setFormData({ ...formData, email: userData?.email });
        setUserId(userData?.userId);

        const isVerified = await verifyEmail(
          userData?.userId,
          verificationIdOnUrl,
        );

        if (!isVerified) setShowErrorModal(true);
      } catch (error) {
        console.error(error);
        setShowErrorModal(true);
      }

      setIsLoading(false);
    };

    verifyAndProceed();
  }, []);

  const onResendVerificationEmail = () => {
    sendVerificationEmail(formData.email)
      .then((isSuccess) => {
        if (isSuccess) navigate(URL.EMAIL_VERIFICATION);
      })
      .catch(() => {
        setIsCreating(false);
      });
  };

  const onChange = (e) => {
    const { type, name, value, checked } = e.target;

    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = await validateCreateAccountForm(formData);

    if (!isEmpty(errors)) setErrors(errors);
    else {
      setIsCreating(true);

      let newUser = {
        userId,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
      };

      // Update user account with additional details
      const createUserAccount = await updateUserAccount(newUser);

      if (createUserAccount) {
        if (isProduction()) window.fbq('track', 'Lead');

        trackAccountCreateSuccess();

        await signInUser();
      } else trackAccountCreateError();

      setIsCreating(false);
    }
  };

  const signInUser = async () => {
    try {
      setIsLoggingIn(true);

      const user = await signIn(formData.email, formData.password, true);

      const previousPage = localStorage.getItem('previousPage');
      navigate(previousPage || URL.HOME);
      localStorage.removeItem('previousPage');

      trackLogInSuccess(previousPage);

      const notAllowedDomains = ['crowdclip.com', 'dius.com.au', 'dius.com'];
      const isValidDomain = () => {
        const domain = formData.email.split('@')[1];
        return !notAllowedDomains.includes(domain);
      };

      identifyUserLR(user);

      if (isProduction() && isValidDomain()) {
        const advancedMatching = { em: formData.email };
        const options = {
          autoConfig: true,
          debug: false,
        };
        ReactPixel.init(config.pixelID, advancedMatching, options);
        ReactPixel.track('Leads');

        if (hotjar.initialized()) {
          hotjar.identify(user.session.userId, { email: formData.email });
        }
      }

      setTimeout(() => {
        if (isMobile || isTablet) {
          toast.warning(
            <div>
              <div>CrowdClip&reg; is best used on a desktop.</div>
              <div>
                You can continue on your mobile device, but beware, things will
                look a bit funny.
              </div>
            </div>,
            {
              autoClose: false,
            },
          );
        }
      }, 200);
    } catch {
      toast.error('Something went wrong. Try again later');
      trackLogInError();
    } finally {
      setIsCreating(false);
      setTimeout(() => setIsLoggingIn(false), 1000);
    }
  };

  return (
    <OnboardingPage
      title="Create Account"
      heading="Create your account in seconds"
      isLoading={isLoading}
    >
      <CreateAccountForm
        formData={formData}
        errors={errors}
        onChange={onChange}
        onSubmit={onSubmit}
        isCreating={isCreating}
      />

      <ErrorModal
        show={showErrorModal}
        btn={
          <div className="w-full flex items-center justify-between gap-4">
            <Button className="w-1/2" onClick={onResendVerificationEmail}>
              Resend Email
            </Button>

            <Button
              variant="pink"
              className="w-1/2"
              onClick={() => navigate(URL.HOME)}
            >
              Return Home
            </Button>
          </div>
        }
        heading="Sorry, we can't verify your account"
        subheading="You may need to resend the verification email. If you still can't verify your account, please contact us."
      />
    </OnboardingPage>
  );
};

export default CreateAccount;
