import React from 'react';
import parse from 'html-react-parser';
import { PiSmileySadFill } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from '../../components/general';
import { URL } from '../../constants';

const ErrorModal = (props) => {
  const { show, heading, subheading, btn, isTryAgainBtn, isContactUs } = props;

  const navigate = useNavigate();

  return (
    <Modal show={show}>
      <Modal.Body centered>
        <PiSmileySadFill
          className="fill-[url('#icon-primary-gradient')]"
          size={100}
        />

        {heading && <h4>{parse(heading)}</h4>}

        {subheading && <div>{parse(subheading)}</div>}

        {!!btn ? (
          btn
        ) : isTryAgainBtn ? (
          <Button className="w-full" onClick={() => window.location.reload()}>
            Try Again
          </Button>
        ) : (
          <div className="w-full flex items-center justify-between gap-4">
            <Button className="w-1/2" onClick={() => navigate(URL.HOME)}>
              Return Home
            </Button>

            <Button
              variant="pink"
              className="w-1/2"
              onClick={() => navigate(URL.HELP)}
            >
              Help
            </Button>
          </div>
        )}

        {isContactUs && (
          <div className="text-sm italic">
            Contact us at{' '}
            <a href="mailto:hello@crowdclip.com">hello@crowdclip.com</a> for
            further assistance.
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ErrorModal;
