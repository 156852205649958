import { object } from 'yup';

import {
  testName,
  testPassword,
  testConfirmPassword,
  testTsAndCs,
} from '../../../validationUtils';

import { CreateAccFormData, ResetPasswordFormData } from './types';

export const validateCreateAccountForm = async (
  formData: CreateAccFormData,
) => {
  const errors: { [key: string]: string } = {};

  await object()
    .shape({
      firstName: testName('First name'),
      lastName: testName('Last name'),
      password: testPassword(),
      confirmPassword: testConfirmPassword(),
      isTsAndCs: testTsAndCs(),
    })
    .validate(formData, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((error: any) => {
        errors[error.path] = error.errors[0];
      });
    });

  return errors;
};

export const validateResetPasswordForm = async (
  formData: ResetPasswordFormData,
) => {
  const errors: { [key: string]: string } = {};

  await object()
    .shape({
      password: testPassword(),
      confirmPassword: testConfirmPassword(),
    })
    .validate(formData, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((error: any) => {
        errors[error.path] = error.errors[0];
      });
    });

  return errors;
};
