import React, { ReactNode, ChangeEventHandler } from 'react';
import classNames from 'classnames';

type Props = {
  id?: string;
  label?: string | ReactNode;
  name: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isInvalid?: boolean;
  className?: string;
};

const Checkbox = (props: Props) => {
  const {
    id = 'checkbox',
    label,
    name,
    checked,
    onChange,
    isInvalid,
    className,
  } = props;

  return (
    <div className={classNames('flex items-center gap-2', className)}>
      <input
        id={id}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className={classNames(
          'w-4 h-4 text-black border-black rounded cursor-pointer focus:ring-0 focus:ring-offset-0',
          {
            'border-black': !isInvalid,
            'border-error-900': isInvalid,
            'bg-gray-100': !checked,
            'bg-black': checked,
          },
        )}
      />
      <label htmlFor={id} className="text-sm font-medium">
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
