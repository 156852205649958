import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';

const ModalContext = createContext<{
  onHide?: () => void;
  previewSize?: { width: number; height: number };
  hasHeader?: boolean;
  setHasHeader?: Dispatch<SetStateAction<boolean>>;
  hasFooter?: boolean;
  setHasFooter?: Dispatch<SetStateAction<boolean>>;
}>({});

interface ModalType extends FC<ModalProps> {
  Header: FC<ModalSubComponentProps>;
  Body: FC<ModalSubComponentProps>;
  Footer: FC<ModalSubComponentProps>;
  Title: FC<ModalSubComponentProps>;
}

interface ModalProps extends PropsWithChildren {
  show: boolean;
  onHide?: () => void;
  size?: string;
  customSize?: string;
  position?: string;
  className?: string;
  previewSize?: { width: number; height: number };
}

interface ModalSubComponentProps extends PropsWithChildren {
  className?: string;
  closeButton?: boolean;
  centered?: boolean;
}

const CustomModal: ModalType = ({
  children,
  show,
  onHide,
  size,
  customSize,
  className,
  previewSize,
}) => {
  const [hasHeader, setHasHeader] = useState(false);
  const [hasFooter, setHasFooter] = useState(false);

  useEffect(() => {
    if (show && !previewSize) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => document.body.classList.remove('overflow-hidden');
  }, [show]);

  useEffect(() => {
    const handleEscape = (e: any) => {
      if (e.key === 'Escape' && show) {
        onHide && onHide();
      }
    };

    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, [show, onHide]);

  return (
    <ModalContext.Provider
      value={{
        onHide,
        previewSize,
        hasHeader,
        setHasHeader,
        hasFooter,
        setHasFooter,
      }}
    >
      <div
        className={classNames(
          'fixed inset-x-0 top-0 z-[1055] flex items-center justify-center bg-black/[0.8] overflow-y-auto overflow-x-hidden transition-all ease-in-out duration-300',
          { 'opacity-100': show, 'opacity-0 pointer-events-none': !show },
        )}
        style={{
          width: previewSize ? `${previewSize.width}px` : '100vw',
          height: previewSize ? `${previewSize.height}px` : '100vh',
        }}
        onClick={onHide}
      >
        <div
          id="default-modal"
          aria-hidden="true"
          className={classNames(
            'relative max-w-[calc(100vw_-_1rem)] h-auto w-full p-2 box-content',
            {
              'sm:max-w-xl': (!size && !customSize) || size === 'md',
              'sm:max-w-4xl': size === 'lg',
              'sm:max-w-6xl': size === 'xl',
              'sm:p-4': !previewSize,
            },
            customSize,
          )}
        >
          <div
            className={classNames(
              'relative flex flex-col max-h-[calc(100vh_-_1rem)] sm:max-h-[calc(100vh_-_2rem)] bg-true-white overflow-auto rounded-md shadow transition-transform duration-300 ease-out',
              { 'translate-y-0': show, '-translate-y-10': !show },
              className,
            )}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  );
};

const Header: FC<ModalSubComponentProps> = ({
  children,
  className,
  closeButton,
}) => {
  const { onHide, previewSize, setHasHeader } = useContext(ModalContext);

  useEffect(() => setHasHeader && setHasHeader(true), [setHasHeader]);

  return (
    <div
      className={classNames(
        'flex items-center justify-between px-4 py-4 gap-4 border-b border-grey-200 border-solid',
        { 'sm:px-8': !previewSize },
        className,
      )}
    >
      {children}

      {closeButton && (
        <X
          weight="bold"
          size="1.5rem"
          className="text-black/[0.5] hover:text-black cursor-pointer"
          onClick={onHide}
        />
      )}
    </div>
  );
};

const Body: FC<ModalSubComponentProps> = ({
  children,
  className,
  centered,
}) => {
  const { previewSize, hasHeader, hasFooter } = useContext(ModalContext);

  return (
    <div
      className={classNames(
        {
          'flex flex-col gap-4': true,
          'items-center text-center': centered,
          'px-4': !className?.match(/\b(px-|p-)/),
          'sm:px-8': !previewSize && !className?.match(/\b(px-|p-)/),
          'pt-4': hasHeader && !className?.match(/\b(pt-|p-)/),
          'pt-6': !hasHeader && !className?.match(/\b(pt-|p-)/),
          'pb-4': hasFooter && !className?.match(/\b(pb-|p-)/),
          'pb-6': !hasFooter && !className?.match(/\b(pb-|p-)/),
        },
        className,
      )}
    >
      {children}
    </div>
  );
};

const Footer: FC<ModalSubComponentProps> = ({ children, className }) => {
  const { previewSize, setHasFooter } = useContext(ModalContext);

  useEffect(() => setHasFooter && setHasFooter(true), [setHasFooter]);

  return (
    <div
      className={classNames(
        'px-4 py-4 border-t border-grey-200 border-solid',
        { 'sm:px-8': !previewSize },
        className,
      )}
    >
      {children}
    </div>
  );
};

const Title: FC<ModalSubComponentProps> = ({ children, className }) => {
  return (
    <h4 className={classNames('leading-normal', className)}>{children}</h4>
  );
};

CustomModal.Header = Header;
CustomModal.Body = Body;
CustomModal.Footer = Footer;
CustomModal.Title = Title;

export default CustomModal;
