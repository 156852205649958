import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { DEMO_ID } from '../../../../constants';
import DemoAddOverlay from '../../components/demoAddOverlay';

const DemoComponent = (props) => {
  const { handleUploadStart } = props;

  const eventId = useParams()?.eventId;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const demoThumbnailClass =
    'w-full sm:w-[25vw] aspect-video object-cover rounded-md';

  if (!isDemo) return null;

  return (
    <button
      className={`${demoThumbnailClass} relative self-center`}
      onClick={handleUploadStart}
    >
      <DemoAddOverlay
        element={demoData.hlReel}
        addClass={`${demoThumbnailClass} text-[1.75rem]`}
      />

      <img
        src={demoData.hlReel.thumbnailUrl}
        alt="Demo Highlight Reel"
        className={demoThumbnailClass}
      />
    </button>
  );
};

export default DemoComponent;
