import React, { useState } from 'react';
import classNames from 'classnames';
import VideoThumbnail from 'react-video-thumbnail';

import {
  isEmpty,
  round,
  msecToSec,
  getFileUrl,
  getThumbnail,
} from '../../../Utils';

import { SideIcon } from '../../../components/icons';

import ClipPreview from './clipPreview';
import DemoAddOverlay from './demoAddOverlay';
import ThumbnailStatus from './thumbnailStatus';

const statusDict = {
  IN_QUEUE: 'In queue...',
  UPLOADING: 'Uploading the file',
  CONVERTING: 'Converting the file',
  GENERATING_THUMBNAIL: 'Generating the thumbnail',
  EXTRACTING_CLIPS: 'Extracting the clips',
  ERROR: 'An error occurred!',
};

const ThumbnailBlock = (props) => {
  const {
    groupId,
    video,
    selectedClip,
    setSelectedClip,
    deletingClips,
    deleteClip,
    clipSelections,
    isBig,
    isDemo,
    handleDemoClips,
  } = props;

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};

  const [thumbnailImg, setThumbnailImg] = useState(null);

  // find if a video is checked by default
  const findCheckedVideo = (video) => {
    // find if the face already has selections data
    const foundSelections = clipSelections?.find((s) => s.faceId === groupId);

    // if it does – find index of the wildcard it's used for
    if (foundSelections) {
      const i = foundSelections.wildcardsFiller.findIndex(
        (clip) => clip.clipId === video.elementId,
      );

      // return index + 1 for UI purposes and to avoid 0 to be considered as false
      if (i !== -1) return i + 1;
    }

    return false;
  };

  const handleClickClip = (clip) => {
    const selectedClip = {
      clipId: clip.elementId,
      duration: msecToSec(clip.segment.durationMilliseconds),
    };

    setSelectedClip(selectedClip);
  };

  const handleOnClick = () => {
    if (isDemo) handleDemoClips(video.elementId, true);
    else if (groupId) handleClickClip(video);
  };

  const handleOnDelete = (e) => {
    e.stopPropagation();

    if (isDemo) handleDemoClips(video.elementId, false);
    else deleteClip(video);
  };

  const duration = video.segment?.durationMilliseconds / 1000 || null;

  // find if the video is checked by default. returns number or false
  const isChecked = findCheckedVideo(video);
  const isCurrent =
    !isEmpty(selectedClip) && selectedClip?.clipId === video.elementId;

  const containerClass = classNames({
    block: true,
    'w-[var(--upload-el-width)]': isBig,
    'w-[var(--thumbnail-width)]': !isBig,
  });

  const wrapperContainer = classNames({
    'group relative transition-all duration-300': true,
    'shadow-salmon': isCurrent,
    'hover:scale-110': !isDemo,
  });

  const thumbnailClass = 'w-full aspect-video rounded-md object-cover';

  return (
    <div className={containerClass} key={video.elementId}>
      <button
        className={`${thumbnailClass} ${wrapperContainer}`}
        onClick={handleOnClick}
      >
        {/* img overlay – contains delete & status icons, duration and checkbox */}
        <div
          className={
            `${thumbnailClass} absolute flex flex-col items-center justify-center p-1 text-center` +
            (statusDict[video.status] ? ' bg-black-overlay-200' : '')
          }
        >
          {/* delete icon – shows X or spinner if deleting */}
          {((!isDemo && deleteClip) ||
            (isDemo && !demoData.isLimited && video.isSelected)) && (
            <SideIcon
              icon="delete"
              itemType="clip"
              isProcessing={deletingClips.includes(video.elementId)}
              onClick={handleOnDelete}
              hidePopover={isDemo}
            />
          )}

          {/* select overlay */}
          {video.status && (
            <React.Fragment>
              {/* status icon (check / spinner / exclamation) */}
              <ThumbnailStatus status={video.status} />

              {/* select status */}
              {statusDict[video.status] && (
                <div className="absolute bottom-[10%] text-[0.5rem] text-white hidden sm:block">
                  {statusDict[video.status]}
                </div>
              )}
            </React.Fragment>
          )}

          {/* below elements only present on clips */}

          {/* clip duration */}
          {duration && (
            <div className="absolute bottom-0 right-0 text-xs text-white z-[2]">
              <div className="min-w-[50px] pt-3 pr-1.5 pb-1.5 pl-4 rounded-tl-[100%] rounded-br-md bg-black-overlay-600">
                {round(duration)}s
              </div>
            </div>
          )}

          {/* show wildcard number clip selected for (if any) */}
          {isChecked && (
            <div
              className={
                'absolute w-6 h-6 flex items-center justify-center bg-white rounded-full text-sm font-bold text-success-900' +
                (isCurrent ? ' bg-primary-50 text-primary-900' : '')
              }
            >
              {isChecked}
            </div>
          )}
        </div>

        {/* demo overlay */}
        {isDemo && (
          <DemoAddOverlay
            element={video}
            addClass={`${thumbnailClass} text-[1.25rem]`}
          />
        )}

        {/* clip preview */}
        {(!isDemo || video.isSelected) && (
          <ClipPreview video={video} addClass={thumbnailClass} />
        )}

        {/* gets thumbnail from video and saved it in state */}
        {!video.frameId && !video.thumbnailUrl && (
          <VideoThumbnail
            videoUrl={getFileUrl(video.elementId, video.name)}
            thumbnailHandler={(thumbnail) => setThumbnailImg(thumbnail)}
            renderThumbnail={false}
          />
        )}

        {/* thumbnail itself */}
        <img
          src={
            video.thumbnailUrl ?? thumbnailImg ?? getThumbnail(video.frameId)
          }
          className={thumbnailClass}
          alt={`${video.name} Thumbnail`}
        />
      </button>

      {/* file name */}
      <div className="text-xs mt-1 leading-none break-words">
        {!video.segment ? video.name : `From '${video.name.split('@')[0]}'`}
      </div>
    </div>
  );
};

export default ThumbnailBlock;
