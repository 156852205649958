import React, { useState } from 'react';
import classNames from 'classnames';
import { PiArrowSquareOut, PiCopy, PiCheck } from 'react-icons/pi';
import { useEventStore } from 'stores/event';

import { handleCopy } from '../../Utils';
import { trackShareCopyLink, trackShareOpenLink } from '../../analytics';

const LinkBox = (props) => {
  const {
    type,
    link,
    variant,
    btnOnly,
    addClass,
    handleOpenTrack = () => {},
    handleCopyTrack = () => {},
  } = props;
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [isCopied, setIsCopied] = useState(false);

  const containerClass = classNames({
    'relative flex items-center justify-between h-12 px-3 py-2 gap-3 rounded-md': true,
    'bg-gradient-primary-right text-white': variant === 'pink' || !variant,
    'bg-grey-200 text-black': variant === 'grey',
    'border border-black border-solid text-black': variant === 'white',
  });

  const iconClass = classNames({
    'flex items-center justify-center p-2 text-[1.25rem] rounded-md cursor-pointer': true,
    'hover:bg-white hover:text-primary-900': variant === 'pink' || !variant,
    'hover:bg-black hover:text-white':
      variant === 'grey' || variant === 'white',
  });

  const openLink = () => {
    window.open(link, '_blank');

    if (type) trackShareOpenLink(type, currentEvent);
    else handleOpenTrack();
  };

  const clickCopy = () => {
    handleCopy(link, setIsCopied);

    if (type) trackShareCopyLink(type, currentEvent);
    else handleCopyTrack();
  };

  if (btnOnly)
    return (
      <div className={`flex items-center justify-center ${addClass ?? ''}`}>
        <div className={iconClass} onClick={openLink}>
          <PiArrowSquareOut />
        </div>
        <div
          className={iconClass}
          onClick={() => {
            clickCopy();
          }}
        >
          {isCopied ? <PiCheck /> : <PiCopy />}
        </div>
      </div>
    );

  return (
    <div className={`${containerClass} ${addClass ?? ''}`}>
      <div className="w-[85%] whitespace-nowrap overflow-hidden text-ellipsis text-sm">
        {link}
      </div>

      <div className="flex items-center justify-center">
        <div className={iconClass} onClick={openLink}>
          <PiArrowSquareOut />
        </div>
        <div className={iconClass} onClick={() => clickCopy()}>
          {isCopied ? <PiCheck /> : <PiCopy />}
        </div>
      </div>
    </div>
  );
};

export default LinkBox;
