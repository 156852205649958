import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';

const ChangingTip = (props) => {
  const { type, tipsList } = props;

  const [tipIndex, setTipIndex] = useState(0);

  useEffect(() => {
    const tipsTimer = setInterval(() => {
      setTipIndex((prevIndex) => getRandomIndex(prevIndex, tipsList.length));
    }, 5000);

    return () => clearInterval(tipsTimer);
  }, [tipsList.length]);

  const getRandomIndex = (currentIndex, arrayLength) => {
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * arrayLength);
    } while (newIndex === currentIndex);
    return newIndex;
  };

  if (type === 'page-spinner')
    return (
      <div className="absolute bottom-[-150%] sm:bottom-[-110%] text-center">
        <span>{parse(tipsList[tipIndex])}</span>
      </div>
    );

  if (type === 'tip-box')
    return (
      <div className="flex items-center justify-center px-4 py-2 gap-4 italic rounded-md border border-primary-900 border-solid">
        {tipsList[tipIndex].icon}
        <span className="max-w-[90%]">{parse(tipsList[tipIndex].tip)}</span>
      </div>
    );

  return <div>{parse(tipsList[tipIndex])}</div>;
};

export default ChangingTip;
