import React from 'react';
import parse from 'html-react-parser';

import { isEmpty, isPlural } from '../../../../Utils';
import {
  TemplateShotBlock,
  getWildcardText,
} from '../../../../components/video';

const HlReelSchema = (props) => {
  const { event } = props;

  if (!event || isEmpty(event.wildcards)) return null;

  return (
    <div className="flex flex-col items-center gap-2">
      <div>
        <b>Here's what you've set up in the Project Settings</b>. Please upload
        a highlight reel with{' '}
        {parse(
          getWildcardText(event.wildcards, 'wildcard placeholder', 'at most'),
        )}
        .
      </div>

      <div className="flex flex-wrap items-center gap-2">
        <TemplateShotBlock type="selects" />

        {event.wildcards.map((wildcard, index) => (
          <React.Fragment key={index}>
            <TemplateShotBlock type="wildcard" id={`${index}-wildcard`}>
              <h6 className="text-sm font-bold">Wildcard</h6>

              <div className="text-sm">
                {wildcard} second{isPlural(parseFloat(wildcard))}
              </div>
            </TemplateShotBlock>

            <TemplateShotBlock type="selects" />
          </React.Fragment>
        ))}
      </div>

      <div className="text-sm italic">
        You can upload a highlight reel with fewer or shorter wildcards but not
        vice versa. The wildcards in your video highlight reel can also be in a
        different order.
      </div>
    </div>
  );
};

export default HlReelSchema;
