import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { isEmpty, getDemoSelected } from '../../../../Utils';
import { Button } from '../../../../components/general';
import { DEMO_ID } from '../../../../constants';

import { useEventStore } from '../../../../stores/event';

import PeopleSummary from './peopleSummary';

const DemoComponent = (props) => {
  const { setPeople, handleDemoPeople, handleUploadStart } = props;

  const eventId = useParams()?.eventId;

  const currentEvent = useEventStore((state) => state.currentEvent);

  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const { isBtnDisabled, popoverBody } = useMemo(() => {
    let isBtnDisabled = false;
    let popoverBody = '';

    if (demoData.isLimited) {
      isBtnDisabled = !isEmpty(getDemoSelected(demoData.people, true));
      popoverBody = 'Select all people to upload';
    } else {
      isBtnDisabled = isEmpty(getDemoSelected(demoData.people));
      popoverBody = 'Select at least 1 person to upload';
    }

    return { isBtnDisabled, popoverBody };
  }, [demoData.isLimited, demoData.people]);

  if (!isDemo) return null;

  const selectAll = () => {
    demoData.people.forEach((person) =>
      handleDemoPeople(person.face.elementId, true),
    );
  };

  const imitateUploading = () => {
    const selectedPeople = getDemoSelected(demoData.people);

    handleUploadStart();

    setPeople([]);

    const interval = setInterval(() => {
      setPeople((prevPeople) => {
        const nextPeople = [...prevPeople];

        if (nextPeople.length < selectedPeople.length) {
          nextPeople.push(selectedPeople[prevPeople.length]);

          // last person was just 'uploaded'
          if (nextPeople.length === selectedPeople.length) {
            clearInterval(interval);

            demoData.isPeopleUploaded = true;
            localStorage.setItem('demoData', JSON.stringify(demoData));
          }
        }
        return nextPeople;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  return (
    <React.Fragment>
      <Button onClick={selectAll} variant="link" className="self-center">
        Select All
      </Button>

      <PeopleSummary
        event={currentEvent}
        people={demoData.people}
        isDemo
        handleDemoPeople={handleDemoPeople}
      />

      <Button
        onClick={imitateUploading}
        className="w-full sm:w-1/2 self-center"
        disabled={isBtnDisabled}
        allowPointerEvents
        popover={{
          header: 'Cannot upload',
          body: popoverBody,
          hide: !isBtnDisabled,
        }}
      >
        Upload
      </Button>
    </React.Fragment>
  );
};

export default DemoComponent;
