import React from 'react';

import {
  InputGroup,
  FloatingLabel,
  InputGroupText,
} from '../../../components/form';

const DetailsSection = (props) => {
  const {
    event,
    name,
    setName,
    instagram,
    setInstagram,
    customFieldData,
    setCustomFieldData,
  } = props;

  return (
    <React.Fragment>
      <FloatingLabel
        label="First name"
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />

      {event.customFieldLabel && (
        <FloatingLabel
          label={event.customFieldLabel}
          name="customFieldData"
          value={customFieldData}
          onChange={(e) => setCustomFieldData(e.target.value)}
        />
      )}

      <div className="flex flex-col gap-2">
        <div>
          Be featured, get exclusive content or sneak peek behind the scenes of
          this event via Instagram!
        </div>

        <InputGroup>
          <InputGroupText>@</InputGroupText>

          <FloatingLabel
            label="Instagram"
            name="instagram"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            className="w-full"
          />
        </InputGroup>
      </div>
    </React.Fragment>
  );
};

export default DetailsSection;
