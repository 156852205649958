import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useEventStore } from 'stores/event';

import { getVBLinkParts, getPluralPhrase } from '../../../Utils';

import {
  trackClickDoneButton,
  trackClickMainBackButton,
  trackClickTakeMeBack,
} from '../../../analytics';

import { FancyBtn } from '../../../components/custom';
import { Modal } from '../../../components/general';

import { URL, DEMO_ID } from '../../../constants';

const DoneBtn = (props) => {
  const { subheading, isBack, addClass } = props;

  const uploadData = JSON.parse(localStorage.getItem('uploadData')) ?? {};

  const currentEvent = useEventStore((state) => state.currentEvent);

  const [showAnalyseModal, setShowAnalyseModal] = useState(false);

  const { eventId, page } = getVBLinkParts(window.location.pathname);

  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const navigate = useNavigate();

  const { url, text, showModal } = useMemo(() => {
    const isAttendeeReview =
      currentEvent?.attendeeReviewData?.isAttendeeReview ?? false;
    const isAssigneeAccess = currentEvent?.isAssigneeAccess;
    let url = isAssigneeAccess ? URL.VB_ASSIGNEE : URL.VIDEO_BUILDER;
    let text = 'Done';
    let showModal = false;

    if (!isBack && !isAssigneeAccess && uploadData.eventId === eventId) {
      if (!uploadData.peopleCount && !isAttendeeReview) {
        url = URL.VB_PEOPLE;
        text = 'Next Step: Upload People';
      } else if (!uploadData.isHlReelUploaded) {
        url = URL.VB_HL_REEL;
        text = 'Next Step: Upload Highlight Reel';
      } else if (!uploadData.clipsCount) {
        url = URL.VB_CLIPS;
        text = 'Next Step: Upload Clips';
      }

      // show modal – if is not attendee review, it is in upload stage, all assets uploaded, and user didn't reject analysis before
      else if (
        !isAttendeeReview &&
        !isAssigneeAccess &&
        currentEvent.stage === 'upload' &&
        uploadData.peopleCount &&
        uploadData.isHlReelUploaded &&
        uploadData.clipsCount &&
        !uploadData.rejectedAnalysis
      ) {
        showModal = true;
      }
    }

    return { url, text, showModal };
  }, [currentEvent, eventId, isBack, uploadData]);

  useEffect(() => {
    if (showModal) setShowAnalyseModal(true);
  }, [showModal]);

  const handleBackClick = () => {
    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);

    trackClickMainBackButton(page, currentEvent);
  };

  const handleNextClick = () => {
    navigate(`/${eventId}${url}`);

    trackClickDoneButton(page, currentEvent, text);
  };

  const onClickAnalyse = () => {
    navigate(`/${eventId}${URL.VIDEO_BUILDER}?startAnalysis=true`);
  };

  const onClickTakeMeBack = () => {
    navigate(`/${eventId}${URL.VIDEO_BUILDER}`);

    localStorage.setItem(
      'uploadData',
      JSON.stringify({ ...uploadData, rejectedAnalysis: true }),
    );

    trackClickTakeMeBack(page, currentEvent);
  };

  const btnClass =
    'w-full min-h-[var(--form-el-height)] self-center flex flex-col items-center justify-center p-4 gap-2 rounded-md bg-primary-900 text-white hover:opacity-80';

  if (isBack)
    return (
      <button
        onClick={handleBackClick}
        className={`${btnClass} ${addClass ?? ''}`}
      >
        <div className="font-bold">Back</div>

        {subheading && <div className="text-sm">{subheading}</div>}
      </button>
    );

  return (
    <React.Fragment>
      <button
        onClick={handleNextClick}
        className={`${btnClass} ${addClass ?? ''}`}
      >
        <div className="font-bold">{text}</div>
      </button>

      <Modal show={showAnalyseModal} onHide={() => setShowAnalyseModal(false)}>
        <Modal.Body centered>
          <h4>Ready to analyse uploaded assets?</h4>

          <div>
            Analyse <b>{getPluralPhrase(uploadData.clipsCount, 'clip')}</b> for{' '}
            <b>{getPluralPhrase(uploadData.peopleCount, 'person')}</b>. Our AI
            will look for the best clips to add to your Highlight Reels wildcard
            shots.
          </div>

          <FancyBtn text="Analyse" onClick={onClickAnalyse} />

          {!isDemo && (
            <div
              className="underline cursor-pointer hover:text-primary-900"
              onClick={onClickTakeMeBack}
            >
              Not yet, take me back
            </div>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DoneBtn;
