import React from 'react';
import {
  trackUGModalClose,
  trackUGModalSelectRole,
} from '../../../../analytics';

import { Button, Modal, Logo } from '../../../../components/general';

import { UG_ID } from '../../../../constants';

const UserGuidingModal = (props) => {
  const { show, onHide } = props;

  const onBtnClick = (type) => {
    onHide();

    trackUGModalSelectRole(type);

    const guideId =
      type === 'marketer'
        ? UG_ID.ONBOARDING_MARKETER
        : UG_ID.ONBOARDING_VIDEOGRAPHER;

    window.userGuiding.previewGuide(guideId);
  };

  const onClose = () => {
    onHide();

    trackUGModalClose();
  };

  return (
    <Modal show={show} onHide={onClose} size="lg" className="text-white">
      <Modal.Body className="items-center px-4 py-6 md:p-12 sm:gap-7 text-center bg-cc-purple-700 rounded-md">
        <div className="text-xl sm:text-2xl font-medium leading-10">
          Welcome to{' '}
          <Logo isWhite width="140px" className="align-middle inline-block" />
        </div>

        <div className="text-lg sm:text-xl">
          <b>Before you begin!</b> Tell us about yourself.
          <br />
          What kind of work do you do?
        </div>

        <div className="w-full sm:w-4/5 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-8">
          <Button
            onClick={() => onBtnClick('marketer')}
            variant="white-black"
            className="w-4/5 sm:w-1/2 text-md sm:text-lg"
          >
            Marketer / Event Organiser
          </Button>

          <Button
            onClick={() => onBtnClick('videographer')}
            variant="white-black"
            className="w-4/5 sm:w-1/2 text-md sm:text-lg"
          >
            Videographer
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UserGuidingModal;
