import React, { useState, useMemo } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { PiCaretDown, PiCaretRight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '../../../Utils';
import { trackHomeClickViewAllProjects } from '../../../analytics';
import { URL } from '../../../constants';
import { Event } from '../../../types';

import EventBlock from './eventBlock';

const ProjectsContainer = (props: {
  heading: string;
  events: Event[];
  isCollapse?: boolean;
  hideHeading?: boolean;
  projectNumLimit?: number;
}) => {
  const {
    heading,
    events,
    isCollapse,
    hideHeading,
    projectNumLimit = Infinity,
  } = props;

  const [collapseState, setCollapseState] = useState<boolean>(true);

  const navigate = useNavigate();

  const eventsUpd = useMemo(() => {
    return events.length > projectNumLimit
      ? events.slice(0, projectNumLimit - 1)
      : events;
  }, [events, projectNumLimit]);

  if (isEmpty(events)) return null;

  const isShow = isCollapse ? collapseState : true;

  return (
    <div className="w-full flex flex-col gap-4">
      <Accordion
        expanded={isShow}
        onChange={() => setCollapseState(!collapseState)}
        className="shadow-none"
        disableGutters
        sx={{
          '.MuiAccordionSummary-root': { minHeight: 0 },
          ':before': { display: 'none' },
        }}
      >
        <AccordionSummary
          className="p-0 cursor-default"
          sx={{ '.MuiAccordionSummary-content': { margin: 0 } }}
        >
          {isCollapse ? (
            <div className="w-full flex items-center p-2 gap-2 rounded-md cursor-pointer hover:bg-grey-100">
              <div className="text-[1.1rem] leading-none">
                {isShow ? <PiCaretDown /> : <PiCaretRight />}
              </div>

              <h5>{heading}</h5>
            </div>
          ) : (
            !hideHeading && <h5>{heading}</h5>
          )}
        </AccordionSummary>

        <AccordionDetails className="flex flex-wrap mt-4 p-0 gap-4 lg:gap-7">
          {eventsUpd.map((event) => (
            <EventBlock event={event} key={event.eventId} />
          ))}

          {events.length > projectNumLimit && (
            <div
              className="w-[var(--event-block-width)] h-min aspect-video flex items-center justify-center text-lg uppercase rounded-md border border-grey-900 border-solid hover:border-primary-900 hover:text-primary-900 cursor-pointer"
              onClick={() => {
                navigate(URL.DASH_PROJECTS);
                window.scrollTo({ top: 0, behavior: 'smooth' });
                trackHomeClickViewAllProjects(heading);
              }}
            >
              View All
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProjectsContainer;
