import React from 'react';
import classNames from 'classnames';

type Props = {
  children?: React.ReactNode;
  onClick?: any;
  className?: string;
};

const InputGroupText = (props: Props) => {
  const { children, onClick, className } = props;

  return (
    <span
      className={classNames(
        'flex items-center justify-center px-3 py-1.5 bg-grey-100 rounded-md border border-grey-200 border-solid',
        { 'h-[var(--form-el-height)]': !className?.includes('h-') },
        className,
      )}
      onClick={onClick}
    >
      {children}
    </span>
  );
};

export default InputGroupText;
