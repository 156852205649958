import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  Dispatch,
  SetStateAction,
  FC,
} from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
import { hotjar } from 'react-hotjar';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty, validateEmail, isProduction } from '../../../Utils';

import { trackLogInSuccess, trackLogInError } from '../../../analytics';

import { Form, FloatingLabel, PasswordField } from '../../../components/form';
import { Button, Checkbox } from '../../../components/general';

import config from '../../../config';
import { URL } from '../../../constants';

import { getUserEvents } from '../../../services/api/events';
import { signIn } from '../../../services/api/sessions';
import { useUserStore } from '../../../stores/user';
import { Event } from '../../../types';

import OnboardingPage from '../components/onboardingPage';
import ResetPwModal from './resetPwModal';

interface ErrorType {
  emailErr?: string;
  passwordErr?: string;
  emailPwErr?: string;
  forgotPwEmailErr?: string;
}

const Login: FC = () => {
  const setStoreIsLoggingIn = useUserStore((state) => state.setIsLoggingIn);

  const [email, setEmail] = useState<string>(
    sessionStorage.getItem('email') || '',
  );
  const [password, setPassword] = useState<string>('');
  const [isKeepSignIn, setIsKeepSignIn] = useState<boolean>(true);
  const [errors, setErrors] = useState<ErrorType>({});
  const [showResetPwModal, setShowResetPwModal] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const queryParameters = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  useEffect(() => {
    const onMount = async () => {
      if (queryParameters.get('forgotPassword')) {
        setShowResetPwModal(true);
        window.history.pushState(null, '', URL.LOGIN);
      }
    };

    onMount();
  }, []);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    setValue: Dispatch<SetStateAction<string>>,
  ) => {
    const value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errorsTemp: ErrorType = {};

    const { emailErr } = validateEmail(email);
    if (emailErr) errorsTemp.emailErr = emailErr;

    if (!password.trim().length) {
      errorsTemp.passwordErr = 'Password is required';
    }

    if (!isEmpty(errorsTemp)) {
      setErrors(errorsTemp);
    } else {
      setIsLoggingIn(true);
      setStoreIsLoggingIn(true);

      try {
        const user = await signIn(email, password, isKeepSignIn);
        const userEvents = await getUserEvents(user.session.userId);

        const previousPage = localStorage.getItem('previousPage');
        navigate(previousPage || URL.HOME);
        localStorage.removeItem('previousPage');

        trackLogInSuccess(previousPage);

        if (isProduction()) {
          const advancedMatching: any = { em: email };
          const options = {
            autoConfig: true,
            debug: false,
          };
          ReactPixel.init(config.pixelID || '', advancedMatching, options);
          ReactPixel.track('Leads');

          if (hotjar.initialized()) {
            hotjar.identify(user.session.userId, { email });
          }
        }

        if (isMobile || isTablet) {
          if (!!userEvents.length) {
            const lastSeen = localStorage.getItem('lastSeenIntroModal');
            const notDemoEvent = userEvents.filter(
              (event: Event) => event.type !== 'demo',
            );
            if (!!notDemoEvent.length && lastSeen) {
              setTimeout(() => {
                toast.warning(
                  <div>
                    <div>CrowdClip&reg; is best used on a desktop.</div>
                    <div>
                      You can continue on your mobile device, but beware, things
                      will look a bit funny.
                    </div>
                  </div>,
                  {
                    autoClose: false,
                  },
                );
              }, 200);
            } else {
              localStorage.setItem('showToastAfterTheModal', 'true');
            }
          }
        }
      } catch (err) {
        console.log(err);

        const errMsg =
          err && typeof err === 'object' && 'errMsg' in err
            ? (err.errMsg as string)
            : null;

        if (errMsg) errorsTemp.emailPwErr = errMsg;
        else toast.error('Something went wrong. Try again later');

        setErrors(errorsTemp);
        trackLogInError(errMsg ?? 'Something went wrong. Try again later');
      } finally {
        setIsLoggingIn(false);
        setTimeout(() => setStoreIsLoggingIn(false), 1000);
      }
    }
  };

  return (
    <OnboardingPage title="Login" heading="Log in to CrowdClip®">
      <Form onSubmit={onSubmit} disabled={isLoggingIn}>
        <FloatingLabel
          label="Email"
          name="email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, setEmail)}
          isInvalid={!!errors.emailErr || !!errors.emailPwErr}
          errMsg={errors.emailErr}
          required
        />

        <PasswordField
          label="Password"
          name="password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e, setPassword)
          }
          isInvalid={!!errors.passwordErr || !!errors.emailPwErr}
          errMsg={errors.passwordErr ?? errors.emailPwErr}
        />

        <div className="flex flex-col sm:flex-row gap-4">
          <Checkbox
            id="login-checkbox"
            label="Keep me logged in"
            name="isKeepSignIn"
            checked={isKeepSignIn}
            onChange={(e) => setIsKeepSignIn(e.target.checked)}
            className="w-full sm:w-1/2 text-white"
          />

          {/* note: temp removed as the functionality is not implemented yet */}
          {/* <div className="w-full sm:w-1/2">
            <div
              onClick={() => setShowResetPwModal(true)}
              className="sm:float-right text-primary-900 underline hover:opacity-60 cursor-pointer"
            >
              Forgot password?
            </div>
          </div> */}
        </div>

        <Button className="w-full" isSubmitBtn isProcessing={isLoggingIn}>
          Log In
        </Button>

        <div className="text-white">
          Don't have an account? <Link to={URL.ENTER_EMAIL}>Sign up</Link>
        </div>
      </Form>

      <ResetPwModal
        show={showResetPwModal}
        onHide={() => setShowResetPwModal(false)}
        email={email}
      />
    </OnboardingPage>
  );
};

export default Login;
