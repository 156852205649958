import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { PiLightbulbFill, PiWarningFill, PiInfoFill } from 'react-icons/pi';

import { ChangingTip } from '../custom';

const Alert = (props) => {
  const { variant, alertData, generatedAlertText, tipsList, noIcon, addClass } =
    props;

  const alertClass = classNames({
    'w-full flex flex-col p-3 sm:p-4 gap-4 rounded-md': true,
    'bg-grey-100': !variant || variant === 'grey',
    'border border-error-900 border-solid': variant === 'red',
  });

  const textClass = classNames({
    'w-[calc(100%_-_2rem)] sm:w-[calc(100%_-_2.5rem)]': !noIcon,
    'w-full': noIcon,
  });

  const iconClass = classNames({
    'w-6 h-6': true,
    'text-black': !variant || variant === 'grey',
    'text-error-900': variant === 'red',
  });

  const getIcon = () => {
    switch (alertData.variant) {
      case 'tip':
        return <PiLightbulbFill className={iconClass} />;
      case 'warning':
        return <PiWarningFill className={iconClass} />;
      default:
      case 'info':
        return <PiInfoFill className={iconClass} />;
    }
  };

  return (
    <div className={`${alertClass} ${addClass ?? ''}`}>
      {generatedAlertText && (
        <div className="flex flex-row gap-2 sm:gap-4">
          <PiWarningFill size="1.5rem" />
          <div className="w-[calc(100%_-_2rem)] sm:w-[calc(100%_-_2.5rem)]">
            <b>Videos have already been generated.</b> {generatedAlertText}
          </div>
        </div>
      )}

      {alertData && (
        <div className="flex flex-row gap-2 sm:gap-4">
          {!noIcon && getIcon()}

          <div className={textClass}>
            {typeof alertData.text === 'string'
              ? parse(alertData.text)
              : alertData.text}
          </div>
        </div>
      )}

      {tipsList && <ChangingTip type="tip-box" tipsList={tipsList} />}
    </div>
  );
};

export default Alert;
