import React from 'react';
import { toast } from 'react-toastify';

import { isEmpty, isPlural, validateEmail, round } from './Utils';

import {
  trackSendVerificationEmailSuccess,
  trackSendVerificationEmailError,
} from './analytics';

import { getWildcardTextMaximum } from './components/video';
import { URL, EMAILS, FACE_EMAIL_STATUS } from './constants';

import { sendEmail, sendUserVerification } from './services/api/emails';
import { updateOneFace } from './services/api/mongodb';
import { incrementEmailedMetric } from './services/api/videoCreationMetrics';

// onboarding pages

export const sendVerificationEmail = async (email) => {
  try {
    const isSuccess = await sendUserVerification(email, window.location.origin);

    if (isSuccess) {
      toast.success('Verification email is sent!');
      trackSendVerificationEmailSuccess();
    }

    return isSuccess;
  } catch (err) {
    console.error('Error sending verification email:', err);
    toast.error('Failed to send verification email. Please try again later.');
    trackSendVerificationEmailError();
  }
};

// user email

export const sendMobileReminder = async (user) => {
  const emailData = {
    recipientEmail: user.email,
    templateId: EMAILS.MOBILE_REMINDER,
    dynamicTemplateData: {
      name: user.firstName,
      share_url: window.location.href,
    },
  };

  const isSuccess = await sendEmail(emailData);

  if (isSuccess) toast.success('Email launched into the digital cosmos!');
  else toast.error('Something went wrong. Try again later');
};

export const sendStepCompleteEmail = async (user, event, step) => {
  const emailData = {
    recipientEmail: user.email,
    templateId: EMAILS.VB_STEP_COMPLETE,
    dynamicTemplateData: {
      event_name: event.name,
      text: step,
      share_url: `${window.location.origin}/${event.eventId}${URL.VIDEO_BUILDER}`,
    },
  };

  await sendEmail(emailData);
};

// emails to attendees

export const checkFaceEmail = (e, id, faceEmails, setFaceEmails) => {
  e.preventDefault();

  const faceEmailsTemp = { ...faceEmails };

  const { emailErr } = validateEmail(faceEmailsTemp[id].email || '');
  faceEmailsTemp[id].err = emailErr;

  setFaceEmails({ ...faceEmails });
};

export const sendFaceEmail = async (
  e,
  type,
  event,
  face,
  faceEmails,
  setFaceEmails,
  showToast,
) => {
  const id = face.elementId;

  checkFaceEmail(e, id, faceEmails, setFaceEmails);

  if (!faceEmails[id].err) {
    let email = faceEmails[id].email;

    faceEmails[id].status = FACE_EMAIL_STATUS.SENDING;
    setFaceEmails({ ...faceEmails });

    await updateOneFace({
      eventId: event.eventId,
      elementId: face.elementId,
      email,
    });

    let emailData = {
      recipientEmail: email,
    };

    switch (type) {
      case 'individual-creation':
        emailData = {
          ...emailData,
          templateId: event.emailsData?.creationTemplateId,
          dynamicTemplateData: {
            event_name: event.name,
            name: face.name,
            share_url: faceEmails[id].videoLink,
            text: event.emailsData?.creationTemplateText ?? '',
          },
        };
        break;
      case 'missing-clips':
        const reqShotsText = `?reqShots=${face.missingClips
          .map((clip) => round(clip.duration))
          .join(',')}`;

        emailData = {
          ...emailData,
          templateId: EMAILS.MISSING_ASSETS,
          dynamicTemplateData: {
            event_name: event.name,
            name: face.name,
            share_url: `${window.location.origin}${URL.CHECK_IN}${event.eventId}/${face.elementId}${reqShotsText}`,
            text: `${getWildcardTextMaximum(
              face.missingClips,
              'video',
              'around',
            )} in <b>${event.videoMode}</b> orientation`,
          },
        };
        break;
      default:
        break;
    }

    const isSuccess = await sendEmail(emailData);

    if (isSuccess) {
      showToast &&
        toast.success(
          <div>
            Sent email to <b>{email}</b>
          </div>,
        );

      faceEmails[id].status = FACE_EMAIL_STATUS.SENT;
      setFaceEmails({ ...faceEmails });

      setTimeout(() => {
        faceEmails[id].status = FACE_EMAIL_STATUS.RESEND;
        setFaceEmails({ ...faceEmails });
      }, 2000);

      if (faceEmails[id].metricId)
        await incrementEmailedMetric(faceEmails[id].metricId);
    } else {
      showToast &&
        toast.error(
          <div>
            Email couldn't be sent to <b>{email}</b>, please try again later.
          </div>,
        );

      faceEmails[id].status = FACE_EMAIL_STATUS.NOT_SENT;
      setFaceEmails({ ...faceEmails });
    }

    return { isSuccess };
  } else return { isInvalid: true };
};

export const sendBulkFaceEmail = async (
  e,
  type,
  event,
  faces,
  faceEmails,
  setFaceEmails,
  setIsEmailsSending,
) => {
  setIsEmailsSending(true);

  let successCount = 0;
  let invalidList = [];
  let errorList = [];

  for (let id in faceEmails) {
    // to make both new & old flow work
    // TODO: remove 'checked' part when new flow is live
    if (
      faceEmails[id].checked ||
      (faceEmails[id].checked === undefined && !!faceEmails[id].email)
    ) {
      let foundFace = faces.find((face) => face.elementId === id);

      const { isSuccess, isInvalid } = await sendFaceEmail(
        e,
        type,
        event,
        foundFace,
        faceEmails,
        setFaceEmails,
      );

      if (isInvalid) invalidList.push(foundFace.name);
      else if (isSuccess) successCount++;
      else errorList.push(foundFace.name);
    }
  }

  if (successCount > 0)
    toast.success(
      <div>
        Sent email to{' '}
        <b>
          {successCount} attendee{isPlural(successCount)}
        </b>
        .
      </div>,
    );

  if (!isEmpty(invalidList))
    toast.error(
      <div>
        Some emails are invalid. Update email for{' '}
        <b>{invalidList.join(', ')}</b>.
      </div>,
    );

  if (!isEmpty(errorList))
    toast.error(
      <div>
        Email{isPlural(errorList)} couldn't be sent to{' '}
        <b>{errorList.join(', ')}</b>, please try again later.
      </div>,
    );

  setIsEmailsSending(false);

  return { isSuccess: successCount > 0 };
};
