import React from 'react';

import HeadingWithBadge from '../../../pages/VideoBuilder/components/headingWithBadge';

const PageHeader = (props) => {
  const { heading, subheading, icons, customClassName } = props;

  return (
    <div className={`w-full flex flex-col gap-4 ${customClassName ?? ''}`}>
      {heading && (
        <div className="flex items-center justify-between gap-4">
          <HeadingWithBadge>
            <div className="font-bold uppercase text-xl sm:text-2xl leading-none">
              {heading}
            </div>
          </HeadingWithBadge>

          {icons}
        </div>
      )}

      {subheading && <div>{subheading}</div>}
    </div>
  );
};

export default PageHeader;
