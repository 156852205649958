import React from 'react';
import { PiPlus } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

import { trackCreateProject } from '../../../../analytics';
import { URL } from '../../../../constants';

const CreateProjectBtn = () => {
  const navigate = useNavigate();

  return (
    <button
      className="group relative w-[var(--event-block-width)] lg:w-[calc(var(--event-block-width)_*_1.25)] xl:w-[var(--event-block-width)] aspect-video flex flex-col items-center justify-center p-2 gap-2 bg-white rounded-md"
      onClick={() => {
        navigate(URL.PROJECT_SETTINGS);
        trackCreateProject('Home');
      }}
    >
      <PiPlus className="w-8 h-8 sm:w-10 sm:h-10 fill-[url('#icon-purple-gradient')] transition-all group-hover:scale-[200%] group-hover:mt-8" />

      <div className="font-bold uppercase bg-gradient-purple-light bg-clip-text text-transparent transition-all group-hover:invisible group-hover:opacity-0">
        Create Project
      </div>
    </button>
  );
};

export default CreateProjectBtn;
