import React from 'react';
import classNames from 'classnames';

const InputGroup = (props: { children?: React.ReactNode }) => {
  const { children } = props;

  const containerClass = classNames(
    'flex',

    // border radius
    '[&>:not(:first-child)_*_input]:rounded-l-none [&>:not(:first-child)]:rounded-l-none',
    '[&>:not(:last-child)_*_input]:rounded-r-none [&>:not(:last-child)]:rounded-r-none',

    // border width
    '[&>:not(:first-child)_*_input]:border-l-0 [&>:not(:first-child)]:border-l-0',
  );

  return <div className={containerClass}>{children}</div>;
};

export default InputGroup;
