import React from 'react';
import { Link } from 'react-router-dom';

import { trackSharePageClickHelp } from '../../../analytics';
import { URL } from '../../../constants';

const ShareFooter = (props) => {
  const { type } = props;

  const imgClass = 'w-6 h-6';

  return (
    <div className="w-full flex flex-col my-7 gap-2 text-center text-xs text-[#5e5e5e]">
      <div>
        Click{' '}
        <Link to={URL.HELP} onClick={() => trackSharePageClickHelp(type)}>
          here
        </Link>{' '}
        if you are unable to share your video using a mobile device.
      </div>
      <div>
        This video was automatically curated using CrowdClip's automated AI
        video editing software.
      </div>
      <div>
        If you would like to create your own premium video content at the click
        of a button, simply visit{' '}
        <a href="https://crowdclip.com" target="_blank" rel="noreferrer">
          www.crowdclip.com
        </a>
        .
      </div>

      <div className="flex justify-center mt-2 gap-2.5">
        <a
          href="https://www.instagram.com/crowdclip/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/27a34d6d-b3ef-40b0-9d89-aaaba1cc7674/256x253.png"
            className={imgClass}
            alt="Instagram Icon"
          />
        </a>
        <a
          href="https://www.facebook.com/crowdnclip/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/f4934c0e-cddf-451a-a2f7-1213e8aa8959/256x256.png"
            className={imgClass}
            alt="Facebook Icon"
          />
        </a>
        <a
          href="https://twitter.com/crowdclip/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/4d1b66cb-62fd-44bd-903d-6ee4b8358865/256x256.png"
            className={imgClass}
            alt="Twitter Icon"
          />
        </a>
        <a
          href="https://au.linkedin.com/company/crowdclip"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="http://cdn.mcauto-images-production.sendgrid.net/47f51ffc24a0aec6/1a9a4fa0-73cc-40e9-bf7e-21de98bc7ca0/256x256.png"
            className={imgClass}
            alt="LinkedIn Icon"
          />
        </a>
      </div>

      <hr className="my-4" />

      <div className="italic">
        Copyright &copy; 2025 CrowdClip&reg;, All Rights Reserved
      </div>
    </div>
  );
};

export default ShareFooter;
