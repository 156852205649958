import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const theme = createTheme({
  typography: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>

      <svg width="0" height="0">
        <linearGradient
          id="icon-primary-gradient"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop stopColor="var(--primary-color)" offset="0%" />
          <stop stopColor="var(--gradient-secondary-color)" offset="100%" />
        </linearGradient>
        <linearGradient
          id="icon-purple-gradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop stopColor="var(--purple-color-light)" offset="0%" />
          <stop stopColor="var(--primary-color-light)" offset="100%" />
        </linearGradient>
        <linearGradient
          id="icon-purple-gradient-2"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop stopColor="var(--primary-color-light)" offset="0%" />
          <stop stopColor="var(--purple-color-light)" offset="1000%" />
        </linearGradient>
      </svg>
    </HelmetProvider>
  </ThemeProvider>,
);
