import React, { useState, useEffect, useRef } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import { getUrl } from '../../Utils';

import { ErrorModal } from '../../components/modals';

import { URL } from '../../constants';

import { getCreationWithData } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';
import { incrementViewedMetric } from '../../services/api/videoCreationMetrics';

import PaymentModal from './components/paymentModal';
import ShareVideoUI from './components/shareVideoUI';

const isTouchDevice = isMobile || isTablet;

const ShareVideo = () => {
  const { eventId, faceId } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const paymentState = queryParameters.get('paymentState');

  const [event, setEvent] = useState(null);
  const [person, setPerson] = useState(null);
  const [isPlayed, setIsPlayed] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const videoRef = useRef(null);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (paymentState) {
        setPaymentModalState(paymentState);
        window.history.pushState({}, '', getUrl(URL.SHARE, eventId, faceId));
      }

      await fetchVideoData();

      setIsLoading(false);
    };

    onMount();
  }, [eventId, faceId, paymentState]);

  const fetchVideoData = async () => {
    try {
      const event = await getEventPublic(eventId);
      const person = await getCreationWithData(eventId, faceId);

      if (!event || !person) setPageError(404);

      setEvent(event);
      setPerson(person);
    } catch (err) {
      console.error(err);
      setError('Error fetching the video');
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handlePlayVideo = async () => {
        await incrementViewedMetric(person?.metrics?.metricId);

        // open video in full screen on mobile
        if (isTouchDevice && !isPlayed) {
          var video = document.getElementById('video');

          if (video.requestFullscreen) video.requestFullscreen();
          else if (video.webkitRequestFullscreen)
            video.webkitRequestFullscreen();
          else if (video.msRequestFullScreen) video.msRequestFullScreen();

          setIsPlayed(true);
        }
      };

      videoElement.addEventListener('play', handlePlayVideo);
      return () => {
        videoElement.removeEventListener('play', handlePlayVideo);
      };
    }
  }, [isPlayed, person?.metrics?.metricId]);

  const title = `${person?.face?.name ?? 'Name'} at ${event?.name ?? 'Event'}`;

  return (
    <React.Fragment>
      <ShareVideoUI
        width={window.innerWidth}
        isLoading={isLoading}
        pageError={pageError}
        title={title}
        description={`Personalized highlight reel of ${title}`}
        event={event}
        person={person}
        videoRef={videoRef}
        setPaymentModalState={setPaymentModalState}
      />

      <PaymentModal
        show={!!paymentModalState}
        onHide={() => setPaymentModalState(null)}
        event={event}
        person={person}
        state={paymentModalState}
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </React.Fragment>
  );
};

export default ShareVideo;
