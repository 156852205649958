import config from '../../config';
import { getApiKeyClient } from '../client/http';

const {
  apiBaseUrl: { auth: baseUrl },
} = config;

const apiKeyClient = () => getApiKeyClient(baseUrl);

export const checkEmailAvailability = async (email) => {
  try {
    const response = await apiKeyClient().post('api/available', { email });
    return response.data.availability;
  } catch (err) {
    throw new Error(`Error checking email availability: ${err.message}`);
  }
};

export const verifyEmail = async (userId, verificationCode) => {
  try {
    const response = await apiKeyClient().get(
      `api/user/${userId}/verify?code=${verificationCode}`,
    );
    return response.status;
  } catch (err) {
    throw new Error(`Error verifying email: ${err.message}`);
  }
};

export const getUserData = async (userId) => {
  try {
    const response = await apiKeyClient().get(`api/user/${userId}`);
    return response.data.user;
  } catch (err) {
    throw new Error(`Error getting user data: ${err.message}`);
  }
};

export const updateUserAccount = async (newUser) => {
  try {
    const response = await apiKeyClient().put('api/user', newUser);
    return response.data;
  } catch (err) {
    throw new Error(
      `Error updating new user: ${err.response.data.message || err.message}`,
    );
  }
};

export const createUser = async (newUser) => {
  try {
    const response = await apiKeyClient().post('api/user', {
      ...newUser,
      // TODO: change type to event-owner when API privilege level allows
      roles: [{ type: 'admin', name: null, key: null, value: null }],
    });
    return response.data;
  } catch (err) {
    throw new Error(
      `Error creating new user: ${err.response.data.message || err.message}`,
    );
  }
};
