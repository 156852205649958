import React from 'react';

import { SellingPoint } from '../../../components/custom';

const PageWithSideBar = (props) => {
  const { mainContent, sideContent, showDemoTipText } = props;

  return (
    <div className="flex flex-col lg:flex-row justify-between gap-3 lg:gap-[4%]">
      <div className="flex flex-col gap-6 lg:w-[55%]">{mainContent}</div>

      <div className="border-r border-grey-200 border-solid" />

      <div className="flex flex-col gap-6 lg:w-[calc(var(--page-width)_*_0.37_-_1px)]">
        {showDemoTipText === 'upload' && (
          <SellingPoint text="All assets are already pre-loaded for you in this sample demo, so there's no need to worry about meeting the upload requirements." />
        )}

        {showDemoTipText === 'summary' && (
          <SellingPoint text="The features described below will be available for all live projects." />
        )}

        {sideContent}
      </div>
    </div>
  );
};

export default PageWithSideBar;
