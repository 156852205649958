import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Logo } from '../../../components/general';
import { PageSpinner } from '../../../components/layout/content';
import CheckinStepper from './checkinStepper';
import DetailsStage from './detailsStage';
import EmailStage from './emailStage';
import VideoStage from './videoStage';

const CheckInPageUI = (props) => {
  const {
    isPreview,
    width,
    isLoading,
    pageError,
    title,
    event,
    bannerUrl,
    logoUrl,
    face,
    faceId,
    reqShots,
    refetchFace,
  } = props;

  const renderContent = () => {
    if (!face) {
      return (
        <>
          <CheckinStepper eventId={event.eventId} activeStep={0} />
          <EmailStage event={event} isPreview={isPreview} />
        </>
      );
    }
    // for missing video page (all event types) – show video stage
    else if (!!faceId)
      return (
        <VideoStage
          event={event}
          face={face}
          isMissingVideo={true}
          refetchFace={refetchFace}
          reqShots={reqShots}
          isPreview={isPreview}
        />
      );
    // for brand event – show video stage
    else if (event.type === 'brand')
      return (
        <>
          <CheckinStepper eventId={event.eventId} activeStep={1} />
          <VideoStage
            event={event}
            face={face}
            refetchFace={refetchFace}
            isPreview={isPreview}
          />
        </>
      );
    // else if videographer or demo – show details stage
    else
      return (
        <>
          <CheckinStepper eventId={event.eventId} activeStep={1} />
          <DetailsStage event={event} face={face} isPreview={isPreview} />
        </>
      );
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={title}
      noTips
      isFullPage
    >
      <img
        className={classNames('w-full object-cover', {
          'aspect-video': width < 640,
          'aspect-[20/9]': width >= 640,
        })}
        src={bannerUrl}
        alt="Event Banner"
      />

      <div
        className={classNames(
          'relative flex flex-col items-center mx-auto p-4 gap-8 bg-white rounded-md shadow-grey-2',
          {
            'top-[-1rem] w-[90%]': width < 640,
            'top-[-10rem] w-[500px]': width >= 640,
          },
        )}
      >
        <img
          className="w-24 h-24 mt-[-4rem] mb-[-1rem] object-center object-cover rounded-md overflow-hidden"
          src={logoUrl}
          alt="Event Logo"
        />

        <h5 className="w-full text-center font-normal">
          <b>{!!faceId ? 'Upload clips' : 'Check-in'}</b> to generate your
          personalised video at <b>{event.name}</b>
        </h5>

        {renderContent()}

        <Link
          to="https://crowdclip.com"
          target="_blank"
          className="block text-center no-underline hover:opacity-100"
        >
          Powered by <Logo width="80px" noLink />
        </Link>
      </div>
    </PageSpinner>
  );
};

export default CheckInPageUI;
