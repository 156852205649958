import React from 'react';
import { PiInfoFill } from 'react-icons/pi';

import { Popover } from '../../../components/general';

const HeadingWithInfo = (props) => {
  const { heading, subheading, infoHeader, infoBody } = props;

  const popover = (infoHeader || infoBody) && (
    <Popover
      header={infoHeader}
      body={<div className="flex flex-col gap-2">{infoBody}</div>}
      isWide
    >
      <span className="leading-none">
        <PiInfoFill
          className="hover:fill-[url('#icon-primary-gradient')] cursor-pointer p-0"
          size="1.2em"
        />
      </span>
    </Popover>
  );

  if (heading)
    return (
      <h4 className="flex items-center gap-1">
        <div className="font-semibold">{heading}</div>

        {popover}
      </h4>
    );

  if (subheading)
    return (
      <h5 className="flex items-center gap-1">
        <div className="font-semibold">{subheading}</div>

        {popover}
      </h5>
    );

  return null;
};

export default HeadingWithInfo;
