import React from 'react';
import { PiShareFat, PiEnvelope } from 'react-icons/pi';

import { checkFaceEmail, sendFaceEmail } from '../../UtilsEmail';
import { FACE_EMAIL_STATUS } from '../../constants';
import { Button } from '../general';

import { Form, InputGroup, FloatingLabel, InputGroupText, FormError } from './';

const EmailSendGroup = (props) => {
  const {
    type,
    event,
    face,
    faceEmails,
    setFaceEmails,
    afterEmailSend,
    btnText,
    className,
    isDemo,
  } = props;
  const emailObj = faceEmails[face.elementId] || {};
  const onChangeEmail = (e, id) => {
    faceEmails[id].email = e.target.value;
    faceEmails[id].err = null;

    setFaceEmails({ ...faceEmails });
  };

  const handleSend = async (e) => {
    const { isSuccess } = await sendFaceEmail(
      e,
      type,
      event,
      face,
      faceEmails,
      setFaceEmails,
      true,
    );

    if (isSuccess && afterEmailSend) afterEmailSend();
  };

  const creationTemplateId = event.emailsData?.creationTemplateId;

  const popoverBody = isDemo
    ? 'Sharing email is not available in the sample project'
    : !creationTemplateId
      ? 'First, select the email template above'
      : null;

  return (
    <Form
      onSubmit={(e) =>
        checkFaceEmail(e, face.elementId, faceEmails, setFaceEmails)
      }
      disabled={emailObj.status === FACE_EMAIL_STATUS.SENDING || isDemo}
      formClassName={className}
      className="flex-row gap-2"
    >
      <div className="grow">
        <InputGroup>
          <InputGroupText className="h-12">
            <PiEnvelope />
          </InputGroupText>

          <FloatingLabel
            type="email"
            name="email"
            value={emailObj.email}
            onChange={(e) => onChangeEmail(e, face.elementId)}
            placeholder={`Enter email address to ${btnText.toLowerCase()}...`}
            isInvalid={emailObj.err}
            required
            className="grow"
            customHeight="h-12"
          />
        </InputGroup>

        <FormError errMsg={emailObj.err} />
      </div>

      <Button
        onClick={handleSend}
        variant="black"
        form="square"
        isThin
        className="h-12"
        disabled={!!popoverBody}
        allowPointerEvents
        isSubmitBtn
        isProcessing={emailObj.status === FACE_EMAIL_STATUS.SENDING}
        isDone={emailObj.status === FACE_EMAIL_STATUS.SENT}
        spinner={{ size: 'md' }}
        popover={{
          header: 'Unable to share',
          body: popoverBody,
          placement: 'top',
          hide: !popoverBody,
        }}
      >
        <PiShareFat size="1.25rem" />
      </Button>
    </Form>
  );
};

export default EmailSendGroup;
