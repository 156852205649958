import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { isEmpty, getPluralPhrase } from '../../../Utils';

import { PageSpinner } from '../../../components/layout/content';
import { ErrorModal } from '../../../components/modals';

import { URL } from '../../../constants';

import { getUploadedFaces } from '../../../services/api/faces';
import { getSelectFiles } from '../../../services/api/files';
import { getTemplateData } from '../../../services/api/template';

import { useEventStore } from '../../../stores/event';

import VBBtn from '../VB/components/vbBtn';

const AssigneeVB = () => {
  const eventId = useParams()?.eventId;

  const queryParameters = new URLSearchParams(window.location.search);
  const email = queryParameters.get('email');
  const accessCode = queryParameters.get('accessCode');

  const assigneeData = JSON.parse(localStorage.getItem('assigneeData')) ?? {};

  const setAssigneeEvent = useEventStore((state) => state.setAssigneeEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);

  const [showPeople, setShowPeople] = useState(false);
  const [isPeopleUploaded, setIsPeopleUploaded] = useState(false);
  const [uploadedPeople, setUploadedPeople] = useState([]);

  const [showHlReel, setShowHlReel] = useState(false);
  const [isHlReelUploaded, setIsHlReelUploaded] = useState(false);

  const [showClips, setShowClips] = useState(false);
  const [isClipsUploaded, setIsClipsUploaded] = useState(false);
  const [uploadedClips, setUploadedClips] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  // set event id on mount
  useEffect(() => {
    setAssigneeEvent(eventId);
  }, [eventId, setAssigneeEvent]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      let accessPages = [];
      const assigneeEmail = email
        ? email.replace(/ /g, '+')
        : assigneeData.email ?? '';
      const code = accessCode ?? assigneeData[currentEvent.eventId]?.accessCode;

      const isPeopleChecked =
        currentEvent.assigneeData?.peopleAssignees.includes(assigneeEmail);
      const isHlReelChecked =
        currentEvent.assigneeData?.hlReelAssignees.includes(assigneeEmail);
      const isClipsChecked =
        currentEvent.assigneeData?.clipsAssignees.includes(assigneeEmail);
      const isAccessCode = code === moment(currentEvent.created).format('x');

      if (isPeopleChecked || isAccessCode) {
        setShowPeople(true);
        accessPages.push('people');
        await fetchPeopleData();
      }
      if (isHlReelChecked || isAccessCode) {
        setShowHlReel(true);
        accessPages.push('hl-reel');
        await fetchHlReelData();
      }
      if (isClipsChecked || isAccessCode) {
        setShowClips(true);
        accessPages.push('clips');
        await fetchClipsData();
      }

      if (!isEmpty(accessPages))
        localStorage.setItem(
          'assigneeData',
          JSON.stringify({
            ...assigneeData,
            hasAccess: true,
            email: assigneeEmail,
            [currentEvent.eventId]: { accessCode: code, accessPages },
          }),
        );
      else setPageError(403);

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  const fetchPeopleData = async () => {
    try {
      const faces = await getUploadedFaces(eventId, true);

      setIsPeopleUploaded(!isEmpty(faces));
      setUploadedPeople(faces);
    } catch (err) {
      console.error(`Error fetching the people: ${err}`);
      setError('Error fetching the people');
    }
  };

  const fetchHlReelData = async () => {
    try {
      const { hasTemplate } = await getTemplateData(eventId, true);

      setIsHlReelUploaded(hasTemplate);
    } catch (err) {
      console.error(`Error fetching the highlight reel: ${err}`);
      setError('Error fetching the highlight reel');
    }
  };

  const fetchClipsData = async () => {
    try {
      const uploadedClips = await getSelectFiles(eventId, true);

      setIsClipsUploaded(!isEmpty(uploadedClips));
      setUploadedClips(uploadedClips);
    } catch (err) {
      console.error(`Error fetching the clips: ${err}`);
      setError('Error fetching the clips');
    }
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Video Builder – ${currentEvent?.name}`}
      isPageContainer
      className="items-center gap-4"
    >
      <div className="text-center text-xl sm:text-2xl font-bold">
        {currentEvent?.name}
      </div>

      <div className="text-center">
        You’ve been granted access to upload assets for this project. Choose a
        section below to begin uploading when you’re ready!
      </div>

      {showPeople && (
        <VBBtn
          type="people"
          heading="People"
          subheading={
            !isPeopleUploaded
              ? 'Show our AI who to make highlight reels for'
              : `<b>${getPluralPhrase(uploadedPeople, 'person')}</b> checked-in`
          }
          isComplete={isPeopleUploaded}
          link={`/${eventId}${URL.VB_PEOPLE}`}
        />
      )}

      {showHlReel && (
        <VBBtn
          type="hl-reel"
          heading="Highlight Reel"
          subheading={
            !isHlReelUploaded
              ? 'Upload a highlight reel template'
              : '<b>Highlight reel</b> template uploaded'
          }
          isComplete={isHlReelUploaded}
          link={`/${eventId}${URL.VB_HL_REEL}`}
        />
      )}

      {showClips && (
        <VBBtn
          type="clips"
          heading="Clips"
          subheading={
            !isClipsUploaded
              ? 'Upload clips for our AI to find the best moments for each person'
              : `<b>${getPluralPhrase(uploadedClips, 'clip')}</b> uploaded`
          }
          isComplete={isClipsUploaded}
          link={`/${eventId}${URL.VB_CLIPS}`}
        />
      )}

      {currentEvent?.stage === 'analysis' && (
        <div className="text-center text-sm italic">
          It seems the analysis for this project has already begun. Please check
          with the project owner to see if you’re still required to upload any
          assets.
        </div>
      )}

      {currentEvent?.stage === 'generation' && (
        <div className="text-center text-sm italic">
          It seems the generation for this project has already begun. Please
          check with the project owner to see if you’re still required to upload
          any assets.
        </div>
      )}

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default AssigneeVB;
