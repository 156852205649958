import React from 'react';
import classNames from 'classnames';
import { PiXBold, PiPencilFill } from 'react-icons/pi';

import { Spinner, Tooltip } from '../general';

const SideIcon = (props) => {
  const { icon, itemType, isProcessing, onClick, hidePopover } = props;

  const positionClass = classNames({
    'absolute z-[2]': true,
    'top-[1.25rem] right-[1.25rem]': icon === 'delete' && itemType === 'person',
    'top-[-0.55rem] right-[-0.55rem]':
      icon === 'delete' && itemType !== 'person',
    'top-[-0.55rem] left-[-0.55rem]': icon === 'edit',
  });

  const iconClass = classNames({
    'w-6 h-6 items-center justify-center rounded-full text-white border border-white border-solid cursor-pointer transition-all duration-300 hidden group-hover:flex group-focus:flex hover:scale-110': true,
    'bg-success-900': icon === 'edit',
    'bg-error-900': icon === 'delete',
  });

  // delete icon – shows X or spinner if deleting, also has popover of hover
  if (icon === 'delete')
    return (
      <Tooltip
        text={
          <div className="w-72">
            Clicking 'X' will <b>delete</b> the {itemType} and this action{' '}
            <b>cannot be undone</b>
          </div>
        }
        hide={!itemType || hidePopover}
        className={positionClass}
      >
        <div id="delete-side-icon" className={iconClass} onClick={onClick}>
          {isProcessing ? (
            <Spinner size="2xs" color="white" />
          ) : (
            <PiXBold className="text-sm" />
          )}
        </div>
      </Tooltip>
    );

  // edit icon – shows pen
  if (icon === 'edit')
    return (
      <div className={classNames(positionClass, iconClass)} onClick={onClick}>
        <PiPencilFill className="text-sm" />
      </div>
    );

  return null;
};

export default SideIcon;
