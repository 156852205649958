import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { getProjSideNavData } from '../../../Utils';

import { useEventStore } from '../../../stores/event';

const PageContainer = ({
  children,
  title = '',
  description = '',
  addClass = '',
  isFullPage = false,
}) => {
  const currentEvent = useEventStore((state) => state.currentEvent);
  const { isShowProjSideNav } = getProjSideNavData(currentEvent);

  const contentClass = classNames(
    {
      'flex flex-col transition-all': true,
      'mt-[var(--header-height)]': !addClass.includes('mt-'),
      'py-6': !addClass.includes('py-'),
      'w-[var(--page-width)] mx-[5vw]': !isFullPage,
      'lg:mx-[calc((100vw_-_var(--page-width))_/_2)]': !isShowProjSideNav,
      'lg:ml-[var(--size-nav-width)] lg:mr-[var(--page-spacing)]':
        isShowProjSideNav,
    },
    addClass,
  );

  return (
    <div className={contentClass}>
      {(title || description) && (
        <Helmet>
          {title && <title>{title} | CrowdClip</title>}
          {description && <meta name="description" content={description} />}
        </Helmet>
      )}

      {children}
    </div>
  );
};

export default PageContainer;
