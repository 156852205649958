import React from 'react';
import parse from 'html-react-parser';

import { getDuration, getFileUrl, getThumbnail } from '../../../../Utils';

import { PageSubHeader } from '../../../../components/layout/content';
import { getWildcardText } from '../../../../components/video';

import HlReelPreview from './hlReelPreview';

const HlReelPreviewContainer = (props) => {
  const { hlReel, requiredShots, showVideo } = props;

  return (
    <div className="w-full flex flex-col gap-4">
      <PageSubHeader
        heading="Highlight Reel"
        subheading={
          hlReel && (
            <>
              Here is a preview of your highlight reel. It is{' '}
              <b>{getDuration(hlReel.parameters.length)}</b> long with{' '}
              {parse(getWildcardText(requiredShots, 'wildcard spot'))}.
            </>
          )
        }
      />

      {showVideo && hlReel && (
        <video
          className="w-full h-[calc(9_*_var(--page-width)_/_16)] sm:w-1/2 sm:h-[calc(9_*_(var(--page-width)_*_0.5)_/_16)] rounded-md bg-black object-contain"
          src={getFileUrl(hlReel.parentElementId, hlReel.name, 'mp4', 0.5)}
          poster={hlReel.frameId ? getThumbnail(hlReel.frameId) : null}
          controls
        />
      )}

      <HlReelPreview hlReel={hlReel} />
    </div>
  );
};

export default HlReelPreviewContainer;
