import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import classNames from 'classnames';
import { isMobile, isTablet } from 'react-device-detect';
import {
  PiCaretDownBold,
  PiCheckCircleFill,
  PiHourglassMediumFill,
} from 'react-icons/pi';

const VBAccordion = (props) => {
  const {
    children,
    heading,
    subheading,
    headingBtn,
    progressText,
    isOpen,
    isDisabled,
  } = props;

  const [isExpanded, setIsExpanded] = useState(isOpen && !isDisabled);

  const isCompleted = progressText === 'Completed';

  return (
    <Accordion
      className="w-full border border-grey-200 border-solid rounded-md"
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      disableGutters
      sx={{ ':before': { display: 'none' } }}
      disabled={isDisabled}
    >
      <AccordionSummary
        expandIcon={<PiCaretDownBold size="1.2rem" />}
        className="items-start sm:items-center p-4 gap-1 sm:gap-4 cursor-pointer"
        sx={{ '.MuiAccordionSummary-content': { margin: '0' } }}
      >
        <div className="w-full flex items-start sm:items-center justify-between gap-2 sm:gap-4">
          <div className="flex flex-col gap-2">
            {heading && (
              <div className="flex items-center gap-2 text-xl font-bold uppercase leading-none">
                {heading}
                {headingBtn}
              </div>
            )}

            {subheading && <div>{subheading}</div>}
          </div>

          {progressText && !isDisabled && (
            <div
              className={classNames(
                'flex items-center gap-1 font-semibold whitespace-nowrap',
                { 'text-success-900': isCompleted },
              )}
            >
              {isCompleted ? (
                <PiCheckCircleFill size="1.2rem" />
              ) : (
                <PiHourglassMediumFill size="1.2rem" />
              )}

              {!isMobile && !isTablet && <div>{progressText}</div>}
            </div>
          )}
        </div>
      </AccordionSummary>

      {children && !isDisabled && (
        <AccordionDetails className="flex flex-col pt-0 gap-4">
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default VBAccordion;
