import React from 'react';
import { getPluralPhrase } from 'Utils';
import classNames from 'classnames';

const ProgressBar = (props) => {
  const { progress, fileName, estTime, isThin, isBoxed, addClass } = props;

  return (
    <div
      className={classNames({
        'w-full flex flex-col gap-1': true,
        'p-4 rounded-md border border-grey-200 border-solid': isBoxed,
      })}
    >
      {fileName && (
        <div className="mr-auto text-lg font-medium">{fileName}</div>
      )}

      <div
        className={classNames(
          {
            'bg-gray-200 rounded-full overflow-hidden': true,
            'h-16': !isThin,
            'h-8': isThin,
          },
          addClass || '',
        )}
      >
        <div
          className={classNames({
            'flex p-2 items-center justify-center rounded-l-full bg-blue-green leading-none font-bold text-white transition-all': true,
            'h-16 text-xl sm:text-2xl': !isThin,
            'h-8': isThin,
          })}
          style={{ width: `${progress}%` }}
        >
          {progress > (isThin ? 4 : 10) && `${progress}%`}
        </div>
      </div>

      {estTime && (
        <div className="ml-auto text-sm opacity-50">
          Estimated time: {getPluralPhrase(estTime, 'minute')}
        </div>
      )}
    </div>
  );
};

export default ProgressBar;
