import React from 'react';
import classNames from 'classnames';

const FormError = (props: { errMsg?: string; className?: string }) => {
  const { errMsg, className } = props;

  if (errMsg)
    return (
      <div className={classNames('mt-1 text-sm text-error-900', className)}>
        {errMsg}
      </div>
    );

  return null;
};

export default FormError;
