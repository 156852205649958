import React from 'react';
import { PiSparkleFill } from 'react-icons/pi';

import { Spinner } from '../general';

const FancyBtn = (props) => {
  const { text, isSubmitting, isDisabled, onClick } = props;

  return (
    <button
      className="group max-w-full w-72 h-20 flex items-center justify-center gap-4 uppercase text-xl font-bold text-lime-300 bg-true-black rounded-full border-4 border-lime-300 border-solid hover:bg-lime-300 hover:text-black disabled:pointer-events-none disabled:opacity-65"
      onClick={onClick}
      disabled={isDisabled}
    >
      <div
        className={`absolute transition-all duration-500 ${!isSubmitting ? 'opacity-100' : 'opacity-0'}`}
      >
        <PiSparkleFill /> {text}
      </div>

      <Spinner
        size="lg"
        color="lime"
        hoverColor="black"
        className={`absolute transition-all duration-500 ${isSubmitting ? 'opacity-100' : 'opacity-0'}`}
      />
    </button>
  );
};

export default FancyBtn;
