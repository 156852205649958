import React from 'react';

import { FloatingLabel, FormText } from '../../../components/form';

const EventDatesSelector = (props) => {
  const { startDate, endDate, onChangeStartDate, onChangeEndDate, errors } =
    props;

  return (
    <div className="flex flex-col sm:flex-row gap-6 sm:gap-10">
      <div className="w-full sm:w-1/2">
        <FloatingLabel
          label="Start Date"
          type="date"
          name="startDate"
          value={startDate}
          onChange={onChangeStartDate}
          isInvalid={!!errors.startDate}
          errMsg={errors.startDate}
          required
        />
        <FormText text="You won't be able to edit the project after it starts" />
      </div>

      <div className="w-full sm:w-1/2">
        <FloatingLabel
          label="End Date"
          type="date"
          name="endDate"
          value={endDate}
          onChange={onChangeEndDate}
          isInvalid={!!errors.endDate}
          errMsg={errors.endDate}
          required
        />

        <FormText text="Attendees won't be able to check in after the project ends" />
      </div>
    </div>
  );
};

export default EventDatesSelector;
