import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { isEmpty, getShareLink } from '../../../../Utils';

import {
  trackAnalysisClickOnPerson,
  trackAnalysisOpenHub,
  trackAnalysisOpenCreation,
  trackAnalysisClickUploadMoreClips,
  trackAnalysisOpenMAModal,
} from '../../../../analytics';

import { PageSubHeader } from '../../../../components/layout/content';

import { URL } from '../../../../constants';

import FaceCircle from '../../components/faceCircle';
import MissingAssetsModal from './missingAssetsModal';

const PeopleBlocks = (props) => {
  const {
    event,
    completePeople,
    incompletePeople,
    generatedPeople,
    requiredShots,
    setOpenedPersonId,
    isDemo,
  } = props;

  const [showMAModal, setShowMAModal] = useState(false);

  const handleClickPerson = (person, type) => {
    setOpenedPersonId(person.face.elementId);
    trackAnalysisClickOnPerson(event, type);
  };

  const handleClickGeneratedPerson = (person) => {
    const videoLink = getShareLink(event?.eventId, person.face.elementId);

    window.open(videoLink, '_blank');
    trackAnalysisOpenCreation();
  };

  const handleOpenMAModal = () => {
    setShowMAModal(true);
    trackAnalysisOpenMAModal();
  };

  return (
    <React.Fragment>
      {!isEmpty(completePeople) && (
        <div className="flex flex-row flex-wrap gap-6">
          {completePeople.map((person) => (
            <FaceCircle
              key={person.face.elementId}
              person={person}
              onClick={() => handleClickPerson(person, 'Complete')}
            />
          ))}
        </div>
      )}

      {!isEmpty(incompletePeople) && (
        <React.Fragment>
          <PageSubHeader
            heading="Not enough clips"
            subheading={
              <div>
                The people below don't appear in enough clips to create a
                personalised highlight reel.
                {!isDemo && (
                  <>
                    {' '}
                    <Link
                      to={`/${event?.eventId}${URL.VB_CLIPS}`}
                      onClick={trackAnalysisClickUploadMoreClips}
                    >
                      Upload more clips
                    </Link>{' '}
                    or{' '}
                    <span
                      onClick={handleOpenMAModal}
                      className="underline cursor-pointer text-primary-900 hover:opacity-60"
                    >
                      email these people
                    </span>{' '}
                    to fix this issue.
                  </>
                )}
              </div>
            }
          />

          <div className="flex flex-row flex-wrap gap-6">
            {incompletePeople.map((person) => (
              <FaceCircle
                key={person.face.elementId}
                person={person}
                requiredShots={requiredShots}
                onClick={() => handleClickPerson(person, 'Incomplete')}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      {!isEmpty(generatedPeople) && (
        <React.Fragment>
          <PageSubHeader
            heading="Already generated"
            subheading={
              <div>
                The people below already have their personalised videos
                generated. Their videos will not be re-generated.{' '}
                {!isDemo && (
                  <>
                    <Link
                      to={getShareLink(event?.eventId)}
                      onClick={trackAnalysisOpenHub}
                      target="_blank"
                    >
                      View their videos
                    </Link>
                    .
                  </>
                )}
              </div>
            }
          />

          <div className="flex flex-row flex-wrap gap-6">
            {generatedPeople.map((person) => (
              <FaceCircle
                key={person.face.elementId}
                person={person}
                onClick={!isDemo && (() => handleClickGeneratedPerson(person))}
              />
            ))}
          </div>
        </React.Fragment>
      )}

      <MissingAssetsModal
        show={showMAModal}
        onHide={() => setShowMAModal(false)}
        event={event}
        people={incompletePeople}
      />
    </React.Fragment>
  );
};

export default PeopleBlocks;
