import React from 'react';
import classNames from 'classnames';

const TemplateShotBlock = (props) => {
  const { type, id, children, className } = props;

  switch (type) {
    case 'selects':
      return (
        <div
          className={classNames(
            'group w-[2.5rem] sm:w-[4rem] aspect-video flex rounded-sm sm:rounded-md bg-grey-900',
            className,
          )}
        />
      );

    case 'wildcard':
      return (
        <div
          className={classNames(
            'group relative w-[6rem] sm:w-[7rem] aspect-video flex flex-col items-center justify-center rounded-md border border-grey-900 border-solid',
            className,
          )}
          id={id}
        >
          {children}
        </div>
      );

    default:
      return null;
  }
};

export default TemplateShotBlock;
