import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { isMobile, isTablet } from 'react-device-detect';

import { isEmpty } from '../../Utils';
import config from '../../config';
import { EVENT_TYPE_DEMO, EMAIL_LISTS } from '../../constants';

import { identifyUserLR } from '../../services/logrocketService';
import { useUserStore } from '../../stores/user';

import { getApiKeyClient } from '../client/http';

import { getUserData } from './auth';
import { subscribeContact } from './emails';
import { getUserEvents } from './events';

const INCORRECT_CRED_MESSAGES = [
  'User does not exist',
  'Password hashes do not match',
];

const {
  apiBaseUrl: { sessions: baseUrl },
} = config;
const apiClient = () => getApiKeyClient(baseUrl);

export const getSessionFromToken = async (token) => {
  if (!token) return null;

  // This should call the API to get the user details from the token
  try {
    // Simulate a delay to show the spinner
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    return jwtDecode(token);
  } catch (e) {
    return null;
  }
};

export const signIn = async (email, password, isKeepSignIn) => {
  const newSession = {
    email,
    password,
    remember: isKeepSignIn,
    origin: 'sessions.au.crowdclip.co',
    service: 'sessions.au',
  };

  try {
    const response = await apiClient().post(`/api/sign-in`, newSession);

    const accessToken = response.data.result.accessToken;
    const session = jwtDecode(accessToken);
    const userData = await getUserData(session.userId);

    const user = { ...userData, session };

    identifyUserLR(user);

    Cookies.set('token', response.data.result.refreshToken);
    useUserStore.setState((state) => ({ ...state, user, accessToken }));
    if (isKeepSignIn) {
      useUserStore.getState().saveAccessToken();
    }

    // add user to sendgrid list – if mobile and has no events
    if (isMobile || isTablet) {
      const userEvents = await getUserEvents(session.userId);
      const noEvents = isEmpty(
        userEvents.filter((event) => event.type !== EVENT_TYPE_DEMO),
      );

      if (noEvents) await subscribeContact(EMAIL_LISTS.MOBILE_SIGN_IN, user);
    }

    return user;
  } catch (error) {
    const message = error.response.data.message;
    if (INCORRECT_CRED_MESSAGES.includes(message)) {
      error.errMsg =
        'Incorrect credentials provided. Ensure both email and password are correct';
    }

    throw error;
  }
};
