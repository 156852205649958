import { isProduction } from '../../../Utils';
import { S3_BUCKET_URL } from '../../../constants';

const fifaFolder = `${S3_BUCKET_URL}.files/demo-fifa/`;

const getFifaIds = (type) => {
  switch (type) {
    case 'event':
      return isProduction()
        ? 'eb6e8339-32f7-4787-924d-c74040644410'
        : '42569265-cbdd-4f23-bd7c-9fccc14ac542';

    // attendees

    case 'attendee-1':
      return isProduction()
        ? 'd2da39b4-1f3f-40a1-bf05-497e98b0802b'
        : '62b810c1-5c08-46dd-9173-f5afe157d73b';

    case 'attendee-2':
      return isProduction()
        ? 'adda5630-f188-4051-bade-4cd1cc0e3c14'
        : 'b3b41f74-efc6-46a5-bcd1-f4ae9e45ab21';

    case 'attendee-3':
      return isProduction()
        ? 'b67189ac-94fc-43c6-a25a-9e5a43eade04'
        : '80713251-a6e8-4593-bd7a-c01b949b1949';

    // clips

    case 'clip-a':
      return isProduction()
        ? '90325c1a-f2c4-4152-9e30-254223dc76e7'
        : '3b27d48d-6eed-469b-babb-e88855ece0aa';

    case 'clip-b':
      return isProduction()
        ? '9936e603-2b86-4799-9727-eef7a03b0b16'
        : '68a28ff0-9f1e-4fec-9a7e-668f20256771';

    case 'clip-c':
      return isProduction()
        ? 'fb29a18f-23dd-4cfb-9936-e88618d12554'
        : '41c12ed2-dfdf-442d-a1c9-6844cd47f23f';

    case 'clip-d':
      return isProduction()
        ? '1e40ae24-506d-4f4a-9f14-70251ee5e10d'
        : '531b7c52-8d29-40c4-b1e2-4299425462c5';

    case 'clip-e':
      return isProduction()
        ? 'd37d064f-bedc-4a96-aafb-18419a814e19'
        : '5c01586b-1ded-4c8e-9892-2a53e21e3f0c';

    case 'clip-f':
      return isProduction()
        ? 'd2c3c6f7-255f-411c-8302-e9c81e0cc046'
        : '6bb805f4-d8c8-4c38-a99d-0149a652e318';

    // creations – matching clips ids

    case 'attendee-1-creation-1':
      return isProduction()
        ? {
            6: 'fcb80c89-e88d-432e-bf72-4fed0c7ac9ef',
            10: '5fc88528-9b35-4f02-9f61-f3747f37e8f8',
          }
        : {
            6: '16bd3675-3d30-41ef-9588-4f04d4e61fb5',
            10: '88d0c717-129c-4d8c-ba11-eb87d759c914',
          };
    case 'attendee-1-creation-2':
      return isProduction()
        ? {
            6: '5fc88528-9b35-4f02-9f61-f3747f37e8f8',
            10: 'fcb80c89-e88d-432e-bf72-4fed0c7ac9ef',
          }
        : {
            6: '88d0c717-129c-4d8c-ba11-eb87d759c914',
            10: '16bd3675-3d30-41ef-9588-4f04d4e61fb5',
          };

    case 'attendee-2-creation-1':
      return isProduction()
        ? {
            6: '9fa554a5-7298-48af-b346-751bc8933f09',
            10: '5fc64e9b-c442-4a9c-9278-b90e72ed5e56',
          }
        : {
            6: '071b6f81-6120-4de7-9252-7fc468125d23',
            10: '09a76a57-9986-483a-957a-41c8021fb66d',
          };
    case 'attendee-2-creation-2':
      return isProduction()
        ? {
            6: '5fc64e9b-c442-4a9c-9278-b90e72ed5e56',
            10: '9fa554a5-7298-48af-b346-751bc8933f09',
          }
        : {
            6: '09a76a57-9986-483a-957a-41c8021fb66d',
            10: '071b6f81-6120-4de7-9252-7fc468125d23',
          };

    case 'attendee-3-creation-1':
      return isProduction()
        ? {
            6: 'b31c7660-3db3-4fec-b441-37093b9ac75c',
            10: 'ec6c3ae0-7c08-40f2-9e96-95a6b1eaaa57',
          }
        : {
            6: '37c56852-ffce-44c4-b3d7-209ebce1eb3f',
            10: '2a23004e-8d6e-4814-bcab-f0100e5241fb',
          };
    case 'attendee-3-creation-2':
      return isProduction()
        ? {
            6: 'ec6c3ae0-7c08-40f2-9e96-95a6b1eaaa57',
            10: 'b31c7660-3db3-4fec-b441-37093b9ac75c',
          }
        : {
            6: '2a23004e-8d6e-4814-bcab-f0100e5241fb',
            10: '37c56852-ffce-44c4-b3d7-209ebce1eb3f',
          };

    default:
      return null;
  }
};

export const demoFifa = {
  isLimited: true,
  event: {
    eventId: getFifaIds('event'),
    name: 'FIFA Sample Project',
    type: 'demo',
    videoMode: 'landscape',
    stage: 'upload',
    payment: { paid: true },
  },
  people: [
    {
      face: {
        elementId: getFifaIds('attendee-1'),
        name: 'Attendee 1',
      },
      files: [{ url: `${fifaFolder}attendee-1.jpg` }],
    },
    {
      face: {
        elementId: getFifaIds('attendee-2'),
        name: 'Attendee 2',
      },
      files: [{ url: `${fifaFolder}attendee-2.jpg` }],
    },
    {
      face: {
        elementId: getFifaIds('attendee-3'),
        name: 'Attendee 3',
      },
      files: [{ url: `${fifaFolder}attendee-3.jpg` }],
    },
  ],
  hlReel: {
    url: `${fifaFolder}highlight-reel.mp4`,
    thumbnailUrl: `${fifaFolder}highlight-reel-thumbnail.png`,
    status: 'valid',
  },
  clips: [
    {
      elementId: getFifaIds('clip-a'),
      uid: getFifaIds('clip-a'),
      name: 'Clip A.mp4',
      url: `${fifaFolder}clip-a.mp4`,
      thumbnailUrl: `${fifaFolder}clip-a-thumbnail.png`,
    },
    {
      elementId: getFifaIds('clip-b'),
      uid: getFifaIds('clip-b'),
      name: 'Clip B.mp4',
      url: `${fifaFolder}clip-b.mp4`,
      thumbnailUrl: `${fifaFolder}clip-b-thumbnail.png`,
    },
    {
      elementId: getFifaIds('clip-c'),
      uid: getFifaIds('clip-c'),
      name: 'Clip C.mp4',
      url: `${fifaFolder}clip-c.mp4`,
      thumbnailUrl: `${fifaFolder}clip-c-thumbnail.png`,
    },
    {
      elementId: getFifaIds('clip-d'),
      uid: getFifaIds('clip-d'),
      name: 'Clip D.mp4',
      url: `${fifaFolder}clip-d.mp4`,
      thumbnailUrl: `${fifaFolder}clip-d-thumbnail.png`,
    },
    {
      elementId: getFifaIds('clip-e'),
      uid: getFifaIds('clip-e'),
      name: 'Clip E.mp4',
      url: `${fifaFolder}clip-e.mp4`,
      thumbnailUrl: `${fifaFolder}clip-e-thumbnail.png`,
    },
    {
      elementId: getFifaIds('clip-f'),
      uid: getFifaIds('clip-f'),
      name: 'Clip F.mp4',
      url: `${fifaFolder}clip-f.mp4`,
      thumbnailUrl: `${fifaFolder}clip-f-thumbnail.png`,
    },
  ],
  creations: {
    [getFifaIds('attendee-1')]: [
      {
        name: 'template@converted-cluster@Attendee 1',
        uri: '/demo-fifa/attendee-1-creation-1.mp4',
        matchingClips: getFifaIds('attendee-1-creation-1'),
      },
      {
        name: 'template@converted-cluster@Attendee 1',
        uri: '/demo-fifa/attendee-1-creation-2.mp4',
        matchingClips: getFifaIds('attendee-1-creation-2'),
      },
    ],
    [getFifaIds('attendee-2')]: [
      {
        name: 'template@converted-cluster@Attendee 2',
        uri: '/demo-fifa/attendee-2-creation-1.mp4',
        matchingClips: getFifaIds('attendee-2-creation-1'),
      },
      {
        name: 'template@converted-cluster@Attendee 2',
        uri: '/demo-fifa/attendee-2-creation-2.mp4',
        matchingClips: getFifaIds('attendee-2-creation-2'),
      },
    ],
    [getFifaIds('attendee-3')]: [
      {
        name: 'template@converted-cluster@Attendee 3',
        uri: '/demo-fifa/attendee-3-creation-1.mp4',
        matchingClips: getFifaIds('attendee-3-creation-1'),
      },
      {
        name: 'template@converted-cluster@Attendee 3',
        uri: '/demo-fifa/attendee-3-creation-2.mp4',
        matchingClips: getFifaIds('attendee-3-creation-2'),
      },
    ],
  },
};
