import React, { useState, useEffect } from 'react';
import { PiQuestionFill } from 'react-icons/pi';
import { isEmpty, isPlural, getPluralPhrase } from '../../../../Utils';

import { HeadingBtnGroup } from '../../../../components/custom';
import { Modal, Popover } from '../../../../components/general';

import ThumbnailsContainer from '../../components/thumbnailsContainer';
import HlReelPreview from './hlReelPreview';

const PersonClipsModal = (props) => {
  const {
    clipGroups,
    openedPerson,
    hlReel,
    requiredShots,
    clipSelections,
    setClipSelections,
    openedPersonId,
    setOpenedPersonId,
  } = props;

  const [groupCollapseStates, setGroupCollapseStates] = useState({});
  const [selectedClip, setSelectedClip] = useState({});

  // clear the selected clip when clicked outside of thumbnails container or highlight reel preview
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isEmpty(selectedClip)) {
        const thumbnailContainerEl = document.getElementById(
          openedPerson?.face.elementId,
        );
        const hlReelPreviewEl = document.getElementById(
          'hl-reel-preview-person',
        );

        if (
          !thumbnailContainerEl?.contains(event.target) &&
          !hlReelPreviewEl?.contains(event.target)
        )
          setSelectedClip({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedClip, openedPerson?.face.elementId]);

  //  toggle the collapse state of each group
  const toggleGroupCollapse = (groupId) => {
    const groupCollapseStatesTemp = { ...groupCollapseStates };

    groupCollapseStatesTemp[groupId] =
      groupCollapseStatesTemp[groupId] == null
        ? false
        : !groupCollapseStatesTemp[groupId];

    setGroupCollapseStates(groupCollapseStatesTemp);
  };

  // change selected clip for particular wildcard
  const handleChangeSelection = (wildcard) => {
    let clipSelectionsTemp = [...clipSelections];

    // find if the person already has selections data
    const foundSelections = clipSelectionsTemp.find(
      (s) => s.faceId === openedPerson.face.elementId,
    );

    // update clip selection
    foundSelections?.wildcardsFiller.forEach((w) => {
      if (w.clipId === selectedClip.clipId) w.clipId = null;
      else if (w.wildCardShotIndex === wildcard.shotIndex)
        w.clipId = selectedClip.clipId;
    });

    setClipSelections(clipSelectionsTemp);
  };

  // renders the thumbnails container for provided videos array
  const renderPersonThumbnails = () => {
    const videos = openedPerson.clips ?? [];

    const personId = openedPerson.face.elementId;

    return (
      <div>
        <h5 className="mb-3">
          {videos.length === 0
            ? 'No available clips'
            : `Available clip${isPlural(videos)} (${videos.length})`}
        </h5>

        <ThumbnailsContainer
          groupId={personId}
          videos={videos}
          isCollapsed={groupCollapseStates[personId]}
          onClickShowMoreBtn={() => toggleGroupCollapse(personId)}
          clipSelections={clipSelections}
          selectedClip={selectedClip}
          setSelectedClip={setSelectedClip}
        />
      </div>
    );
  };

  if (isEmpty(openedPerson)) return null;

  return (
    <Modal
      show={!isEmpty(openedPerson)}
      onHide={() => setOpenedPersonId(null)}
      customSize="sm:max-w-[calc((var(--thumbnail-width)_*_7)_+_10rem)]"
    >
      <Modal.Header className="flex-col-reverse sm:flex-row">
        <Modal.Title>
          <div className="flex gap-4 items-center">
            <img
              className="w-10 h-10 rounded-full object-cover"
              src={openedPerson.img}
              alt={`${openedPerson.face.name} Icon`}
            />

            <h4 className="m-0">
              {openedPerson.face.name} – Personalised video preview
            </h4>
          </div>
        </Modal.Title>

        <HeadingBtnGroup
          group={clipGroups}
          id={openedPersonId}
          setId={setOpenedPersonId}
        />
      </Modal.Header>

      <Modal.Body>
        <div className="text-sm italic">
          Our AI already selected recommended clips for{' '}
          {getPluralPhrase(requiredShots, 'wildcard spot')}, but you can
          re-select these clips{' '}
          <Popover
            header="To re-select a clip:"
            body={
              <div className="flex flex-col gap-1">
                <div>
                  1. In the list of attendee clips, click on any of the clips
                </div>
                <div>
                  2. Then, in the highlight reel preview click on the wildcard
                  you want that clip to be used for
                </div>
                <div>
                  If the wildcard is longer than the clip, you won't be able to
                  select it.
                </div>
                <div>
                  <i>Note: refreshing the page will reset your selections.</i>
                </div>
              </div>
            }
            isWide
            className="not-italic"
          >
            <span className="cursor-pointer">
              <PiQuestionFill className="text-[1.1rem] hover:fill-[url('#icon-primary-gradient')]" />
            </span>
          </Popover>
        </div>

        <HlReelPreview
          hlReel={hlReel}
          person={openedPerson}
          selectedClip={selectedClip}
          handleChangeSelection={handleChangeSelection}
        />

        {renderPersonThumbnails()}
      </Modal.Body>
    </Modal>
  );
};

export default PersonClipsModal;
