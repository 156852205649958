import React, { useState, useEffect, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { validateEmail } from '../../../Utils';
import { Form, FloatingLabel } from '../../../components/form';
import { Button, Modal } from '../../../components/general';
import { URL } from '../../../constants';

type Props = { show: boolean; onHide: any; email?: string };

const ResetPwModal = (props: Props) => {
  const { show, onHide, email = '' } = props;

  const [forgotPwEmail, setForgotPwEmail] = useState<string>('');
  const [forgotPwEmailErr, setForgotPwEmailErr] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    setForgotPwEmail(email);
  }, [email]);

  const onSubmitForgotPw = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { emailErr } = validateEmail(forgotPwEmail);
    if (emailErr) {
      setForgotPwEmailErr(emailErr);
    } else {
      // TODO: add reset password email
      navigate(URL.FORGOT_PASSWORD);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot your password?</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={onSubmitForgotPw}>
          <div>
            Enter your email address and we will send you instructions to reset
            your password.
          </div>

          <FloatingLabel
            label="Email"
            name="forgotPwEmail"
            value={forgotPwEmail}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setForgotPwEmail(e.target.value)
            }
            isInvalid={!!forgotPwEmailErr}
            errMsg={forgotPwEmailErr}
            required
          />

          <div className="text-sm italic">
            By clicking 'Continue' you give consent to send email to the
            provided email address.
          </div>

          <Button onClick={() => {}} type="submit" className="w-full">
            Continue
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPwModal;
