import React, { useState, useEffect } from 'react';
import {
  PiPlayFill,
  PiEnvelopeFill,
  PiDownloadSimpleFill,
  PiShareFatFill,
  PiCurrencyDollarSimpleBold,
} from 'react-icons/pi';

import { round, getPct } from '../../../../Utils';

const StatsBlock = (props) => {
  const { event, people, showPages } = props;

  const [stats, setStats] = useState({});

  useEffect(() => {
    let emailedNum = 0;
    let emailedTotal = 0;

    let viewedNum = 0;
    let viewedTotal = 0;

    let downloadedNum = 0;
    let downloadedTotal = 0;

    let sharedNum = 0;
    let sharedTotal = 0;

    let paidTotal = 0;
    let paidSum = 0;

    people.forEach((person) => {
      const { metrics } = person;

      if (metrics.emailedCount >= 1) emailedNum++;
      emailedTotal += metrics.emailedCount ?? 0;

      if (metrics.viewedCount >= 1) viewedNum++;
      viewedTotal += metrics.viewedCount ?? 0;

      if (metrics.downloadedCount >= 1) downloadedNum++;
      downloadedTotal += metrics.downloadedCount ?? 0;

      if (metrics.sharedCount >= 1) sharedNum++;
      sharedTotal += metrics.sharedCount ?? 0;

      if (person.face.payment?.paid) {
        paidTotal++;
        if (person.face.payment.type === 'donate')
          paidSum += person.face.payment.amount;
      }
    });

    const emailedPct = getPct(emailedNum / people.length) || 0;
    const emailedAverage = round(emailedTotal / people.length) || 0;

    const viewedPct = getPct(viewedNum / people.length) || 0;
    const viewedAverage = round(viewedTotal / people.length) || 0;

    const downloadedPct = getPct(downloadedNum / people.length) || 0;
    const downloadedAverage = round(downloadedTotal / people.length) || 0;

    const sharedPct = getPct(sharedNum / people.length) || 0;
    const sharedAverage = round(sharedTotal / people.length) || 0;

    const paidPct = getPct(paidTotal / people.length) || 0;

    setStats({
      emailedPct,
      emailedTotal,
      emailedAverage,
      viewedPct,
      viewedTotal,
      viewedAverage,
      downloadedPct,
      downloadedTotal,
      downloadedAverage,
      sharedPct,
      sharedTotal,
      sharedAverage,
      paidPct,
      paidTotal,
      paidSum,
    });
  }, [people]);

  const avg = () => {
    return window.innerWidth >= 640 ? 'Average' : 'Avg.';
  };

  const paidBlock = !event.payment?.paid
    ? {
        mainData: {
          icon: <PiCurrencyDollarSimpleBold />,
          text: 'Attendees paid',
          num: stats.paidPct,
        },
        otherData: [
          { text: 'Total payments made', num: stats.paidTotal },
          { isEmptyLine: true },
        ],
        show: showPages.includes('all') || showPages.includes('payment'),
      }
    : {
        mainData: {
          icon: <PiCurrencyDollarSimpleBold />,
          text: 'Attendees donated',
          num: stats.paidPct,
        },
        otherData: [
          { text: 'Total donations made', num: stats.paidTotal },
          { text: 'Sum of donations', num: `$${stats.paidSum}` },
        ],
        show: showPages.includes('all') || showPages.includes('payment'),
      };

  const data = [
    {
      mainData: {
        icon: <PiEnvelopeFill />,
        text: 'Attendees emailed',
        num: stats.emailedPct,
      },
      otherData: [
        { text: 'Total emails sent', num: stats.emailedTotal },
        { text: `${avg()} emails per attendee`, num: stats.emailedAverage },
      ],
      show: showPages.includes('all') || showPages.includes('emailed'),
    },
    {
      mainData: {
        icon: <PiPlayFill />,
        text: 'Videos played',
        num: stats.viewedPct,
      },
      otherData: [
        { text: 'Total video plays', num: stats.viewedTotal },
        { text: `${avg()} plays per video`, num: stats.viewedAverage },
      ],
      show: showPages.includes('all') || showPages.includes('played'),
    },
    {
      mainData: {
        icon: <PiDownloadSimpleFill />,
        text: 'Videos downloaded',
        num: stats.downloadedPct,
      },
      otherData: [
        { text: 'Total video downloads', num: stats.downloadedTotal },
        { text: `${avg()} downloads per video`, num: stats.downloadedAverage },
      ],
      show: showPages.includes('all') || showPages.includes('downloaded'),
    },
    {
      mainData: {
        icon: <PiShareFatFill />,
        text: 'Videos shared',
        num: stats.sharedPct,
      },
      otherData: [
        { text: 'Total video shares', num: stats.sharedTotal },
        { text: `${avg()} shares per video`, num: stats.sharedAverage },
      ],
      show: showPages.includes('all') || showPages.includes('shared'),
    },
    paidBlock,
  ];

  return (
    <div className="flex flex-wrap sm:flex-nowrap gap-4">
      {data.map(
        (metric) =>
          metric.show && (
            <div
              key={metric.mainData.text}
              className="w-[calc((100%_-_1rem)_/_2)] sm:w-[calc((var(--page-width)_-_(1rem_*_4))_/_5)]"
            >
              <div className="flex p-3 sm:p-4 gap-3 sm:gap-4 items-center rounded-t-md border border-black border-solid">
                <div className="flex items-center justify-center p-2 sm:p-3 text-lg sm:text-[1.25rem] text-white bg-black rounded-full">
                  {metric.mainData.icon}
                </div>

                <div className="grow basis-0 min-w-0">
                  <div className="text-md sm:text-lg font-bold">
                    {metric.mainData.num ?? 0}%
                  </div>

                  <div className="text-xs sm:text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                    {metric.mainData.text}
                  </div>
                </div>
              </div>

              <div className="flex flex-col p-3 sm:p-4 gap-1 text-white bg-black rounded-b-md">
                {metric.otherData.map((el) =>
                  el.isEmptyLine ? (
                    <div className="h-[calc(0.825rem_*_1.5)]" />
                  ) : (
                    <div
                      key={el.text}
                      className="flex items-center justify-between gap-1"
                    >
                      <div className="text-xs sm:text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                        {el.text}
                      </div>

                      <div className="text-sm font-bold">{el.num ?? 0}</div>
                    </div>
                  ),
                )}
              </div>
            </div>
          ),
      )}
    </div>
  );
};

export default StatsBlock;
