import React, { useState, useMemo } from 'react';

import { isEmpty } from '../../../Utils';

import ciPageEndedEvent from '../../../assets/ci-page-ended-event.png';
import eventEditError from '../../../assets/event-edit-error.png';
import eventUgcReq from '../../../assets/event-ugc-req.png';

import { FloatingLabel } from '../../../components/form';

import {
  EVENT_TYPE_VIDEOGRAPHER,
  EVENT_TYPE_BRAND,
  REC_WILDCARDS,
} from '../../../constants';

import InfoImg from '../components/infoImg';
import TabComponent from '../components/tabComponent';
import EventDatesSelector from './eventDatesSelector';
import EventOrientationSelector from './eventOrientationSelector';
import EventTypeSelector from './eventTypeSelector';
import EventWildcardsSelector from './eventWildcardsSelector';

const GeneralTab = (props) => {
  const { event } = props;

  const [eventName, setEventName] = useState(event.name ?? 'Untitled');
  const [type, setType] = useState(event.type ?? EVENT_TYPE_VIDEOGRAPHER);
  const [startDate, setStartDate] = useState(
    event.startDate?.slice(0, 10) ?? '',
  );
  const [endDate, setEndDate] = useState(event.endDate?.slice(0, 10) ?? '');
  const [orientation, setOrientation] = useState(event.videoMode ?? null);
  const [wildcards, setWildcards] = useState(event.wildcards ?? REC_WILDCARDS);

  const [errors, setErrors] = useState({});

  const onChange = (value, setValue) => {
    setValue(value);
    setErrors({});
  };

  const changeType = (value) => {
    setType(value);
    if ((value === EVENT_TYPE_BRAND) & isEmpty(wildcards))
      setWildcards(REC_WILDCARDS);

    setErrors({});
  };

  const videoReqInfoBody = (
    <React.Fragment>
      <div>
        Based on the selected orientation and wildcards attendees will be
        required to upload the corresponding UGC on the Check-In page.
      </div>

      <InfoImg src={eventUgcReq} alt="UGC Requirements Graphic" />

      <div className="font-bold">
        The uploaded template should also match these requisites.
      </div>
    </React.Fragment>
  );

  const isBrand = useMemo(() => type === EVENT_TYPE_BRAND, [type]);

  return (
    <TabComponent
      event={event}
      data={{ eventName, type, startDate, endDate, orientation, wildcards }}
      isBrand={isBrand}
      setErrors={setErrors}
      blocks={[
        {
          title: 'Project Name',
          description: 'Used to identify your project',
          content: (
            <FloatingLabel
              label="Project name"
              name="eventName"
              value={eventName}
              onChange={(e) => onChange(e.target.value, setEventName)}
              isInvalid={!!errors.eventName}
              errMsg={errors.eventName}
              required
            />
          ),
        },
        {
          // temporary removed
          isHide: true,
          title: 'Project Type',
          content: (
            <EventTypeSelector
              type={type}
              changeType={changeType}
              error={errors.type}
            />
          ),
        },
        {
          isHide: !isBrand,
          title: 'Start & End Date',
          description: 'Start and end dates determine the project availability',
          infoHeader: 'Setting up your video requisites',
          infoBody: (
            <React.Fragment>
              <div>You will see next on this page after the start date</div>

              <InfoImg src={eventEditError} alt="Event Edit Error Graphic" />

              <div>
                Attendees will see next on Check-In page after the end date
              </div>

              <InfoImg
                src={ciPageEndedEvent}
                alt="Check–In Page Ended Event Graphic"
              />
            </React.Fragment>
          ),
          content: (
            <EventDatesSelector
              startDate={startDate}
              endDate={endDate}
              onChangeStartDate={(e) => onChange(e.target.value, setStartDate)}
              onChangeEndDate={(e) => onChange(e.target.value, setEndDate)}
              errors={errors}
            />
          ),
        },
        {
          isHide: !isBrand,
          title: 'Orientation',
          description:
            'Template and user UGC will be converted to the selected orientation.',
          infoHeader: 'Setting up your video requisites',
          infoBody: videoReqInfoBody,
          content: (
            <EventOrientationSelector
              orientation={orientation}
              onChangeOrientation={(value) => onChange(value, setOrientation)}
              error={errors.orientation}
            />
          ),
        },
        {
          isHide: !isBrand,
          title: 'Wildcards',
          description:
            'This will determine what UGC attendees are asked to upload. Note that your template should also match with this selection.',
          infoHeader: 'Setting up your video requisites',
          infoBody: videoReqInfoBody,
          content: (
            <EventWildcardsSelector
              wildcards={wildcards}
              onChangeWildcards={(value) => onChange(value, setWildcards)}
              error={errors.wildcards}
            />
          ),
        },
      ]}
    />
  );
};

export default GeneralTab;
