import React from 'react';
import parse from 'html-react-parser';

import { Button, Modal } from '../../../components/general';

const UploadErrorModal = (props: {
  show: boolean;
  onHide: () => void;
  noteModalContent: string;
}) => {
  const { show, onHide, noteModalContent } = props;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Note</Modal.Title>
      </Modal.Header>

      <Modal.Body className="text-center text-lg">
        <div>{parse(noteModalContent)}</div>

        <Button className="w-full" onClick={onHide}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default UploadErrorModal;
