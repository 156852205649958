import React from 'react';
import classNames from 'classnames';
import { isEmpty, round, msecToSec } from '../../../../Utils';

import { Spinner, Tooltip } from '../../../../components/general';
import CreationPreview from './creationPreview';
import HlReelPreviewThumbnail from './hlReelPreviewThumbnail';

const HlReelPreview = (props) => {
  const { hlReel, person, selectedClip, handleChangeSelection } = props;

  // array with 5 empty blocks – shows while highlight reel is processing
  let previewArray = new Array(5).fill({});
  let isProcessing = true;

  if (hlReel?.shots) {
    previewArray = [];
    isProcessing = false;
    let i = 0;

    hlReel.shots.forEach((shot) => {
      const shotDuration = msecToSec(shot.segment.durationMilliseconds);

      // all shots between wildcards to combine in 1 block
      if (shot.type === 'shot') {
        if (!previewArray[i]) {
          // if block i doesn't exist – create one with first shot data
          const firstSelect = {
            type: 'selects',
            duration: shotDuration,
            startTime: msecToSec(shot.segment.startTimestampMilliseconds),
            endTime: msecToSec(shot.segment.endTimestampMilliseconds),
            frameIds: [shot.frameId],
          };
          previewArray.push(firstSelect);
        } else {
          // if exists – update some data of the block
          previewArray[i].duration += shotDuration;
          previewArray[i].endTime = msecToSec(
            shot.segment.endTimestampMilliseconds,
          );
          previewArray[i].frameIds.push(shot.frameId);
        }
      } else {
        let clipId = null;
        let frameId = null;
        let elementId = null;
        let name = null;
        let startTime = null;

        // if there's an opened person – save its clip id and frame id
        if (person) {
          clipId = person.selectedClips?.find(
            (clip) => clip.wildCardShotIndex === shot.shotIndex,
          )?.clipId;

          const foundClip =
            person.clips.find((clip) => clip.elementId === clipId) ?? {};

          frameId = foundClip.frameId;

          // next data is used for final creation video preview
          const fileNameParts = foundClip.name?.split('@') ?? [];
          elementId = foundClip.parentElementId;
          name = fileNameParts[0] + '@' + fileNameParts[1] + '.mp4';
          startTime = msecToSec(foundClip.segment?.startTimestampMilliseconds);
        }

        // if the shot is a wildcard – add it to array
        previewArray.push({
          type: 'wildcard',
          duration: shotDuration,
          shotIndex: shot.shotIndex,
          clipId,
          frameId,
          elementId,
          name,
          startTime,
        });
        // jump 2 steps – 1 for wildcard added above, 1 for next added select
        i = i + 2;
      }
    });
  }

  // on big screens 5 blocks fit within a page, on small – 2
  const isFitting =
    previewArray.length <= 2 ||
    (window.innerWidth >= 500 && previewArray.length <= 5);

  const containerClass = classNames({
    'group flex items-center p-4 gap-4 overflow-x-auto': true,
    'justify-center': isFitting,
  });

  const previewSchema = (
    <div
      className="relative w-full rounded-md border border-grey-200 border-solid"
      id={person ? 'hl-reel-preview-person' : null}
    >
      <div className={containerClass}>
        {previewArray.map((block, index) => {
          const thumbnailClass = classNames({
            'aspect-video rounded-md object-cover': true,
            'w-[32vw] min-w-[32vw] sm:w-[13vw] sm:min-w-[13vw]':
              block.type === 'wildcard',
            'w-[25vw] min-w-[25vw] sm:w-[10vw] sm:min-w-[10vw]':
              block.type !== 'wildcard',
          });

          // if block is selects – get middle frame id from array, or use block frame id
          const frameId =
            block.type === 'selects'
              ? block.frameIds[Math.round((block.frameIds.length - 1) / 2)]
              : block.frameId;

          let isMatching = false;
          let isFaded = false;
          let wildcardTooltipText = null;

          if (!isEmpty(selectedClip) && block.type === 'wildcard') {
            // don't allow to select wildcard if the duration doesn't match
            if (block.duration > selectedClip.duration) {
              isFaded = true;
              wildcardTooltipText =
                "You cannot use the selected clip for this wildcard as it's not long enough";
            }

            // else – set isMatching to true, and set corresponding tooltip text
            else {
              isMatching = true;
              wildcardTooltipText =
                block.clipId === selectedClip.clipId
                  ? 'The selected clip is already inserted here. Click to remove it.'
                  : 'Click to insert the selected clip here.';
            }
          }

          if (block.type === 'wildcard' && !person && !frameId)
            wildcardTooltipText =
              'The personalised attendee clip will be inserted here';

          const blockClass = classNames({
            'group relative transition-all duration-300 hover:z-10': true,
            'cursor-pointer animate-glowing hover:scale-110': isMatching,
            'cursor-default hover:scale-110 hover:shadow-salmon group-[:has(.group:hover)]:[&:not(:hover)]:blur-xs':
              !person,
          });

          const overlayClass = classNames({
            'absolute flex flex-col items-center justify-center p-2 text-white text-center pointer-events-none': true,
            'bg-black-overlay-200': !!frameId,
          });

          return (
            <Tooltip
              key={index}
              text={wildcardTooltipText}
              hide={!wildcardTooltipText}
              className={`${thumbnailClass} ${blockClass}`}
            >
              <div
                className="relative"
                onClick={() => isMatching && handleChangeSelection(block)}
              >
                {/* faded overlay */}
                {isFaded && (
                  <div
                    className={`${thumbnailClass} block absolute bg-grey-overlay-800 z-[1]`}
                  />
                )}

                <div className={`${thumbnailClass} ${overlayClass}`}>
                  {frameId && (
                    <React.Fragment>
                      <div className="capitalize font-bold">{block.type}</div>
                      <div className="text-xs">
                        {round(block.duration)} seconds
                      </div>
                    </React.Fragment>
                  )}

                  {isProcessing && <Spinner color="white" />}
                </div>

                <HlReelPreviewThumbnail
                  block={block}
                  frameId={frameId}
                  person={person}
                  thumbnailClass={thumbnailClass}
                />
              </div>
            </Tooltip>
          );
        })}
      </div>

      {!isFitting && (
        <div className="absolute top-0 left-0 w-full h-full bg-white-edges rounded-md pointer-events-none z-10" />
      )}
    </div>
  );

  return (
    <React.Fragment>
      {person && (
        <CreationPreview
          hlReel={hlReel}
          person={person}
          previewArray={previewArray}
        />
      )}

      {previewSchema}
    </React.Fragment>
  );
};

export default HlReelPreview;
