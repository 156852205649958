import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  goTo,
  isEmpty,
  getUrl,
  getShareLink,
  getCreationVideoUrl,
} from '../../../Utils';

import {
  trackFirstProjectComplete,
  trackProjectComplete,
  trackClickRemoveWatermark,
} from '../../../analytics';

import { Button, Alert } from '../../../components/general';
import { PageSpinner, BackBtn } from '../../../components/layout/content';
import { ConfirmModal, ErrorModal } from '../../../components/modals';

import config from '../../../config';

import {
  URL,
  DEMO_ID,
  FACE_EMAIL_STATUS,
  FIRST_EVENT_PROMO_CODE,
  UG_ID,
} from '../../../constants';

import { getCreationResults } from '../../../services/api/creations';
import { getTemplate } from '../../../services/api/emails';

import { useEventStore } from '../../../stores/event';
import { useUserStore } from '../../../stores/user';

import { getSelectionEmails } from '../../ProjectSettings/EmailHubTab/components/Utils';
import EmailPreviewModal from '../../ProjectSettings/EmailHubTab/components/emailPreviewModal';
import DoneBtn from '../components/doneBtn';
import MainShareBtn from './components/mainShareBtn';
import ShareFaceBlock from './components/shareFaceBlock';
import SharePageHeader from './components/sharePageHeader';
import StatsBlock from './components/statsBlock';

const { stripeLinks } = config;

const Share = () => {
  const { eventId } = useParams();

  const queryParameters = new URLSearchParams(window.location.search);
  const payment_success = queryParameters.get('payment_success') === 'true';

  const user = useUserStore((state) => state.user);
  const eventIdLocal = useEventStore((state) => state.eventId);
  const setEventId = useEventStore((state) => state.setEventId);
  const setDemoEvent = useEventStore((state) => state.setDemoEvent);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const userEvents = useEventStore((state) => state.userEvents);

  const [isLoading, setIsLoading] = useState(true);
  const [isIncreased, setIsIncreased] = useState(false);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  const [people, setPeople] = useState([]);
  const [peopleEmails, setPeopleEmails] = useState({});
  const [creationTemplate, setCreationTemplate] = useState({});

  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { creationTemplateId, creationTemplateTitle, creationTemplateText } =
    useMemo(() => {
      const creationTemplateId = currentEvent?.emailsData?.creationTemplateId;
      const creationTemplateText =
        currentEvent?.emailsData?.creationTemplateText;

      const creationTemplateTitle =
        getSelectionEmails('creation').find(
          (t) => t.templateId === creationTemplateId,
        )?.title ?? '';

      return {
        creationTemplateId,
        creationTemplateTitle,
        creationTemplateText,
      };
    }, [currentEvent?.emailsData]);

  // demo data
  const demoData = JSON.parse(localStorage.getItem('demoData')) ?? {};
  const isDemo = useMemo(() => eventId === DEMO_ID.FIFA, [eventId]);

  const hubId = isDemo ? demoData.event.eventId : eventId;

  // set event id on mount
  useEffect(() => {
    if (isDemo) setDemoEvent(eventId);
    else if (eventIdLocal !== eventId) setEventId(eventId);
  }, [eventId, eventIdLocal, isDemo, setDemoEvent, setEventId]);

  // fetch data on mount
  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      if (currentEvent.error) {
        setPageError(currentEvent.error);
        setIsLoading(false);
        return;
      }

      await fetchGenerationData();
      if (creationTemplateId) await fetchEmailTemplateData();

      if (payment_success) {
        setShowPaymentModal(true);
        window.history.pushState({}, '', getUrl(URL.VB_SHARE, eventId));
      }

      handleAnalytics();

      setIsLoading(false);
    };

    if (currentEvent) onMount();
  }, [currentEvent]);

  // when user opens share page for the first time – add GA events
  const handleAnalytics = () => {
    const projectsUpd =
      JSON.parse(localStorage.getItem('completedProjects')) ?? [];
    const isFirstCompletedProject =
      userEvents.filter((event) => event.stage === 'generation')?.length ===
        1 && localStorage.getItem('hasFirstCompletedProject') !== 'true';

    // add project completion analytics
    // check if eventId is not in localStorage completedProjects – to avoid duplicate events
    if (!projectsUpd.includes(eventId)) {
      trackProjectComplete(currentEvent);

      projectsUpd.push(eventId);
      localStorage.setItem('completedProjects', JSON.stringify(projectsUpd));
    }

    // if it's first completed project – add corresponding analytics
    if (isFirstCompletedProject) {
      trackFirstProjectComplete(currentEvent);

      localStorage.setItem('hasFirstCompletedProject', 'true');
    }
  };

  useEffect(() => {
    const isShow = sessionStorage.getItem('showSampleUG') === 'true';
    if (isShow) {
      window.userGuiding.previewGuide(UG_ID.SAMPLE_P3);
      sessionStorage.removeItem('showSampleUG');
    }
  }, []);

  useEffect(() => {
    people.forEach((person) => {
      const { face, metrics } = person;
      const id = face.elementId;

      if (!peopleEmails[id])
        peopleEmails[id] = {
          email: face.email || '',
          videoLink: getShareLink(hubId, id),
          metricId: metrics.metricId,
          status: !!metrics.emailedCount
            ? FACE_EMAIL_STATUS.RESEND
            : FACE_EMAIL_STATUS.NOT_SENT,
          err: null,
        };
    });

    setPeopleEmails({ ...peopleEmails });
  }, [people]);

  const fetchGenerationData = async () => {
    try {
      let generatedPeople = [];

      // demo event
      if (isDemo) {
        demoData.people.forEach((person) => {
          if (person.isSelected && person.isGenerated) {
            const foundCreation = demoData.creations[
              person.face.elementId
            ].find((creation) => creation.isSelected);

            // imitate data returned by getCreationResults
            generatedPeople.push({
              face: { ...person.face, img: person.files[0].url },
              creations: [foundCreation],
              metrics: {},
              finalVideoUri: foundCreation.uri,
            });
          }
        });
      }

      // usual event
      else generatedPeople = await getCreationResults(eventId);

      setPeople(generatedPeople);
    } catch (err) {
      console.error(`Error fetching the generated videos: ${err}`);
      setError('Error fetching the generated videos');
    }
  };

  const fetchEmailTemplateData = async () => {
    const template = await getTemplate(creationTemplateId);
    setCreationTemplate(template);
  };

  const sortedPeople = useMemo(() => {
    const peopleUpd = [...people]
      .map((person) => {
        const videoUrl = getCreationVideoUrl(currentEvent, person);
        return { ...person, videoUrl };
      })
      .sort((one, other) => {
        if (one.face.email && !other.face.email) return -1;
        if (!one.face.email && other.face.email) return 1;

        return (
          new Date(one.face.created).getTime() -
          new Date(other.face.created).getTime()
        );
      });

    return peopleUpd;
  }, [currentEvent, people]);

  const afterEmailSend = () => {
    fetchGenerationData();
  };

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={`Share – ${currentEvent?.name}`}
      isPageContainer
    >
      <BackBtn label="Video Builder" />

      <div className="flex flex-col gap-6">
        <SharePageHeader
          eventId={eventId}
          event={currentEvent}
          people={sortedPeople}
          isIncreased={isIncreased}
          setIsIncreased={setIsIncreased}
        />

        {isEmpty(people) ? (
          <React.Fragment>
            <Alert
              alertData={{
                variant: 'warning',
                text: (
                  <>
                    Hmm, seems like you don't have any videos generated yet.
                    Come back when you complete all{' '}
                    <Link to={`/${eventId}${URL.VIDEO_BUILDER}`}>
                      Video Builder
                    </Link>{' '}
                    steps.
                  </>
                ),
              }}
            />

            <DoneBtn isBack />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!currentEvent?.payment?.paid && (
              <div className="animated-box-primary flex flex-col sm:flex-row items-center p-[2px] rounded-md animate-running-2">
                <div className="w-full flex flex-row items-center justify-between p-3 sm:p-4 gap-2 sm:gap-4 text-left text-black bg-white rounded-t sm:rounded-l sm:rounded-tr-none">
                  <div>
                    Remove watermarks now for only{' '}
                    <span className="line-through">$249</span>{' '}
                    <span className="font-bold text-[1.1rem] bg-gradient-primary-right bg-clip-text text-transparent">
                      $39
                    </span>
                  </div>
                </div>

                <button
                  className="w-full sm:w-32 p-3 sm:p-4 text-white whitespace-nowrap transition-all duration-200 sm:hover:w-72"
                  onClick={() => {
                    trackClickRemoveWatermark();
                    goTo(
                      `${stripeLinks.removeWatermarkProject}?client_reference_id=${eventId}&prefilled_email=${user.email}&prefilled_promo_code=${FIRST_EVENT_PROMO_CODE}`,
                    );
                  }}
                >
                  Pay now
                </button>
              </div>
            )}

            {!isDemo && (
              <StatsBlock
                event={currentEvent}
                people={people}
                showPages={['all']}
              />
            )}

            <div className="w-full flex flex-col items-center gap-2">
              <MainShareBtn
                event={currentEvent}
                people={sortedPeople}
                isDemo={isDemo}
                peopleEmails={peopleEmails}
                setPeopleEmails={setPeopleEmails}
                creationTemplate={creationTemplate}
                afterEmailSend={afterEmailSend}
              />

              {!isEmpty(creationTemplate) && (
                <div className="text-sm text-black/[0.5]">
                  <>
                    The attendees will receive{' '}
                    <Button
                      onClick={() => setShowEmailPreviewModal(true)}
                      variant="link"
                    >
                      {creationTemplateTitle}
                    </Button>{' '}
                    email. Change template{' '}
                    <Link
                      to={`${getUrl(URL.PROJECT_SETTINGS, eventId, 'email-hub/creation')}?redirectUrl=${getUrl(URL.VB_SHARE, eventId)}`}
                    >
                      here
                    </Link>
                  </>
                </div>
              )}
            </div>

            <div className="w-full flex flex-wrap gap-8">
              {sortedPeople.map((person) => (
                <ShareFaceBlock
                  key={person.face.elementId}
                  event={currentEvent}
                  person={person}
                  peopleEmails={peopleEmails}
                  setPeopleEmails={setPeopleEmails}
                  afterEmailSend={afterEmailSend}
                  isDemo={isDemo}
                  isIncreased={isIncreased}
                />
              ))}
            </div>
          </React.Fragment>
        )}
      </div>

      {/* email preview modal */}
      <EmailPreviewModal
        show={showEmailPreviewModal}
        onHide={() => setShowEmailPreviewModal(false)}
        template={creationTemplate}
        event={currentEvent}
        user={user}
        customText={creationTemplateText}
      />

      {/* payment modal */}
      <ConfirmModal
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        heading="Payment successful"
        subheading="Thanks for upgrading your project. We've now generated watermark-free highlight reels for everyone to share and enjoy."
        btn={
          <Button className="w-full" onClick={() => setShowPaymentModal(false)}>
            See Videos
          </Button>
        }
      />

      <ErrorModal
        show={!!error}
        heading="Something went wrong"
        subheading={error}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default Share;
