import React, { useState } from 'react';
import {
  DotsThree,
  Presentation,
  Pencil,
  ChartBar,
  Users,
  UserCirclePlus,
  QrCode,
} from '@phosphor-icons/react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import {
  formatDate,
  canEditEvent,
  getUrl,
  getCheckInLink,
} from '../../../Utils';
import {
  trackEditProject,
  trackClickProjectAnalytics,
} from '../../../analytics';

import { QRCode, downloadQRCode } from '../../../components/custom';
import { Dropdown } from '../../../components/general';
import { URL } from '../../../constants';

const EventBlockOverlay = (props) => {
  const { event, onClickEvent, addClass } = props;

  const [isFocused, setIsFocused] = useState(false);

  const eventId = event.eventId;

  const navigate = useNavigate();

  const checkInPageLink = getCheckInLink(event.eventId);

  const items = [
    {
      icon: <Presentation />,
      label: 'Video Builder',
      onClick: onClickEvent,
    },
    {
      icon: <Pencil />,
      label: 'Edit',
      onClick: () => {
        navigate(getUrl(URL.PROJECT_SETTINGS, eventId));
        trackEditProject('Home');
      },
      show: canEditEvent(event),
    },
    {
      icon: <ChartBar />,
      label: 'Analytics',
      onClick: () => {
        navigate(getUrl(URL.PROJECT_ANALYTICS, eventId));
        trackClickProjectAnalytics('Home');
      },
    },
    {
      icon: <Users />,
      label: 'Hub Page',
      onClick: () => window.open(getUrl(URL.SHARE, eventId), '_blank'),
    },
    {
      icon: <UserCirclePlus />,
      label: 'Faces Check-In',
      onClick: () => window.open(checkInPageLink, '_blank'),
    },
    {
      icon: <QrCode />,
      label: 'Download Check-In QR',
      onClick: () => downloadQRCode(event, 'Home'),
    },
  ];

  return (
    <div
      className={classNames(
        {
          'absolute top-0 left-0 pointer-events-none': true,
          'sm:hidden hover:block group-hover:block focus:block focus-within:block':
            !isFocused,
          block: isFocused,
        },
        addClass,
      )}
    >
      <Dropdown
        btnData={{
          icon: <DotsThree weight="bold" className="sm:text-xl leading-none" />,
          variant: 'custom',
          className:
            'absolute top-2 right-2 w-8 h-8 p-0 bg-true-white hover:bg-primary-900 hover:text-white pointer-events-auto',
        }}
        menuItems={items.map((item) => {
          if (item.show !== false)
            return {
              label: item.label,
              icon: item.icon,
              onClick: item.onClick,
              closeOnClick: true,
              isSmall: true,
            };

          return null;
        })}
        onOpen={() => setIsFocused(true)}
        onClose={() => setIsFocused(false)}
      />

      {event.startDate && event.endDate && (
        <div className="hidden sm:block absolute bottom-0 left-0 px-2 py-1.5 bg-black-overlay-600 text-xs text-white rounded-bl-md rounded-tr-md">
          {formatDate(event.startDate)} – {formatDate(event.endDate)}
        </div>
      )}

      <QRCode event={event} link={checkInPageLink} className="hidden" />
    </div>
  );
};

export default EventBlockOverlay;
