import React from 'react';
import { QRCode as QR } from 'react-qrcode-logo';

import { isEmpty } from '../../Utils';
import { trackCheckInQRDownload } from '../../analytics';

import LogoImage from '../../assets/cc-logo-spinner.png';

export const QRCode = (props) => {
  const { event, link, className } = props;

  if (isEmpty(event)) return null;

  return (
    <div className={className}>
      <QR
        id={`check-in-qr-${event.eventId}`}
        value={link}
        size={140}
        quietZone={0}
        eyeRadius={5}
        eyeColor="#f05a5b"
        logoImage={LogoImage}
        logoWidth={40}
        logoPaddingStyle="circle"
        removeQrCodeBehindLogo={true}
      />
    </div>
  );
};

export const downloadQRCode = (event, source) => {
  const canvas = document.getElementById(`check-in-qr-${event.eventId}`);

  if (canvas) {
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `CrowdClip QR Code${!!event ? ` - ${event.name}` : ''}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    trackCheckInQRDownload(source);
  }
};
