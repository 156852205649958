import React from 'react';
import classNames from 'classnames';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Tooltip } from 'flowbite-react';

import { Placement, Trigger } from '../../types';

type Props = {
  children: React.ReactNode;
  text: string;
  placement?: Placement;
  trigger?: Trigger;
  style?: 'light' | 'dark' | 'auto';
  className?: string;
  hide?: boolean;
};

const CustomTooltip = (props: Props) => {
  const {
    children,
    text,
    placement = 'top',
    trigger = 'hover',
    style = 'light',
    className,
    hide = false,
  } = props;

  const lightArrowClass = classNames({
    'bg-white border-grey-200 border-solid': true,
    'border-b border-r': placement === 'top',
    'border-t border-l': placement === 'bottom',
    'border-t border-r': placement === 'left',
    'border-b border-l': placement === 'right',
  });

  const customTheme: CustomFlowbiteTheme['tooltip'] = {
    target: classNames('w-fit', className),
    arrow: { style: { light: lightArrowClass }, placement: '-4.5px' },
    base: 'absolute z-[200] inline-block rounded-md px-3 py-2 text-sm font-medium shadow-sm',
  };

  if (hide) return <div className={className}>{children}</div>;

  return (
    <Tooltip
      theme={customTheme}
      content={text}
      placement={placement}
      trigger={trigger}
      style={style}
      animation="duration-300"
      className="w-fit max-w-[90vw] sm:max-w-[400px] font-medium border border-grey-200 border-solid shadow"
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
