import React from 'react';
import {
  PiMagicWand,
  PiArrowSquareOut,
  PiDownloadSimple,
  PiTrash,
} from 'react-icons/pi';

import previewApe from '../../assets/report-preview-ape.png';
import previewHarvard from '../../assets/report-preview-harvard.png';
import previewZoom from '../../assets/report-preview-zoom.png';

import { Button } from '../../components/general';
import { PremiumIcon } from '../../components/icons';
import { PageContainer, ContentHeader } from '../../components/layout/content';

const Analytics = () => {
  const reports = [
    {
      preview: previewZoom,
      name: 'SSFC Campaign Infographics',
      link: 'https://drive.google.com/file/d/1tU3voC1qEdRP6JlEpa7xkK5Oulkwq84v/view?usp=sharing',
      isEnabled: true,
    },
    {
      preview: previewHarvard,
      name: 'SFFC X Zoom Event 2',
      link: '',
      isEnabled: false,
    },
    {
      preview: previewApe,
      name: 'SFFC X Zoom Event 3',
      link: '',
      isEnabled: false,
    },
  ];

  return (
    <PageContainer title="Analytics" addClass="gap-6">
      <ContentHeader
        title="Analytics"
        subtitle="Recent Campaign Infographics"
      />

      <table>
        <thead>
          <tr className="font-bold text-left">
            <th className="py-4">Preview</th>
            <th>Name</th>
            <th></th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          {reports.map((report) => {
            return (
              <tr className="border-t border-grey-900 border-solid">
                <td className="w-72 py-4">
                  <img
                    className="w-52 rounded-md"
                    src={report.preview}
                    alt="Report Preview"
                  />
                </td>

                <td>{report.name}</td>

                <td>
                  {report.isEnabled ? (
                    <Button
                      variant="blue-green"
                      className="w-24 gap-2"
                      isThin
                      onClick={() => window.open(report.link, '_blank')}
                    >
                      <PiMagicWand size="1.1rem" />
                      <div>Free</div>
                    </Button>
                  ) : (
                    <Button className="w-24 gap-2" isThin>
                      <PremiumIcon />
                      <div>Buy</div>
                    </Button>
                  )}
                </td>

                <td className="w-[calc((2.5rem_*_3)_+_(0.5rem_*_2))]">
                  <fieldset className="flex gap-2" disabled={!report.isEnabled}>
                    <Button variant="square-icon">
                      <PiArrowSquareOut />
                    </Button>
                    <Button variant="square-icon">
                      <PiDownloadSimple />
                    </Button>
                    <Button variant="square-icon">
                      <PiTrash />
                    </Button>
                  </fieldset>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </PageContainer>
  );
};

export default Analytics;
