import React from 'react';
import classNames from 'classnames';

import { Tooltip } from './';

const Badge = (props: {
  variant?: string;
  size?: string;
  text: string;
  tooltipText?: string;
}) => {
  const { variant, size, text, tooltipText } = props;

  const badgeClass = classNames({
    'w-min h-min rounded-full': true,
    'bg-grey-100':
      !variant || variant === 'purple-gradient' || variant === 'grey',
    'bg-black': variant === 'black',
    'px-3 py-2': !size || size === 'md',
    'px-2 py-1.5': size === 'sm',
    'cursor-pointer': !!tooltipText,
  });

  const textClass = classNames({
    'font-bold uppercase leading-none whitespace-nowrap': true,
    'bg-gradient-purple-light bg-clip-text text-transparent':
      !variant || variant === 'purple-gradient',
    'text-black': variant === 'grey',
    'text-white': variant === 'black',
    'text-sm': !size || size === 'md',
    'text-[0.6rem]': size === 'sm',
  });

  const badge = (
    <div className={badgeClass}>
      <div className={textClass}>{text}</div>
    </div>
  );

  if (tooltipText) return <Tooltip text={tooltipText}>{badge}</Tooltip>;

  return badge;
};

export default Badge;
