import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { isEmpty, getEventImg, getShareLink } from '../../Utils';

import { trackHubClickPerson } from '../../analytics';

import { Logo } from '../../components/general';
import { PageSpinner } from '../../components/layout/content';
import { ErrorModal } from '../../components/modals';

import { getCreationResults } from '../../services/api/creations';
import { getEventPublic } from '../../services/api/events';

import withRouter from '../../withRouter';
import ShareFooter from './components/shareFooter';

const ShareHub = (props) => {
  const { eventId } = props.params;

  const [event, setEvent] = useState({});
  const [generatedFaces, setGeneratedFaces] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pageError, setPageError] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const onMount = async () => {
      setIsLoading(true);

      await fetchEventData();

      setIsLoading(false);
    };

    onMount();
  }, [eventId]);

  const fetchEventData = async () => {
    try {
      const event = await getEventPublic(eventId);

      if (!event) {
        setPageError(404);
        return;
      }

      const generatedFaces = await getCreationResults(eventId, true);

      if (isEmpty(generatedFaces))
        setError({
          heading: 'No videos generated',
          subheading:
            'It looks like no videos have been generated yet for this event. Please check back later!',
        });

      setEvent(event);
      setGeneratedFaces(generatedFaces);
    } catch (err) {
      console.error(err);
      setError({ subheading: 'Error fetching the generated videos' });
    }
  };

  const logo = event.logo && getEventImg('logo', event.logo);

  return (
    <PageSpinner
      isLoading={isLoading}
      pageError={pageError}
      title={event.name}
      description={`${event.name} hub page with personalised highlight reels`}
      noTips
      isFullPage
      isPageContainer
      className="mt-[5vw] sm:mt-7 py-0"
    >
      <div className="flex flex-col px-5 sm:px-20 py-7 gap-4 sm:gap-8 bg-grey-100 text-center rounded-md shadow-grey-1">
        <Logo withTagLine />

        <h2>
          <b>{event.name ?? 'Event'}</b> has created {generatedFaces.length}{' '}
          personalised videos!
        </h2>

        <hr className="my-4" />

        <div className="flex flex-col items-center gap-7">
          {logo && (
            <img
              src={logo}
              alt="Logo"
              className="w-[50vw] sm:w-[13vw] h-[50vw] sm:h-[13vw] object-cover rounded-md"
            />
          )}

          <h4>Click faces to find videos for:</h4>

          <div className="flex flex-wrap justify-center gap-6">
            {generatedFaces.map((faceObj) => {
              const face = faceObj.face;

              return (
                <Link
                  to={getShareLink(eventId, face.elementId)}
                  onClick={() => trackHubClickPerson(face.name)}
                  target="_blank"
                  className="w-[calc((100%_-_(1.5rem_*_2))_/_3)] sm:w-[calc((100%_-_(1.5rem_*_5))_/_6)] text-center no-underline transition-all duration-500 hover:scale-110 hover:opacity-100"
                >
                  <img
                    src={face.img}
                    className="w-full aspect-square rounded-full object-cover"
                    alt={`${face.name} Icon`}
                  />

                  <div className="w-full mt-2 text-black whitespace-nowrap overflow-hidden text-ellipsis">
                    {face.name}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <ShareFooter type="share_hub" />

      <ErrorModal
        show={!!error}
        heading={error?.heading ?? 'Something went wrong'}
        subheading={error?.subheading}
        isTryAgainBtn
      />
    </PageSpinner>
  );
};

export default withRouter(ShareHub);
