import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';

const FormText = (props: {
  text: string;
  color?: string;
  className?: string;
}) => {
  const { text, color, className } = props;

  return (
    <div
      className={classNames('mt-1 text-sm', className, {
        'text-white': color === 'white',
        'text-black/[0.5]': !color || color === 'grey',
      })}
    >
      {parse(text)}
    </div>
  );
};

export default FormText;
