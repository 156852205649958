import React, { ReactNode, FormEventHandler } from 'react';
import classNames from 'classnames';

type Props = {
  children: ReactNode;
  onSubmit?: FormEventHandler<HTMLFormElement>;
  disabled?: boolean;
  formClassName?: string;
  className?: string;
};

const Form = (props: Props) => {
  const { children, onSubmit, disabled, formClassName, className } = props;

  return (
    <form noValidate onSubmit={onSubmit} className={formClassName ?? ''}>
      <fieldset
        className={classNames(
          {
            flex: true,
            'flex-col': !className?.includes('flex-'),
            'gap-4': !className?.includes('gap-'),
          },
          className,
        )}
        disabled={disabled}
      >
        {children}
      </fieldset>
    </form>
  );
};

export default Form;
