import { isPlural, round } from '../../Utils';

export const getWildcardText = (shots, shotNameText, addText) => {
  shots = shots.map((shot) => round(shot.duration || shot));

  if (shots.length === 0) return '';

  if (shots.length === 1)
    return (
      `<b>1 ${shotNameText}</b> that is ` +
      `<b>${shots[0]} second${isPlural(shots[0])}</b> long`
    );

  let shotsLengthText = '';

  if (shots.every((v) => v === shots[0])) {
    shotsLengthText = `${shots[0]} second${isPlural(shots[0])} long</b> each`;
  } else if (shots.length === 2) {
    shotsLengthText = `${shots.join(' & ')} seconds</b> long`;
  } else {
    const shotsTemp = [...shots];
    const last = shotsTemp.pop();
    shotsLengthText = `${shotsTemp.join(', ')} & ${last} seconds</b> long`;
  }

  return (
    `<b>${shots.length} ${shotNameText}s</b> that are ` +
    `<b>${addText ? addText + ' ' : ''}${shotsLengthText}`
  );
};

export const getWildcardTextMaximum = (shots, shotNameText, addText) => {
  const shotDurations = shots.map((shot) => round(shot.duration || shot));
  const maxDuration = Math.max(...shotDurations);

  return shotDurations.length > 1
    ? `<b>${
        shots.length
      } ${shotNameText}s</b> that are ${addText} <b>${maxDuration} to ${
        maxDuration * 2
      } seconds</b> long`
    : `<b>${
        shots.length
      } ${shotNameText}</b> that is ${addText} <b>${maxDuration} to ${
        maxDuration * 2
      } seconds</b> long`;
};
