import React, { useState } from 'react';
import auth0 from 'auth0-js';

import { useNavigate } from 'react-router-dom';
import { object } from 'yup';

import { isEmpty } from '../../../Utils';

import { Form, FloatingLabel, FormError } from '../../../components/form';
import { Button, Checkbox } from '../../../components/general';

import config from '../../../config';
import { useAttendeeStore } from '../../../stores/attendee';

import {
  testEmail,
  testConfirmEmail,
  testTsAndCs,
} from '../../../validationUtils';

import { getTsAndCsText } from './TsAndCsText';

const { featureToggles } = config;

const authenticate = async (webAuth, event, email) => {
  return new Promise((resolve, reject) => {
    webAuth.authorize(
      {
        state: event.eventId,
        connection: 'email',
        nonce: 'nonce',
        login_hint: email,
      },
      (err, authResult) => {
        if (err) {
          console.error(err);
          return reject(err);
        }

        return resolve(authResult);
      },
    );
  });
};

const EmailStage = (props) => {
  const { event, isPreview } = props;

  const setAttendeeEmail = useAttendeeStore((state) => state.setAttendeeEmail);

  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [isTsAndCs, setIsTsAndCs] = useState(true);

  const [isShowConfirm, setIsShowConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const onChange = (e, setValue) => {
    const { type, name, value, checked } = e.target;

    setValue(type === 'checkbox' ? checked : value);
    setErrors({});

    if (name === 'email') setAttendeeEmail(value);
  };

  const validateForm = async (formData) => {
    const errors = {};

    await object()
      .shape({
        email: testEmail(),
        confirmEmail: isShowConfirm && testConfirmEmail(),
        isTsAndCs: testTsAndCs(),
      })
      .validate(formData, { abortEarly: false })
      .catch((err) => {
        err.inner.forEach((error) => {
          errors[error.path] = error.errors[0];
        });
      });

    return errors;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isPreview) return;

    const errors = await validateForm({ email, confirmEmail, isTsAndCs });

    // if errors found – set errors and stop here
    if (!isEmpty(errors)) {
      setErrors(errors);
      return;
    }

    // if it's not confirm stage – change to it and stop here
    if (!isShowConfirm) {
      setIsShowConfirm(true);
      return;
    }

    // check in attendee
    const redirectUri = `${
      featureToggles.auth0 ? window.location.origin : ''
    }/complete-check-in?eventId=${event.eventId}`;

    if (featureToggles.auth0) {
      setIsProcessing(true);
      const webAuth = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        responseType: 'token id_token',
        scope: 'openid profile email',
        redirectUri: redirectUri,
      });
      return authenticate(webAuth, event, email);
    } else {
      navigate(redirectUri);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <div className="text-center font-bold">
        Tell us where to send your personalised video
      </div>

      <FloatingLabel
        label="Enter your email"
        name="email"
        value={email}
        onChange={(e) => onChange(e, setEmail)}
        isInvalid={!!errors?.email}
        errMsg={errors?.email}
        required
      />

      {isShowConfirm ? (
        <FloatingLabel
          label="Confirm email"
          name="confirmEmail"
          value={confirmEmail}
          onChange={(e) => onChange(e, setConfirmEmail)}
          isInvalid={!!errors?.confirmEmail}
          errMsg={errors?.confirmEmail}
          required
        />
      ) : (
        <div>
          <Checkbox
            id="check-in-t&c-checkbox"
            label={getTsAndCsText(event.TsAndCsLink, event.organiserName)}
            name="isTsAndCs"
            checked={isTsAndCs}
            onChange={(e) => onChange(e, setIsTsAndCs)}
            isInvalid={!!errors?.isTsAndCs}
          />

          <FormError errMsg={errors?.isTsAndCs} />
        </div>
      )}

      <Button
        className="w-full"
        disabled={!isEmpty(errors)}
        isSubmitBtn
        isProcessing={isProcessing}
      >
        Check-in
      </Button>
    </Form>
  );
};

export default EmailStage;
