import React, { useEffect, useState } from 'react';
import axios from 'axios';
import confetti from 'canvas-confetti';
import { PiCheckCircle, PiInfo } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isEmpty } from '../../../../Utils';

import {
  trackCreateProject,
  trackDemoUpsellClickNextEventForm,
  trackDemoUpsellSubmitNextEventForm,
  trackDemoUpsellClickAskCC,
} from '../../../../analytics';

import { Form, FloatingLabel } from '../../../../components/form';
import { Button, Modal, Tooltip } from '../../../../components/general';

import { URL, FORMSPARK_ID } from '../../../../constants';

import { validateForm } from './nextEventFormValidations';

const DemoUpsellModal = (props) => {
  const { show, onHide } = props;

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    numOfPeople: '',
    msg: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (show)
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
        zIndex: 1060,
      });
  }, [show]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const errors = await validateForm(formData);

    if (!isEmpty(errors)) {
      setErrors(errors);
      toast.error('Some data is missing or invalid');

      return;
    }

    setIsSubmitting(true);

    try {
      await axios.post(
        `https://submit-form.com/${FORMSPARK_ID.NEXT_EVENT}`,
        formData,
      );

      toast.success("Thanks, we'll get back to you soon.");
      setShowForm(false);
      trackDemoUpsellSubmitNextEventForm();
    } catch (error) {
      toast.error('There was a problem submitting the form. Please try again.');
    }

    setIsSubmitting(false);
  };

  const btnClass =
    'w-4/5 h-[var(--form-el-height)] flex items-center justify-center px-3 py-1.5 bg-primary-900 text-md sm:text-lg rounded-md no-underline hover:opacity-80 disabled:opacity-65';

  return (
    <React.Fragment>
      {/* main modal */}
      <Modal show={show && !showForm} onHide={onHide}>
        <Modal.Body centered>
          <PiCheckCircle className="w-24 h-24 text-[var(--success-color)]" />

          <h4>You did it!</h4>

          <div className="text-lg">
            You’ve successfully generated a personalised video which is now on
            its way to your inbox.{' '}
            <Tooltip
              text={
                <div>
                  This is an <b>example video</b> and it doesn't reflect the
                  choices you've made before.
                </div>
              }
              className="inline-block"
            >
              <span>
                <PiInfo className="cursor-pointer hover:fill-[url('#icon-primary-gradient')]" />
              </span>
            </Tooltip>
          </div>

          <div className="text-lg">Where to from here?</div>

          <div className="w-full flex flex-col items-center justify-center gap-4">
            <Link
              className={`${btnClass} text-white`}
              to={URL.PROJECT_SETTINGS}
              onClick={() => trackCreateProject('Demo upsell modal')}
            >
              Create Project
            </Link>

            <button
              className={`${btnClass} text-white`}
              onClick={() => {
                setShowForm(true);
                trackDemoUpsellClickNextEventForm();
              }}
            >
              Tell Us About Your Next Event
            </button>

            <button
              className={`${btnClass} bg-transparent text-black border border-primary-900 border-solid gap-2 cursor-default hover:opacity-100`}
              onClick={trackDemoUpsellClickAskCC}
            >
              Ask CrowdClip&reg;!
              <span className="text-xs">Coming Soon</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* next event modal */}
      <Modal show={showForm} onHide={() => setShowForm(false)} size="lg">
        <Modal.Body centered>
          <h4>
            Tell us a little bit more about your upcoming event. One of our
            superstar team members will get in touch with you.
          </h4>

          <Form onSubmit={onSubmit} formClassName="w-full text-left">
            <div className="flex flex-col sm:flex-row gap-4">
              <FloatingLabel
                label="Name"
                name="name"
                value={formData.name}
                onChange={onChange}
                isInvalid={!!errors.name}
                errMsg={errors.name}
                required
                className="w-full sm:w-1/2"
              />

              <FloatingLabel
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={onChange}
                isInvalid={!!errors.email}
                errMsg={errors.email}
                required
                className="w-full sm:w-1/2"
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-4">
              <FloatingLabel
                label="Event Date"
                type="date"
                name="date"
                value={formData.date}
                onChange={onChange}
                isInvalid={!!errors.date}
                errMsg={errors.date}
                required
                className="w-full sm:w-1/2"
              />

              <FloatingLabel
                label="Number of people"
                name="numOfPeople"
                value={formData.numOfPeople}
                onChange={onChange}
                isInvalid={!!errors.numOfPeople}
                errMsg={errors.numOfPeople}
                required
                className="w-full sm:w-1/2"
              />
            </div>

            <FloatingLabel
              label="Message"
              as="textarea"
              name="msg"
              value={formData.msg}
              onChange={onChange}
              isInvalid={!!errors.msg}
              errMsg={errors.msg}
              required
            />

            <Button className="w-full" isSubmitBtn isProcessing={isSubmitting}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DemoUpsellModal;
