import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { CaretDown, CaretUp, CheckCircle, X } from '@phosphor-icons/react';
import parse from 'html-react-parser';

const UploadInfo = (props) => {
  const { isCollapse, type, infoPoints, collapseText, heading, note } = props;
  const infoOpenState = JSON.parse(localStorage.getItem('infoOpenState')) || {};

  // if info collapse was opened or wasn't interacted with – set true
  const [infoOpen, setInfoOpen] = useState(
    !type && [true, null, undefined].includes(infoOpenState[type]),
  );

  const onInfoCollapse = () => {
    setInfoOpen(!infoOpen);

    if (type)
      localStorage.setItem(
        'infoOpenState',
        JSON.stringify({ ...infoOpenState, [type]: !infoOpen }),
      );
  };

  if (isCollapse)
    return (
      <div className="flex flex-col gap-3">
        <Accordion
          expanded={infoOpen}
          onChange={onInfoCollapse}
          className="shadow-none"
          disableGutters
          sx={{
            '.MuiAccordionSummary-root': { minHeight: 0 },
            ':before': { display: 'none' },
          }}
        >
          <AccordionSummary
            className="p-0"
            sx={{ '.MuiAccordionSummary-content': { margin: 0 } }}
          >
            {collapseText && (
              <h5 className="inline-block">{parse(collapseText)}</h5>
            )}

            <div
              className="inline-block ml-2"
              onClick={onInfoCollapse}
              aria-controls="collapse-wrapper"
              aria-expanded={infoOpen}
            >
              <div className="w-6 h-6 flex items-center justify-center bg-true-black text-white rounded-full cursor-pointer hover:bg-gradient-primary-bottom">
                {infoOpen ? <CaretUp /> : <CaretDown />}
              </div>
            </div>
          </AccordionSummary>

          <AccordionDetails className="flex flex-col mt-3 p-4 gap-4 rounded-md border border-primary-900 border-solid">
            <div className="flex justify-between gap-2">
              <div>{parse(heading)}</div>
              <div
                className="group w-6 h-6 flex items-center justify-center rounded-full border-2 border-primary-900 border-solid cursor-pointer hover:bg-gradient-primary-bottom"
                onClick={onInfoCollapse}
              >
                <X
                  weight="bold"
                  className="fill-[url('#icon-primary-gradient')] group-hover:fill-white"
                />
              </div>
            </div>

            {infoPoints.map((point, index) => {
              return (
                <div className="flex items-center gap-2" key={index}>
                  <CheckCircle size="1.5rem" />
                  <div>{parse(point)}</div>
                </div>
              );
            })}

            {note && <div className="fst-italic">{note}</div>}
          </AccordionDetails>
        </Accordion>
      </div>
    );

  return (
    <div className="flex flex-col p-4 gap-4 rounded-md border border-primary-900 border-solid">
      <div>{parse(heading)}</div>

      {infoPoints.map((point) => {
        return (
          <div className="flex items-center gap-2">
            <CheckCircle size="1.5rem" />
            <div>{point}</div>
          </div>
        );
      })}

      {note && <div>{note}</div>}
    </div>
  );
};

export default UploadInfo;
