import React, { useState, useEffect, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import TurndownService from 'turndown';

import { isEmpty } from '../../../../Utils';

import {
  trackEmailHubUpdateCustomTextSuccess,
  trackEmailHubUpdateCustomTextError,
} from '../../../../analytics';

import { Form, FloatingLabel, FormText } from '../../../../components/form';
import { Button, Modal } from '../../../../components/general';

import { updateOneEvent } from '../../../../services/api/mongodb';

import { useEventStore } from '../../../../stores/event';

const turndownService = new TurndownService();

const CustomTextModal = (props: any) => {
  const { show, onHide, event, emailType } = props;

  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [customText, setCustomText] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // fetch data on mount
  useEffect(() => {
    let text = '';
    if (emailType === 'creation')
      text = event?.emailsData?.creationTemplateText ?? '';

    setCustomText(turndownService.turndown(text).replace(/ +\n/g, '\n'));
  }, [emailType, event?.emailsData?.creationTemplateText]);

  if (isEmpty(event)) return null;

  const onSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsSaving(true);

      const data: any = { eventId: event.eventId };

      if (emailType === 'creation') data.creationTemplateText = customText;

      await updateOneEvent(data);
      await updateCurrentEvent();

      toast.success('Your custom text is updated');
      trackEmailHubUpdateCustomTextSuccess(emailType);

      onHide();
    } catch {
      toast.error('Something went wrong. Try again later');
      trackEmailHubUpdateCustomTextError(emailType);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Customise email text</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          Your text will appear above the button on the template. Use ‘Preview’
          for a visual reference.
        </div>

        <Form onSubmit={onSubmit}>
          <div>
            <FloatingLabel
              label="Custom Text"
              as="textarea"
              name="customText"
              value={customText ?? ''}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setCustomText(e.target.value)
              }
              required
            />

            <FormText text="To italicise text, add one asterisk or underscore before and after a word or phrase. E.g. This is _italic text_.<br />To bold text, add two asterisks or underscores before and after a word or phrase. E.g. This is **bold text**." />
          </div>

          <Button
            disabled={isEmpty(customText)}
            isSubmitBtn
            isProcessing={isSaving}
          >
            Save
          </Button>
        </Form>
      </Modal.Body>

      <Modal.Footer className="text-sm italic">
        We suggest clicking 'Preview' after saving your text to make sure you’re
        happy with the result.
      </Modal.Footer>
    </Modal>
  );
};

export default CustomTextModal;
