import React from 'react';
import parse from 'html-react-parser';
import { PiCheckCircle } from 'react-icons/pi';

import { ChangingTip } from '../custom';
import { Spinner, Modal } from '../general';

const ConfirmModal = (props) => {
  const { show, onHide, isProcessing, heading, subheading, btn, tipsList } =
    props;

  return (
    <Modal show={show} onHide={!isProcessing ? onHide : null}>
      <Modal.Body centered>
        {isProcessing ? (
          <Spinner customSize="w-20 h-20" color="primary" />
        ) : (
          <PiCheckCircle color="var(--success-color)" size={100} />
        )}

        {heading && <h4>{parse(heading)}</h4>}

        {subheading &&
          (typeof subheading === 'string' ? (
            <div>{parse(subheading)}</div>
          ) : (
            subheading
          ))}

        {isProcessing && tipsList && <ChangingTip tipsList={tipsList} />}

        {btn}
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmModal;
