import React, { useState, ChangeEventHandler } from 'react';
import { Eye, EyeSlash } from '@phosphor-icons/react';

import { InputGroup, FloatingLabel, InputGroupText, FormError } from '.';

type Props = {
  className?: string;
  label: string;
  name: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isInvalid?: boolean;
  errMsg?: string;
};

const PasswordField = (props: Props) => {
  const { className, label, name, value, onChange, isInvalid, errMsg } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={className ?? ''}>
      <InputGroup>
        <FloatingLabel
          label={label}
          type={showPassword ? 'text' : 'password'}
          name={name}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          required
          className="w-[calc(100%_-_var(--form-el-height))]"
        />

        <InputGroupText
          onClick={() => setShowPassword(!showPassword)}
          className="aspect-square cursor-pointer"
        >
          {showPassword ? (
            <EyeSlash size="1.5rem" weight="fill" />
          ) : (
            <Eye size="1.5rem" weight="fill" />
          )}
        </InputGroupText>
      </InputGroup>

      <FormError errMsg={errMsg} />
    </div>
  );
};

export default PasswordField;
