import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { validateEmail, isEmpty } from '../../../Utils';

import {
  Form,
  FloatingLabel,
  FormText,
  PasswordField,
} from '../../../components/form';
import { Button, Modal } from '../../../components/general';

import {
  URL,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  LINK_EXPIRE_IN_MIN,
} from '../../../constants';

import OnboardingPage from '../components/onboardingPage';
import { validateResetPasswordForm } from '../components/validations';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showExpModal, setShowExpModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isResetting, setIsResetting] = useState(false);

  const queryParameters = new URLSearchParams(window.location.search);
  const id = queryParameters.get('id');

  const navigate = useNavigate();

  useEffect(() => {
    if (!id) navigate('/');
    else {
      const emailSends = JSON.parse(localStorage.getItem('emailSends')) ?? [];
      let foundEmailSend = emailSends.filter((eSend) => eSend.id === id)[0];

      if (!foundEmailSend) navigate('/');
      else if (new Date() > new Date(foundEmailSend.expiryMoment)) {
        setShowExpModal(true);
        setEmail(foundEmailSend.email);
      } else setEmail(foundEmailSend.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (e, setValue) => {
    const value = e.target.value;
    setValue(value);
    setErrors({});
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    let errors = await validateResetPasswordForm({ password, confirmPassword });

    if (!isEmpty(errors)) setErrors(errors);
    else {
      setIsResetting(true);

      toast.info('API is not connected yet, so nothing happens');

      // do nothing for now, need API to update pw in DB

      // if (success)  {
      //     navigate(URL.LOGIN);
      // } else {
      //     toast.error("Something went wrong");
      // }

      setIsResetting(false);
    }
  };

  const onSubmitResendEmail = (e) => {
    e.preventDefault();

    let { emailErr } = validateEmail(email);

    if (emailErr) {
      let errorsTemp = { resendEmail: emailErr };
      setErrors(errorsTemp);
    } else {
      // TODO: send real email
      navigate(URL.FORGOT_PASSWORD);
    }
  };

  return (
    <OnboardingPage title="Reset Password" heading="Reset your password">
      <Form onSubmit={onSubmit} disabled={isResetting}>
        <div>
          <PasswordField
            label="New password"
            name="password"
            value={password}
            onChange={(e) => onChange(e, setPassword)}
            isInvalid={!!errors.password}
            errMsg={errors.password}
          />

          <FormText
            text={`Password must be ${PASSWORD_MIN_LENGTH}-${PASSWORD_MAX_LENGTH} characters long, contain at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character.`}
            color="white"
          />
        </div>

        <PasswordField
          label="Confirm new password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => onChange(e, setConfirmPassword)}
          isInvalid={!!errors?.confirmPassword}
          errMsg={errors?.confirmPassword}
        />

        <Button className="w-full" isSubmitBtn isProcessing={isResetting}>
          Reset Password
        </Button>
      </Form>

      <Modal show={showExpModal}>
        <Modal.Header>
          <Modal.Title>Link has expired</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            Reset password link expires after {LINK_EXPIRE_IN_MIN} minutes. But
            don't worry, we can send the link again.
          </div>

          <Form onSubmit={onSubmitResendEmail}>
            <FloatingLabel
              label="Email"
              name={'email'}
              value={email}
              onChange={(e) => onChange(e, setEmail)}
              isInvalid={!!errors?.resendEmail}
              errMsg={errors?.resendEmail}
              required
            />

            <div className="text-sm italic">
              By clicking 'Resend email' you give consent to send email to the
              provided email address.
            </div>

            <Button onClick={() => {}} type="submit" className="w-full">
              Resend email
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </OnboardingPage>
  );
};

export default ResetPassword;
