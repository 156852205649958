import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { toast } from 'react-toastify';

const fetchVideo = async (url, retries = 3, delay = 1000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error(`HTTP Error: ${response.status}`);
      return await response.blob();
    } catch (err) {
      console.warn(`Attempt ${attempt} failed for ${url}:`, err);
      if (attempt === retries) throw err;
      await new Promise((res) => setTimeout(res, delay));
    }
  }
};

export const zipAndDownloadVideos = async (eventName, videoUrls) => {
  try {
    const zip = new JSZip();
    const videoFolder = zip.folder(`${eventName} videos`);

    if (!videoFolder) throw new Error('Failed to create ZIP folder.');

    const fetchPromises = videoUrls.map(async (video, index) => {
      try {
        const videoBlob = await fetchVideo(video.url);
        videoFolder.file(
          `${index + 1}) CrowdClip ${video.faceName} video.mp4`,
          videoBlob,
        );
      } catch (err) {
        console.error(`Failed to fetch video ${video.url}:`, err);
      }
    });

    await Promise.all(fetchPromises);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    if (!zipBlob) throw new Error('Failed to generate ZIP file.');

    saveAs(zipBlob, `${eventName} videos.zip`);
  } catch (err) {
    console.error('Error in zipAndDownloadVideos:', err);
    toast.error(
      'Something went wrong while downloading videos. Try again later',
    );
  }
};

export const downloadVideo = async (videoUrl, faceName) => {
  try {
    const videoBlob = await fetchVideo(videoUrl);
    const url = window.URL.createObjectURL(videoBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `CrowdClip ${faceName} video.mp4`;
    a.click();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  }
};
