import React, { useState, useMemo } from 'react';

import { isEmpty } from '../../../Utils';

import { EMAILS } from '../../../constants';

import { useUserStore } from '../../../stores/user';

import { Event, SGTemplate } from '../../../types';

import { getAllEmails } from './components/Utils';
import EmailBlock from './components/emailBlock';
import EmailPreviewModal from './components/emailPreviewModal';
import SendTestEmailModal from './components/sendTestEmailModal';

const EmailHub = (props: { event: Event }) => {
  const { event } = props;

  const user = useUserStore((state) => state.user);

  const [previewTemplate, setPreviewTemplate] = useState<SGTemplate>({});
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const [sendTestTemplate, setSendTestTemplate] = useState<SGTemplate>({});
  const [showTestModal, setShowTestModal] = useState<boolean>(false);

  const emails = getAllEmails(event);

  const customText = useMemo(() => {
    const template = !isEmpty(previewTemplate)
      ? previewTemplate
      : !isEmpty(sendTestTemplate)
        ? sendTestTemplate
        : null;

    let customText = '';

    if (template) {
      if (template.template_id === EMAILS.CREATION_CUSTOM)
        customText = event?.emailsData?.creationTemplateText ?? '';
      else customText = (JSON.parse(template.test_data ?? '') ?? {}).text ?? '';
    }

    return customText;
  }, [event, previewTemplate, sendTestTemplate]);

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold leading-[2.5rem]">Email Hub</h3>

        <div>View and manage emails your attendees receive</div>
      </div>

      <div className="flex flex-col gap-6">
        {emails.map((email) => (
          <EmailBlock
            email={email}
            handleShowPreview={(template) => {
              setPreviewTemplate(template);
              setShowPreviewModal(true);
            }}
            handleShowTest={(template) => {
              setSendTestTemplate(template);
              setShowTestModal(true);
            }}
          />
        ))}
      </div>

      <EmailPreviewModal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        template={previewTemplate}
        event={event}
        user={user}
        customText={customText}
      />

      <SendTestEmailModal
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        template={sendTestTemplate}
        event={event}
        user={user}
        customText={customText}
      />
    </div>
  );
};

export default EmailHub;
