import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  trackClickTakeMeBack,
  trackAttendeeGenerationStartSuccess,
  trackAttendeeGenerationStartError,
} from '../../../analytics';

import { Spinner, Button, Modal } from '../../../components/general';
import { URL } from '../../../constants';

import { generateCreations } from '../../../services/api/creations';

const GenerateModal = (props) => {
  const { show, onHide, eventId, faceId, clipSelections } = props;

  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();

  const onClickGenerate = async () => {
    setIsProcessing(true);

    try {
      const data = { eventId, clipSelections: [clipSelections] };
      await generateCreations(data, true);

      trackAttendeeGenerationStartSuccess();
      navigate(`${URL.REVIEW_VIDEO_GENERATING}${faceId}`);
    } catch (err) {
      trackAttendeeGenerationStartError(err.message);
      toast.error(err.message);
      onHide();
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal show={show} onHide={() => !isProcessing && onHide()}>
      <Modal.Body centered>
        <h4>Are you sure you want to proceed?</h4>

        <div>
          This is your <b>only chance to generate</b>, so be certain before you
          continue
        </div>

        {isProcessing ? (
          <Spinner
            customSize="w-[--form-el-height] h-[--form-el-height]"
            color="primary"
          />
        ) : (
          <div className="w-full flex flex-col sm:flex-row items-center justify-between gap-4">
            <Button className="w-full sm:w-1/2" onClick={onClickGenerate}>
              Yes, Generate
            </Button>

            <Button
              variant="pink"
              className="w-full sm:w-1/2"
              onClick={() => {
                onHide();
                trackClickTakeMeBack('attendee_generate');
              }}
            >
              Not Yet, Take Me Back
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default GenerateModal;
