import React, { useState, useEffect } from 'react';
import { UserCircle, Check } from '@phosphor-icons/react';
import classNames from 'classnames';
import moment from 'moment';
import { toast } from 'react-toastify';

import { isEmpty, validateEmail } from '../../../../Utils';

import {
  trackVBAssignModalAddAssignee,
  trackVBAssignModalRemoveAssignee,
  trackVBAssignModalChangeAssess,
  trackVBAssignModalOpenLink,
  trackVBAssignModalCopyLink,
  trackVBAssignModalSaveSuccess,
  trackVBAssignModalSaveError,
} from '../../../../analytics';

import { LinkBox } from '../../../../components/custom';
import { Form, FloatingLabel, FormError } from '../../../../components/form';
import {
  Button,
  Modal,
  Dropdown,
  Checkbox,
} from '../../../../components/general';

import { URL, EMAILS } from '../../../../constants';

import { sendEmail } from '../../../../services/api/emails';
import { setAssigneeData } from '../../../../services/api/mongodb';

import { useEventStore } from '../../../../stores/event';
import { useUserStore } from '../../../../stores/user';

const AssignModal = (props) => {
  const { show, onHide, isProcessing } = props;

  const user = useUserStore((state) => state.user);
  const currentEvent = useEventStore((state) => state.currentEvent);
  const updateCurrentEvent = useEventStore((state) => state.updateCurrentEvent);

  const [existingEmails, setExistingEmails] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [errors, setErrors] = useState([]);

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  // fill assignee array based on data from database
  useEffect(() => {
    const existingEmails = [];
    const assigneesNew = [];

    const types = [
      { array: 'peopleAssignees', check: 'isPeopleChecked' },
      { array: 'hlReelAssignees', check: 'isHlReelChecked' },
      { array: 'clipsAssignees', check: 'isClipsChecked' },
    ];

    if (isEmpty(currentEvent.assigneeData)) return;

    types.forEach((type) => {
      currentEvent.assigneeData[type.array].forEach((assigneeEmail) => {
        if (!existingEmails.includes(assigneeEmail))
          existingEmails.push(assigneeEmail);

        const foundAssignee = assigneesNew.find(
          (assignee) => assignee.email === assigneeEmail,
        );

        if (foundAssignee) {
          foundAssignee[type.check] = true;
          if (
            foundAssignee.isPeopleChecked &&
            foundAssignee.isHlReelChecked &&
            foundAssignee.isClipsChecked
          )
            foundAssignee.access = 'full';
        } else
          assigneesNew.push({
            email: assigneeEmail,
            access: 'limited',
            [type.check]: true,
          });
      });
    });

    setExistingEmails(existingEmails);
    setAssignees(assigneesNew);
  }, [currentEvent.assigneeData]);

  const handleAddAssignee = (e) => {
    e.preventDefault();

    trackVBAssignModalAddAssignee();

    let { emailErr } = validateEmail(email);

    assignees.forEach((assignee) => {
      if (email === assignee.email)
        emailErr = 'This email address is already added';
    });

    if (emailErr) {
      setEmailErr(emailErr);
      return;
    }

    const assignee = {
      email,
      access: 'full',
      isPeopleChecked: true,
      isHlReelChecked: true,
      isClipsChecked: true,
    };

    setAssignees([...assignees, assignee]);
    setEmail('');
  };

  const onChangeAssignee = (index, e) => {
    const assigneesTemp = [...assignees];

    const { type, name, value, checked } = e.target;

    if (name === 'access') trackVBAssignModalChangeAssess(value);

    assigneesTemp[index] = {
      ...assigneesTemp[index],
      [name]: type === 'checkbox' ? checked : value,
    };

    setAssignees(assigneesTemp);
    setErrors([]);
  };

  const handleRemoveAssignee = (index) => {
    trackVBAssignModalRemoveAssignee();

    const assigneesTemp = [...assignees];
    assigneesTemp.splice(index, 1);
    setAssignees(assigneesTemp);
  };

  const handleSave = async () => {
    let peopleAssignees = [];
    let hlReelAssignees = [];
    let clipsAssignees = [];
    let errorsTemp = [];

    assignees.forEach((assignee) => {
      if (assignee.isPeopleChecked) peopleAssignees.push(assignee.email);
      if (assignee.isHlReelChecked) hlReelAssignees.push(assignee.email);
      if (assignee.isClipsChecked) clipsAssignees.push(assignee.email);

      if (
        !assignee.isPeopleChecked &&
        !assignee.isHlReelChecked &&
        !assignee.isClipsChecked
      )
        errorsTemp.push(
          'No access has been granted. Please either assign some permissions or remove the assignee.',
        );
      else errorsTemp.push('');
    });

    if (errorsTemp.every((err) => err === '')) errorsTemp = [];

    if (!isEmpty(errorsTemp)) {
      setErrors(errorsTemp);
      return;
    }

    try {
      setIsSaving(true);

      await setAssigneeData({
        eventId: currentEvent.eventId,
        peopleAssignees: JSON.stringify(peopleAssignees),
        hlReelAssignees: JSON.stringify(hlReelAssignees),
        clipsAssignees: JSON.stringify(clipsAssignees),
      });

      assignees.forEach(async (assignee) => {
        // if email already existed, it already received email
        if (existingEmails.includes(assignee.email)) return;

        const emailData = {
          recipientEmail: assignee.email,
          templateId: EMAILS.ASSIGNEE_INVITE,
          dynamicTemplateData: {
            name: user.firstName ?? user.email,
            event_name: currentEvent.name,
            share_url: `${window.location.origin}/${currentEvent.eventId}${URL.VB_ASSIGNEE}?email=${assignee.email}`,
          },
        };

        const isSuccess = await sendEmail(emailData);

        if (!isSuccess)
          toast.error(
            <div>
              Email couldn't be sent to <b>{assignee.email}</b>, please try
              again later.
            </div>,
          );
      });

      await updateCurrentEvent();

      toast.success(
        'Permissions have been updated, and emails have been sent to the assignees.',
      );
      trackVBAssignModalSaveSuccess();
    } catch {
      toast.error('Something went wrong. Try again later');
      trackVBAssignModalSaveError();
    } finally {
      setIsSaving(false);
    }
  };

  const accessOptions = [
    {
      value: 'full',
      label: 'Full Access',
      description:
        'Assignees can access people, highlight reels, and clips upload pages.',
    },
    {
      value: 'limited',
      label: 'Limited Access',
      description:
        'Assignees will only have access to the upload pages you’ve selected.',
    },
  ];

  const accessCheckboxes = [
    { name: 'isPeopleChecked', label: 'People' },
    { name: 'isHlReelChecked', label: 'Highlight Reel' },
    { name: 'isClipsChecked', label: 'Clips' },
  ];

  return (
    <Modal show={show} onHide={!isProcessing ? onHide : null}>
      <Modal.Body>
        <h5>Assign to people</h5>

        <Form onSubmit={handleAddAssignee}>
          <div className="flex gap-2">
            <FloatingLabel
              label="Enter assignee email"
              type="email"
              name="email"
              value={email ?? ''}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailErr(null);
              }}
              isInvalid={!!emailErr}
              errMsg={emailErr}
              required
              className="w-3/4"
            />

            <Button variant="secondary" type="submit" className="w-1/4">
              Add
            </Button>
          </div>
        </Form>

        {!isEmpty(assignees) && (
          <div className="flex flex-col gap-4">
            {assignees.map((assignee, index) => (
              <div className="flex flex-col gap-1 sm:gap-0">
                <div className="flex items-center gap-2">
                  <UserCircle weight="duotone" size="1.5rem" />

                  <div>{assignee.email}</div>

                  <Dropdown
                    btnData={{
                      label: `${assignee.access} Access`,
                      variant: '',
                      withCaret: true,
                      className: 'ml-auto text-sm capitalize',
                    }}
                    menuItems={[
                      ...accessOptions.map((option) => {
                        const content = (
                          <>
                            <div
                              className={classNames('flex items-center gap-2', {
                                'text-success-900 font-bold':
                                  option.value === assignee.access,
                              })}
                            >
                              {option.value === assignee.access ? (
                                <Check weight="bold" />
                              ) : (
                                <div className="w-4" />
                              )}
                              <div>{option.label}</div>
                            </div>

                            <div className="pl-6 text-xs text-wrap opacity-50">
                              {option.description}
                            </div>
                          </>
                        );

                        return {
                          customContent: content,
                          onClick: () =>
                            onChangeAssignee(index, {
                              target: { name: 'access', value: option.value },
                            }),
                          closeOnClick: true,
                          className: 'w-80 flex-col items-start px-3 text-sm',
                        };
                      }),
                      { type: 'divider' },
                      {
                        customContent: (
                          <div className="pl-6 text-sm text-error-900">
                            Remove
                          </div>
                        ),
                        onClick: () => handleRemoveAssignee(index),
                      },
                    ]}
                  />
                </div>

                {assignee.access === 'limited' && (
                  <div className="flex gap-4 text-sm">
                    {accessCheckboxes.map((check) => (
                      <Checkbox
                        id={`${check.label.toLowerCase().replace(/\s+/g, '-')}-checkbox-${index}`}
                        label={check.label}
                        name={check.name}
                        checked={assignee[check.name]}
                        onChange={(e) => onChangeAssignee(index, e)}
                      />
                    ))}
                  </div>
                )}

                <FormError errMsg={errors[index]} />
              </div>
            ))}
          </div>
        )}

        <div className="text-sm italic">
          Your assignees will get an email with a link, giving them access only
          to the upload sections you’ve designated for them.
        </div>

        <hr className="my-1" />

        <h5>Share a link instead</h5>

        <LinkBox
          link={`${window.location.origin}/${currentEvent.eventId}${URL.VB_ASSIGNEE}?accessCode=${moment(currentEvent.created).format('x')}`}
          variant="white"
          handleOpenTrack={trackVBAssignModalOpenLink}
          handleCopyTrack={trackVBAssignModalCopyLink}
        />

        <div className="text-sm italic">
          Anyone with this link will have full access.
        </div>

        <Button
          onClick={handleSave}
          disabled={!isEmpty(errors)}
          isSubmitBtn
          isProcessing={isSaving}
        >
          Save
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default AssignModal;
