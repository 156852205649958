import React from 'react';
import classNames from 'classnames';
import { PiUserRectangleFill } from 'react-icons/pi';

import { round, getThumbnail } from '../../../../Utils';

type Props = {
  block: any;
  frameId: string;
  person: any;
  thumbnailClass: string;
};

const HlReelPreviewThumbnail = (props: Props) => {
  const { block, frameId, person, thumbnailClass } = props;

  //  wildcard – return thumbnail image or placeholder block
  if (block.type === 'wildcard') {
    if (frameId)
      return (
        <img
          src={getThumbnail(frameId)}
          className={thumbnailClass}
          alt="Wildcard Thumbnail"
        />
      );
    else
      return (
        <div
          className={classNames(
            thumbnailClass,
            'flex flex-col items-center justify-center bg-grey-100 rounded-md border-1 border-grey-200 border-dashed',
            { 'hover:border-primary-900 hover:border-solid': !person },
          )}
        >
          <PiUserRectangleFill size="2.5rem" className="hidden sm:block" />
          <div>WILDCARD</div>
          {block.duration && (
            <div className="text-xs">{round(block.duration)} seconds</div>
          )}
        </div>
      );
  }

  // selects – return thumbnail image or grey block
  else if (!person)
    return (
      <img
        src={getThumbnail(frameId)}
        className={thumbnailClass}
        alt="Shot Thumbnail"
      />
    );
  else return <div className={`${thumbnailClass} bg-grey-200`} />;
};

export default HlReelPreviewThumbnail;
