import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { isEmpty, isProduction, getNameForTracking } from '../Utils';
import { URL } from '../constants';

import { User } from '../types';

// not used rn
export const isValidDomain = (email: string) => {
  const notAllowedDomains = ['crowdclip.com', 'dius.com.au', 'dius.com'];

  const domain = email.split('@')[1];
  return !notAllowedDomains.includes(domain);
};

export const initLR = () => {
  const untrackedPaths = [
    URL.ENTER_EMAIL,
    URL.EMAIL_VERIFICATION,
    URL.CREATE_ACCOUNT,
  ];

  if (!isProduction() || untrackedPaths.includes(window.location.pathname))
    return;

  LogRocket.init('6f0rux/httpsmycrowdclipco-fe');
  setupLogRocketReact(LogRocket);
};

export const identifyUserLR = (user: User) => {
  if (!isProduction() || isEmpty(user)) return;

  LogRocket.identify(user.userId, {
    name: getNameForTracking(user),
    email: user.email,
  });
};

export const restartSessionLR = () => {
  LogRocket.startNewSession();
};
